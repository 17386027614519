import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "antd";

import Icons from "../../icons";
import { Localize } from "components/service";

import './attachments-list.css';



const AttachIcon = ( { extension } ) => {

    switch (extension) {
        case 'pdf' :
            return <Icons.File type="pdf" />;

        case 'xls' :
        case 'xlsx':
            return <Icons.File type="excel" />;

        case 'zip' :
        case '7zip':
        case '7zp' :
        case 'rar' :
            return <Icon type="file-zip" />;

        case 'doc' :
        case 'docx':
            return <Icons.File type="word" />;

        case 'ppt' :
        case 'pptx':
            return <Icon type="file-ppt" />;

        case 'txt' :
        case 'list':
            return <Icon type="file-text" />;

        case 'JPEG':
        case 'jpg':
        case 'gif':
        case 'png':
        case 'tif':
            return <Icons.File type="image" />;

        default: return <Icons.File />;
    }
};

const AttachmentsList = (props) => {

    const {
        attachments,
        postLink,
        showTextOnButton,
        subText,
        withAuthor,
        className= '',
        rowClass=''
    } = props;

    const list = attachments.map( (node) => {

        const {
            id,
            title,
            display_url,
            extension,
            post,
            author,
            created_at,
            description
        } = node;

        return (
            <li key={id} className={`${ rowClass }`}>
                <div className="download-file">
                    <div className="company-icon-color">
                        <AttachIcon  extension={extension} />
                    </div>
                    <div className="download-file-info">
                        {  withAuthor &&
                            <div className="download-file-author">
                                <Link to={`/${author.nickname}`} className="site-text-color">
                                    <Icons.User filled/>
                                    <span>{ author.name }</span>
                                </Link>
                            </div>
                        }
                        <div className="download-file-name">
                            <span >{title}</span>
                            <span className="file-extension">.{extension}</span>
                        </div>
                        { subText &&
                            <div className="download-file-description site-sub-text-color">
                                { subText === 'description' &&  description }
                                { subText === 'created_at' &&  created_at }
                            </div>
                        }
                    </div>
                </div>
                <div className="actions">
                    <div className="download-file-created site-sub-text-color">
                        { created_at }
                    </div>
                    { postLink &&
                        <Link to={ `/post/${ post.order_slug }` } className="post-link company-icon-color">
                            <Icons.Post />
                            { showTextOnButton &&
                                <Localize wrap>POST.Link_Text_ViewPost</Localize>
                            }
                        </Link>
                    }
                    <a
                        href={display_url}
                        className="download-button button-bg-color site-text-color"
                        target="_blank"
                        title={`${title}.${extension} `}
                        rel="noopener noreferrer"
                    >
                        <Icons.Download />
                        { showTextOnButton &&
                            <Localize wrap>POST.Link_Text_DownloadAttachment</Localize>
                        }
                    </a>
                </div>
            </li>
        );
    });


    return (
       <ul className={ `attachments-list site-text-color ${className}`} >
           { list }
       </ul>
    );
};

export default AttachmentsList;
