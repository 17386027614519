import React, { useState } from "react";
import { Button, Form, Upload } from "antd";

import { errorNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";

import "./post-create-form.css";


const handleSubmit = (e, { createPost, fileList }) => {
    e.preventDefault();

    const files = fileList.map( ({ originFileObj }) => {
        return originFileObj;
    });

    createPost({
        variables : {
            files: files
        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const NormalPostCreateForm  = ({ createPost, loading }) => {

    const [ fileList, setFileList ] = useState([]);

    const formUploadProps = {
        accept: 'image/*',
        beforeUpload :  () => { return false; },
        onChange: ({ fileList }) => setFileList(fileList),
        listType: "picture-card",
        fileList: fileList,
        showUploadList: {
            showPreviewIcon: false
        },
        multiple : false
    };

    return (
        <Form className={"post-create-form"}
              onSubmit={ (e) => {
                  handleSubmit(e, {
                      createPost,
                      fileList
                  });
              }}
        >
            <Form.Item
                className={ `upload-holder ${fileList.length < 3 ? "center-upload" : ""}` }
            >
                <Upload {...formUploadProps} >
                    { fileList < 1 &&
                    <div className="upload-label">
                        <div className="site-badge"><Icons.Plus /></div>
                        <div className="site-icon">
                            <Localize>POST.Text_AddImage</Localize>
                        </div>
                    </div>
                    }
                </Upload>
            </Form.Item>
            <Form.Item>
                <div className="upload-rules site-label-text-color">
                    <Localize size="25" br={ <br /> }>FORM_RULES.Text_UploadImage</Localize>
                </div>
            </Form.Item>
            <Form.Item>
                <div className="form-actions">
                    <Button
                        className="rounded-button"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={fileList.length === 0}
                    >
                        <Localize wrap>GLOBAL.Button_Text_Next</Localize>
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

const PostCreateForm = Form.create({ name: 'validate_other' })(NormalPostCreateForm);

export default PostCreateForm;