import React from 'react';
import { Icon } from 'antd';

const NotificationSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M23.4,19c-0.4-0.4-1.3-1-1.7-1.9c-0.3-0.7-0.4-1-0.4-2.5c0-0.5-0.1-1.1-0.1-2c-0.3-3.8-0.8-5.2-2.7-6.9
	             c-0.9-0.8-1.9-1.2-2.7-1.4c-0.1-0.8-0.8-1.5-1.7-1.5c-0.8,0-1.6,0.6-1.7,1.4c-0.9,0.2-2,0.6-2.8,1.4c-1.9,1.7-2.7,3.1-2.7,7
	             c0,2.4,0,3.2-0.6,4.1c-0.5,0.8-1,1.3-1.7,1.9c-0.5,0.4-1,1.3-0.8,2.1c0.2,0.9,1,1.3,1.7,1.3h4.7c0.5,1.7,2,2.9,3.9,2.9
	             c1.8,0,3.4-1.2,3.9-2.9h4.5c0.7,0,1.6-0.4,1.7-1.3C24.4,19.8,23.7,19.2,23.4,19z M14.1,23.6c-1,0-1.9-0.6-2.3-1.5h4.5
	             C15.9,23,15.1,23.6,14.1,23.6z M22.7,20.6c0,0-0.2,0.1-0.3,0.1h-3.9c0,0,0,0-0.1,0H9.9c0,0,0,0-0.1,0H5.6c-0.1,0-0.2,0-0.3-0.1
	             c0-0.2,0.2-0.6,0.4-0.9c0.7-0.6,1.2-1.2,1.8-2.2c0.8-1.3,0.8-2.5,0.8-4.9c0-3.7,0.8-4.6,2.2-5.9c0.8-0.7,1.9-1,2.6-1.1
	             c0.4,0,0.7-0.4,0.7-0.7V4.6c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2V5c0,0.4,0.3,0.7,0.7,0.7c0.7,0.1,1.8,0.4,2.6,1.1
	             c1.4,1.3,1.9,2.3,2.2,5.9c0.1,0.8,0.1,1.4,0.1,1.9c0.1,1.5,0.1,2,0.6,3c0.5,1.2,1.6,2,2.1,2.4C22.7,20.4,22.7,20.4,22.7,20.6z"
        />
    </svg>
);

const NotificationIcon = props => <Icon component={NotificationSvg} {...props} />;

export default NotificationIcon;