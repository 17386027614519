import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import useChatCreateMutation from "./chat-create-mutation-hook";

import { encode } from "utils";

import "./chat-create-button.css";


const ChatCreateButton = ({ selectedUsers, setShowPopup, setSelectedUsers }) => {

    const history =  useHistory();
    const userIds = selectedUsers.map( (user) => user.id );

    const { loading, onChatCreate } = useChatCreateMutation(userIds,  (chat) => {

        const chatSlug = encode.toSlug(
            [chat.id]
        ).replace(/[=]/g, '');

        history.push(`/chat/${ chatSlug }`);

        setShowPopup(false);
        setSelectedUsers([]);

    });

    return(
        <Button
            disabled={ loading || selectedUsers.length < 1 }
            loading={ loading }
            onClick={
                () => onChatCreate()
            }
            className={ `chat-create-button rounded-button` }
            type="primary"
        >
            <span className="label">Chat</span>
        </Button>
    );
};

export default ChatCreateButton;