import React from "react";
import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/react-hooks";

import { CREATE_POST } from "graphql/mutation/post-gql";

import { UpdateWrap } from "../../wraps";
import { PostCreateForm } from "../forms";
import { successNotification } from '../../result';

import './post-create.css';
import {Localize} from "../../service";


const PostCreate = () => {

    const history = useHistory();

    const [createPost, { loading }] = useMutation(CREATE_POST, {
        update(cache, { data }){

            const {createPost} = data;
            const {post, label, message} = createPost;

            successNotification({
                title: label,
                description: message
            });

            history.push(`/post/edit/${post.slug}`);

        }
    });

    return(
        <div className="post-create">
            <UpdateWrap>
                <Localize wrap="h2">POST.Title_ChooseImage</Localize>
                <PostCreateForm
                    createPost={createPost}
                    loading={loading}
                />
            </UpdateWrap>
        </div>
    );
};

export default PostCreate;