import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from "@apollo/react-hooks";

import { GET_COLLECTION } from 'graphql/query/collection-gql';

import { UpdateWrap, WrapColumn } from "../../wraps";
import CollectionUpdateCover from "../collection-update-cover";
import CollectionDataForm from "../collection-update-form";
import CollectionDeleteButton from "../collection-delete-button";
import { TopAppBar } from "../../menu-bar";
import { ElementVisibility, Localize } from "components/service";
import { Loader } from "components/result";

import './collection-update.css';


const CollectionUpdate = ({ collectionSlug }) => {

    let history = useHistory();

    const {loading, data} = useQuery(GET_COLLECTION, {
        variables: { slug : collectionSlug },
        fetchPolicy:"cache-and-network"
    });

    const {
        collection
    } = data !== undefined ? data : {};

    return (
        <div className="collection-update">
            { !loading ?
                <UpdateWrap>
                    <ElementVisibility maxWidth={ 768 } type="toggle" comparison="<">
                        <WrapColumn position="show">
                            <TopAppBar
                                title={
                                    <Localize wrap="h3">COLLECTION.Title_EditCollection</Localize>
                                }
                                rightSide={
                                    <>
                                        { collectionSlug  !== "private_collection" &&
                                            <CollectionDeleteButton
                                                collection_id={collection.id}
                                                buttonType="smallAntButton"
                                            />
                                        }
                                    </>
                                }
                            />
                        </WrapColumn>
                        <WrapColumn position="hide">
                            <Localize wrap="h2">COLLECTION.Title_EditCollection</Localize>
                        </WrapColumn>
                    </ElementVisibility>
                    <CollectionUpdateCover
                        collection={ collection }
                    />
                    { collection.slug !== 'private_collection' &&
                        <CollectionDataForm
                            collection={collection}
                            history={history}
                        />
                    }
                </UpdateWrap> :
                <Loader />
            }
        </div>
    );
};

export default CollectionUpdate;