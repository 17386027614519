import { gql } from "apollo-boost";

import { userFieldsShort, userFields } from "../fragment/user-frgm";
import { postFieldsFull, postFields, postAuthorFragment } from "../fragment/post-frgm";
import { mediaFragment } from "../fragment/media-frgm";
import { pageInfoFieldsFull } from "../fragment/pagination-frgm";


export const GET_ME = gql`
    query GetMe {
        me{
            ...userFields
            seller_number
            date_entrance_at
        }
    }
    ${ userFields }
`;

//...userMeta
//${ userMetaFragment }

export const GET_ME_LOCALE = gql`
    query GetMeLocale {
        me{
            id,
            locale
        }
    }
`;

export const GET_USER = gql`
    query GetUser($nickname : String, $user_id : ID){
        user(nickname : $nickname, user_id : $user_id){
            ...userFields
            me_following
            itsMe
            counters : user_counters{
                published : public_posts
                followers
                following_users
                following_collections
                following_tags
                following
            }
        }
    }
    ${ userFields }
`;

//...userMeta
//${ userMetaFragment }

export const GET_USER_SHORT = gql`
    query GetUser($nickname : String, $user_id : ID){
        user(nickname : $nickname, user_id : $user_id){
            ...userFieldsShort
            me_following
            itsMe
        }
    }
    ${ userFieldsShort }
`;

export const GET_LANGUAGES = gql`
    query  GetLanguages {
        languageActive {
            id
            name
            slug
        }
    }
`;

export const CHECK_FORGOT_PASSWORD = gql`
    query CheckUpdateForgottenPassword ($forgotToken: String){
        checkUpdateForgottenPassword (key: $forgotToken){
            label
            message
            user{
                ...userFieldsShort
            }
        }
    }
    ${ userFieldsShort }
`;

export const GET_AUTH_TOKEN = gql`
    query GetAuthToken {
        authToken @client
    }
`;

export const GET_MY_DASHBOARD = gql`
    query MyDashboard($endCursor : String){
        myDashboard(after: $endCursor, first: 12){
            pageInfo{
                ...pageInfoFieldsFull
            }
            edges{
                node{
                    id
                    relationship_info{
                        label
                        slug
                        cover_img
                        relationship_type
                        relationship_id
                    }
                    post{
                        ...postFieldsFull
                    }
                }
            }
        }
    }
    ${ pageInfoFieldsFull }
    ${ postFieldsFull }
`;

export const GET_USER_POSTS = gql`
    query GetUserPosts(
        $post_id: ID,
        $author_id : ID!,
        $paginate: TypePaginate,
        $defaultCount: Int!,
        $order_date: Int
    ){
        userPosts(
            post_id: $post_id,
            author_id: $author_id,
            paginate: $paginate,
            defaultCount: $defaultCount,
            order_date: $order_date
        ){
            ...postFields
            ...mediaFragment
            ...postAuthorFragment
        }
    }
    ${ postFields }
    ${ mediaFragment }
    ${ postAuthorFragment }
`;

export const GET_USER_POSTS_DETAILED = gql`
    query GetUserPosts(
        $post_id: ID,
        $author_id : ID!,
        $paginate: TypePaginate,
        $defaultCount: Int!,
        $order_date: Int
    ){
        userPosts(
            post_id: $post_id,
            author_id: $author_id,
            paginate: $paginate,
            defaultCount: $defaultCount,
            order_date: $order_date
        ){
            ...postFieldsFull
        }
    }
    ${ postFieldsFull }
`;

export const clientSchemaExtensions = gql`

    directive @client on FIELD
    
`;