import { gql } from "apollo-boost";

import { postFieldsFull, postFieldsShort } from "../fragment/post-frgm";
import { mediaFragment } from "../fragment/media-frgm";

export const GET_POST_SHORT = gql`
    query GetPost($slug : String, $post_id : ID){
        post(slug : $slug, post_id : $post_id){
            ...postFieldsShort
            ...mediaFragment
        }
    }
    ${ postFieldsShort }
    ${ mediaFragment }
`;

export const GET_POST = gql`
    query GetPost($slug : String, $post_id : ID){
        post(slug : $slug, post_id : $post_id){
            ...postFieldsFull
        }
    }
    ${ postFieldsFull }
`;