import { gql } from "apollo-boost";

import { commentsFields } from "../fragment/comment-frgm";


export const ADD_COMMENT = gql`
    mutation AddComment($input: CommentInput!) {
        addComment(input: $input){
            label
            message
            comments{
                ...commentsFields
                post{
                    id
                    comment_count
                }
            }  
        }
    }
    ${ commentsFields }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
      deleteComment(id: $id){
        label
        message 
      }
    }`;