import React from 'react';

import {ContainerWrap} from "../../wraps";
import Collection from "../../collections";


const CollectionPage = () => {
    return (
        <ContainerWrap className="container-margin">
            <Collection />
        </ContainerWrap>
    );
};

export default CollectionPage;