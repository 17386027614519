import AuthorizationPage from './authorization/authorization-page';
import HomePage from './home/home-page';
import UserPage from "./user/user-page";
import UserFollowingPage from "./user/user-following-page";
import UserFollowersPage from "./user/user-followers-page";
import SearchPage from "./search/search-page";
import PostCreatePage from "./post/post-create-page";
import PostUpdatePage from "./post/post-update-page";
import UserPostPage from './user/user-post-page';
import ProfilePage from './profile/profile-page';
import CollectionPage from "./collection/collection-page";
import CollectionPostPage from "./collection/collection-post-page";
import CollectionUpdatePage from "./collection/collection-update-page";
import CollectionFollowersPage from "./collection/collection-followers-page";
import HashtagPage from "./hashtag/hashtag-page";
import HashtagPostPage from "./hashtag/hashtag-post-page";
import HashtagFollowersPage from "./hashtag/hashtag-followers-page";
import ChatPage from "./chat/chat-page";


const Pages = {
    Authorization: AuthorizationPage,
    Home: HomePage,
    UserPostPage: UserPostPage,
    PostCreatePage: PostCreatePage,
    PostUpdatePage: PostUpdatePage,
    UserPage: UserPage,
    SearchPage: SearchPage,
    FollowingPage: UserFollowingPage,
    Profile: ProfilePage,
    CollectionPage,
    CollectionPostPage,
    CollectionUpdatePage,
    HashtagPage,
    HashtagPostPage,
    UserFollowersPage,
    CollectionFollowersPage,
    HashtagFollowersPage,
    ChatPage
};

export default Pages;

export {
    AuthorizationPage as Authorization,
}