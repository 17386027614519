import React, { useState } from "react";
import { Drawer, Radio } from "antd";

import Icons from "../../icons";
import NotificationList from "../notification-list";
import { Localize } from "components/service";

import './notification-drawer.css';


const NotificationDrawer = (props) => {

    const { setShowDrawer, showDrawer, userId, refetchNotificationsView } = props;

    const [ filterStatus, setFilterStatus ] = useState( "all" );



    return (
        <Drawer
            className="notification-drawer"
            title={
                <>
                    <Localize wrap="h4">NOTIFICATIONS.Drawer_Title</Localize>
                    <button
                        className="close-drawer"
                        onClick={ () => setShowDrawer( false )}
                    >
                        <Icons.Close />
                    </button>
                </>
            }
            closable={ false }
            placement="right"
            onClose={ () => setShowDrawer( false )}
            visible={ showDrawer }
            afterVisibleChange={
                (state) => {

                    if(!state) {
                        document.body.style.width = null;
                        document.body.classList.remove("ant-scrolling-effect");
                    } else {
                        refetchNotificationsView();

                    }
                }
            }
            destroyOnClose={ true }
        >
            <div className="notification-filter">
                <Radio.Group
                    onChange={
                        (e) => { setFilterStatus(e.target.value) }
                    }
                    defaultValue={ filterStatus }
                >
                    <Radio.Button value="all">
                        <Localize>NOTIFICATIONS.Filter_Label_All</Localize>
                    </Radio.Button>
                    <Radio.Button value="unread">
                        <Localize>NOTIFICATIONS.Filter_Label_Unread</Localize>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <NotificationList
                userId={userId}
                filterStatus={ filterStatus }
                onClickItem={ () => setShowDrawer( false ) }
                elementClass={ '.notification-drawer .notification-list' }
                scrollElementClass={ '.notification-drawer .ant-drawer-body' }
            />
        </Drawer>
    );
};

export default NotificationDrawer;