import React from "react";
import { Switch, Route } from 'react-router-dom';

import Pages from "../pages";

const MainRoutes = () => {
    return(
        <Switch>
            <Route path="/" exact>
                <Pages.Home type="news" />
            </Route>
            <Route path="/timeline" component={Pages.Home} exact/>
            <Route path="/search" component={Pages.SearchPage} exact/>
            <Route path="/post/create" component={Pages.PostCreatePage} exact/>
            <Route path="/post/edit/:postSlug" component={Pages.PostUpdatePage} exact/>
            <Route path="/post/:scrollIds" component={Pages.UserPostPage} exact/>
            <Route path="/profile" component={Pages.Profile} exact/>
            <Route path="/profile/:profileTab" component={Pages.Profile} exact/>
            <Route path="/collection/:collectionSlug" component={Pages.CollectionPage} exact/>
            <Route path="/collection/:collectionSlug/followers" component={ Pages.CollectionFollowersPage } exact/>
            <Route path="/collection/edit/:collectionSlug" component={Pages.CollectionUpdatePage} exact/>
            <Route path="/collection/:collectionSlug/:scrollIds" component={Pages.CollectionPostPage} exact/>
            <Route path="/tag/:tagSlug" component={Pages.HashtagPage} exact/>
            <Route path="/tag/:tagSlug/followers" component={ Pages.HashtagFollowersPage } exact/>
            <Route path="/tag/:tagSlug/:scrollIds" component={Pages.HashtagPostPage} exact/>
            <Route path="/chat" component={Pages.ChatPage} exact/>
            <Route path="/chat/:chatSlug/details" component={Pages.ChatPage} exact/>
            <Route path="/chat/:chatSlug" component={Pages.ChatPage} exact/>
            <Route path="/:userLogin/followers" component={Pages.UserFollowersPage} exact/>
            <Route path="/:userLogin/following/:followTab" component={Pages.FollowingPage} exact/>
            <Route path="/:userLogin" component={Pages.UserPage} exact/>
            <Route path="/:userLogin/:userLoginTab" component={Pages.UserPage} exact/>
        </Switch>
    );
};

export default MainRoutes;