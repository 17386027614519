import React from "react";
import { Input } from "antd";

import Icons from "../../icons";
import { Localize } from "components/service";

import "./chat-search-form.css";


const ChatSearchForm = (props) => {

    const {
        setSearchVariables,
        searchVariables,
        placeholder = Localize({children: "SEARCH.Input_Placeholder" }).props.children
    } = props;

    return (
        <div className="chat-search-form">
            <div className="search-field">
                <Input
                    name="search"
                    placeholder={ placeholder }
                    className="input-bg-color"
                    prefix={
                        <Icons.Search />
                    }
                    onChange={ ({ target: { value }}) => setSearchVariables(
                        {
                            ...searchVariables,
                            input: value
                        }
                    )}
                    value={ searchVariables.input }
                />
                { searchVariables.input.length > 0 &&
                    <button
                        className="clear-search site-title-color ant-btn"
                        onClick={ () => setSearchVariables({ ...searchVariables, input: ''}) }
                    >
                        Cancel
                    </button>
                }
            </div>
        </div>
    );
};

export default ChatSearchForm;