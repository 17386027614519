import React from "react";
import { useApolloClient, useQuery } from "@apollo/react-hooks";

import { GET_FOLLOWERS_BY } from 'graphql/query/follow-gql';
import { GET_ME } from "graphql/query/user-gql"

import FollowersBy from './type';
import { errorNotification } from "../../result";


const FollowersByType = (props) => {

    const { id, type } = props;

    const client = useApolloClient();
    const { me } = client.readQuery({query: GET_ME});

    const { loading, data,fetchMore } = useQuery(GET_FOLLOWERS_BY, {
        variables: {
            id: id,
            model: type
        },
        fetchPolicy: "cache-and-network"
    });

    const {
        followersBy : {
            pageInfo,
            __typename : typename,
            edges : followers = []
        } = {}
    } = data !== undefined ? data  : {} ;

    const nodes = followers.map(
        ({ node }) => {
            return {
                node : {
                    id: node.id,
                    author: node
                }
            }
        }
    );

    const fetchMoreAction = () => {
        if(!loading && data !== undefined){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newEdges = fetchMoreResult.followersBy.edges;
                    const pageInfo = fetchMoreResult.followersBy.pageInfo;

                    const newFollowers = {
                        followersBy: {
                            edges : [
                                ...followers,
                                ...newEdges
                            ],
                            __typename: typename,
                            pageInfo
                        }
                    };

                    return newEdges.length ? newFollowers : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    let followersProps = {
        followers : data !== undefined ? nodes : undefined,
        pageInfo,
        me,
        fetchMoreAction,
        loading
    };

    switch (type) {
        case "User" :

            return (
                <FollowersBy.User
                    { ...followersProps }
                    userId={ id }
                />
            );

        case "Collection" :
            return (
                <FollowersBy.Collection
                    { ...followersProps }
                />
            );

        case "Hashtag" :
            return (
                <FollowersBy.Hashtag
                    { ...followersProps }
                />
            );
        default: return null;
    }
};

export default FollowersByType;