import React from "react";


const CollectionCardCoverFourPost = ({ posts }) => {

    const {
        pageInfo,
        edges : postItems
    } = posts;

    const postList = postItems.map( ({ node }) => {
        return (
            <li key={ node.id }>
                <img src={ node.media[0].thumbnail } alt={ node.title } />
            </li>
        );
    });

    return (
        <div className="cover-four-post">
            { pageInfo.total > 0 &&
                <ul className="cover-post-list">
                    { postList }
                </ul>
            }
        </div>
    );

};

export default CollectionCardCoverFourPost;