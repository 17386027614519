import React from "react";
import { Link } from "react-router-dom";

import AvatarBlock from "components/avatar";
import Icons from "components/icons";
import { initials, encode } from "utils";

import "./chat-list-item.css";


const ChatListItem = ({ chat, activeChatId, me }) => {

    const {
              group_flag : group,
              messages : {
                  edges : messages = []
              },
              users
          } = chat,
        { node : message = undefined } = messages[0] || [];

    const chatSlug = encode.toSlug(
        [
            chat.id
            /*, message !== undefined ? message.id : 0*/
        ]
    ).replace(/[=]/g, '');

    const user = group ? users[0] : users.filter( ( node ) => node.id !== me.id )[0];

    const avatarData = {
        photoUrl: group ? chat.cover_url : user.avatar,
        initials: group ? initials(chat.title) : user.initials,
        label: group ? chat.title : user.name ,
        text: message !== undefined ? message.content : "",
        size: 44,
        shape: group ? "square" : "round"
    } ;

    return (
        <li className={ `chat-list-item-wrap${ activeChatId === parseInt(chat.id) ? " chat-active-item-bg" : "" }`}>
            <Link className="chat-list-item" to={`/chat/${ chatSlug }`}>
                <AvatarBlock { ...avatarData } />
                {  message !== undefined &&
                    <div className="status-block">
                        <div className="row">
                            { me.id === message.author_id &&
                                <span className={ `state ${ message.status }` }>
                                    { message.status === "send" &&
                                        <Icons.Check />
                                    }
                                    { message.status !== "send" &&
                                        <Icons.Check type="double" />
                                    }
                                </span>
                            }
                            <span className="date site-sub-text-light-color">{ message.message_date }</span>
                        </div>
                        <div className="row">
                            { me.id !== message.author_id && message.status === "send" &&
                                <span className="not-read" />
                            }
                        </div>
                    </div>
                }
            </Link>
        </li>
    );
};

export default ChatListItem;