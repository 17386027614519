import React from 'react';
import { Icon } from 'antd';

const FollowersSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 20 20">
        <path d="M13.8,9.9c1.7,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2S10.6,5,10.6,6.7S12.1,9.9,13.8,9.9z"/>
        <path d="M6.2,9.9c1.7,0,3.2-1.4,3.2-3.2S8,3.5,6.2,3.5S3,5,3,6.7S4.5,9.9,6.2,9.9z"/>
        <path d="M13.8,10.7c-1,0-1.9,0.2-2.7,0.6c1.5,1.3,2.5,3.3,2.5,5.5H20C20,13.4,17.2,10.7,13.8,10.7z"/>
        <path d="M6.2,10.7c-3.4,0-6.1,2.7-6.2,6.1h12.4C12.4,13.4,9.6,10.7,6.2,10.7z"/>
    </svg>
);


const FollowersIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ FollowersSvg }  />;
};

export default FollowersIcon;