import React from 'react';

import { ContainerWrap } from "../../wraps";
import PostCreate from "../../posts/post-create";
import { TopAppBar } from "../../menu-bar";
import {ElementVisibility, Localize} from "../../service";


const PostCreatePage = () => {

    return (
        <ContainerWrap className="container-margin">
            <ElementVisibility maxWidth={ 768 } type="show" comparison="<">
                <TopAppBar
                    title={ <Localize wrap="h3">POST.Title_CreatePost</Localize> }
                />
            </ElementVisibility>
            <PostCreate />
        </ContainerWrap>
    );
};

export default PostCreatePage;