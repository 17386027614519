import { useApolloClient } from "@apollo/react-hooks";

import { GET_ME_LOCALE } from "graphql/query/user-gql";

import translation from "mock-data/translation";


const useTranslation = () => {

    //const { loading, data } = useQuery( GET_ME );
    const client = useApolloClient();
    const { me } = client.readQuery({query: GET_ME_LOCALE});

    const defaultLang = "de";

    if(me){
        return { ...translation[ defaultLang ], ...translation[ me.locale ] };
    }

    return { ...translation[ defaultLang ] }
}

export default useTranslation;