import React from "react";
import { Link } from "react-router-dom";

import AvatarBlock from "../../../avatar";
import CardMenu from "../../card-menu";
import {CollectionCardMenu} from "../../../collections";
import Icons from "../../../icons";


const CardHeroTypeCollection = (props) => {

    const {
        relation : {
            cover_img,
            slug,
        },
        post
    } = props;


    return (
        <div className="card-hero">
            <AvatarBlock
                photoUrl={cover_img}
                initials={ <Icons.NoImage /> }
                link={`/collection/${slug}`}
                label={`^${slug}`}
                dataClass={'with-link'}
                shape="square"
                badgeIcon={ <Icons.Collection type="small" /> }
                avatarAction={
                    <Link to={`/${post.author.nickname}`} className="site-text-color">
                        { post.author.nickname }
                    </Link>
                }
            />
            <CardMenu>
                <CollectionCardMenu { ...props } />
            </CardMenu>
        </div>
    );

};

export default CardHeroTypeCollection;