import React, {useState} from "react";
import {Button, Modal} from "antd";

import AttachmentsList from "../attachments-list";
import Icons from "../../icons";
import { Localize } from "components/service";


const AttachmentsPopup = ({ attachments, attached_file_count = 0 }) => {

    const [ showAttachments, setShowAttachments ] = useState(false);

    return (
        <>
            <Button
                type={'link'}
                onClick={ () => setShowAttachments(true) }
            >
                <Icons.File />
                <Localize wrap attachmentCount={ attached_file_count }>
                    { attached_file_count > 1  ?
                        "POST.Button_Text_Attachments":
                        "POST.Button_Text_Attachment"
                    }
                </Localize>
            </Button>
            <Modal
                title={ <Localize wrap="h3">POST.Modal_Title_Attachments</Localize>}
                closeIcon={
                    <Icons.Close />
                }
                footer={null}
                visible={ showAttachments }
                onCancel={ () => setShowAttachments(false)}
                wrapClassName="modal-actions modal-attachments-list"
                centered
            >
                <AttachmentsList attachments={ attachments } />
            </Modal>
        </>
    );
};

export default AttachmentsPopup;