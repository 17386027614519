import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, Checkbox, Form, Input } from "antd";

import { UPDATE_COLLECTION } from 'graphql/mutation/collection-gql';

import CollectionDeleteButton from "../collection-delete-button";
import { ElementVisibility, Localize } from "components/service";
import { errorNotification, successNotification } from "components/result";


const formSubmit = ({ event, form, updateCollection, id }) => {
    event.preventDefault();

    form.validateFields((err, values) => {
        if (!err) {

            updateCollection({
                variables : {
                    collection_id: id,
                    input: {
                        title: values.title,
                        description: values.description,
                        slug: values.slug,
                        status: values.status ? "public" : "private"
                    }
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    });

};

const CollectionDataFormNormal = (props) => {

    const {
        form,
        collection,
        history
    } = props;

    const [updateCollection, { loading : updateLoading}] = useMutation(UPDATE_COLLECTION, {
        update : (cache, { data }) => {

            const {
                editCollection : {
                    label,
                    message,
                    collection : {
                        slug
                    }
                }
            } = data;

            successNotification({
                title: label,
                description: message,
            });

            history.push(`/collection/${slug}`);
        }
    });

    const formItemLayout ={
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const { getFieldDecorator  } = form;

    return (
        <div className="collection-update-details">
            <Form
                onSubmit={(event) => {
                    formSubmit({
                        event,
                        form,
                        updateCollection,
                        id: collection.id
                    });
                }}
                className="collection-data-form"
            >
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_CollectionName</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('title', {
                        initialValue: collection.title,
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_CollectionUniqueName</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('slug', {
                        rules: [
                            { min: 4, message: <Localize minLength="4">FORM_RULES.Min_CollectionSlug</Localize> },
                            {
                                type: "string",
                                pattern: /^[a-z0-9-_]+$/,
                                message: <Localize>FORM_RULES.RegExp_CollectionSlug</Localize>
                            },
                            { required: true, message: <Localize>FORM_RULES.Required_CollectionSlug</Localize> }

                        ],
                        initialValue: collection.slug,
                    })(
                        <Input placeholder={ Localize({children: "FORMS.Input_Placeholder_Slug" }).props.children } />
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_Description</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('description', {
                        initialValue: collection.description,
                    })(
                        <Input.TextArea
                            autoSize={{ minRows: 6, maxRows: 10 }}
                        />
                    )}
                </Form.Item>
                <Form.Item className="collection-status-block">
                    {getFieldDecorator('status', {
                        valuePropName: 'checked',
                        initialValue: collection.status === "public",
                    })(
                        <Checkbox>
                            <Localize>FORMS.Checkbox_Label_CollectionPublic</Localize>
                        </Checkbox>
                    )}
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <ElementVisibility maxWidth={ 768 } type="hide" comparison="<">
                            <div>
                                <CollectionDeleteButton
                                    collection_id={ collection.id }
                                    buttonType="antButton"
                                />
                            </div>
                        </ElementVisibility>
                        <div>
                            <Button
                                className="rounded-button"
                                onClick={ () => history.push(`/collection/${collection.slug}`) }
                            >
                                <Localize>GLOBAL.Button_Text_Cancel</Localize>
                            </Button>
                            <Button
                                loading={ updateLoading }
                                type="primary"
                                htmlType="submit"
                                className="rounded-button"
                            >
                                <Localize wrap>GLOBAL.Button_Text_Save</Localize>
                            </Button>
                        </div>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

const CollectionDataForm = Form.create({ name: 'collection_update_data' })(CollectionDataFormNormal);

export default CollectionDataForm;