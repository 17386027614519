import { gql } from "apollo-boost";

import { chatFieldsFull, chatUserFields, chatMessageFieldsFull } from "../fragment/chat-frgm";
import { pageInfoFieldsFull } from "../fragment/pagination-frgm";


export const GET_CHATS = gql`
    query  GetChats {
        chats{
            pageInfo{
                ...pageInfoFieldsFull
            }
            edges{
                node{
                    ...chatUserFields
                }
            }
        }
    }
    ${ pageInfoFieldsFull }
    ${ chatUserFields }
`;

export const SEARCH_CHATS = gql`
    query  SearchChats( 
            $filter: String
            $end_cursor: String 
        ) {
        chatSearch(
            filter : $filter 
            after : $end_cursor
        ){
            pageInfo{
                ...pageInfoFieldsFull
            }
            edges{
                node{
                    ...chatFieldsFull
                }
            }
        }
    }
    ${ pageInfoFieldsFull }
    ${ chatFieldsFull }
`;

export const GET_CHAT = gql`
    query  GetChat( 
            $id : ID! 
        ) {
        chat( id : $id ){
            ...chatFieldsFull
        }
    }
    ${ chatFieldsFull }
`;


export const GET_CHAT_MESSAGES = gql`
    query  GetChatMessages( 
            $id: ID
            $chat_id: ID!
            $paginate: TypePaginate
            $defaultCount : Int!
        ) {
        chatMessages( 
            id : $id
            chat_id : $chat_id
            paginate: $paginate
            defaultCount: $defaultCount
        ){
            ...chatMessageFieldsFull
        }
    }
    ${ chatMessageFieldsFull }
`;