import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Upload } from "antd";

import { GET_COLLECTION } from "graphql/query/collection-gql";
import { UPLOAD_COLLECTION_COVER } from "graphql/mutation/collection-gql";

import AvatarBlock from "components/avatar";
import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";


const CollectionUpdateCover = (props) => {

    const {
        collection : {
            id,
            cover_url
        }
    } = props;

    const [collectionUploadCover, { loading }] = useMutation(UPLOAD_COLLECTION_COVER,
        {
            update(cache, { data }) {

                const {
                    uploadCoverCollection : {
                        label,
                        message,
                        collection :{
                            slug,
                            cover_url,
                        }
                    }
                } = data;

                const { collection } = cache.readQuery({
                    query: GET_COLLECTION,
                    variables: {
                        slug : slug
                    },
                });

                collection.cover_url = cover_url;

                cache.writeQuery({
                    query: GET_COLLECTION,
                    data: {
                        collection
                    },
                });

                successNotification({
                    title: label,
                    description: message,
                });
            }
        });

    const uploadProps = {
        accept: 'image/*',
        beforeUpload: (file) => {
            collectionUploadCover({
                variables: {
                    collection_id: id,
                    file: file
                }
            }).catch((error) => {
                errorNotification(error);
            });

            return false;
        },
        showUploadList: false
    };

    return(
        <div className="collection-cover">
            <p className="site-label-text-color">
                <Localize>FORMS.Upload_Label_Cover</Localize>
            </p>
            <Upload {...uploadProps}>
                <AvatarBlock
                    photoUrl={ cover_url }
                    initials={ <div className="site-badge"><Icons.Plus /></div> }
                    avatarClass={ cover_url.length === 0 ? 'empty' : '' }
                    dataClass="profile"
                    size={150}
                    shape="square"
                    loading={loading}
                    avatarAction={
                        <button className="upload-avatar-but company-color">
                            <Localize>GLOBAL.Button_Text_ChangeCover</Localize>
                        </button>
                    }
                />
            </Upload>
        </div>
    );
};

export default CollectionUpdateCover;