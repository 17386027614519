import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Button, Modal } from "antd";

import { TRASH_POST } from "graphql/mutation/post-gql";

import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";

import './post-remove-button.css';


const confirmRemovePost = (props) => {
    const {
        removePost,
        postId,
        history,
        myNickname,
        hideModal
    } = props;

    removePost({
        variables: {
            post_id: postId,
        },
        update: (cache, { data }) => {

            const {
                trashPost:{
                    label,
                    message
                }
            } = data;

            successNotification({
                title: label,
                description: message,
            });

            hideModal();

            history.push(`/${myNickname}/drafts`);
        }
    }).catch((error) => {
        errorNotification(error);
    });
};

const PostRemoveButton = (props) => {

    const [ showModal, setShowModal ] = useState(false);
    const { postId, myNickname, smallButton } = props;

    const history = useHistory();

    const [trashPost, { loading : trashLoading }] = useMutation(TRASH_POST);

    return(
        <>
            { smallButton ?
                <Button
                    className="small-rounded-button site-icon"
                    shape="circle"
                    onClick={ () => setShowModal(true) }
                >
                    <Icons.Trash />
                </Button>:
                <Button
                    loading={ trashLoading }
                    className="rounded-button"
                    onClick={() => setShowModal(true)}
                >
                    <Localize wrap>POST.Button_Text_DeletePost</Localize>
                </Button>
            }
            <Modal
                title={ <Localize>POST.Modal_Title_Delete</Localize> }
                className="remove-post-modal"
                visible={showModal}
                onOk={
                    () => confirmRemovePost(
                    {
                            history,
                            removePost : trashPost,
                            postId,
                            myNickname,
                            hideModal : () =>  setShowModal(false)
                        }
                )}
                onCancel={() => setShowModal(false)}
                okText={
                    <Localize>GLOBAL.Button_Text_Yes</Localize>
                }
                okButtonProps={
                    {
                        loading : trashLoading,
                        className : "rounded-button"
                    }
                }
                cancelText={
                    <Localize>GLOBAL.Button_Text_No</Localize>
                }
                cancelButtonProps={
                    { className : "rounded-button" }
                }
            >
                <Localize wrap="p">POST.Text_ModalDelete_Question</Localize>
            </Modal>
        </>
    );
};

export default PostRemoveButton;