import CardHeroTypeUser from "./card-hero-type-user";
import CardHeroTypeCollection from "./card-hero-type-collection";
import CardHeroTypeHashtag from "./card-hero-type-hashtag";


const CardHeroType = {
    User : CardHeroTypeUser,
    Collection : CardHeroTypeCollection,
    Hashtag : CardHeroTypeHashtag
};

export default CardHeroType;