import React from "react";
import Icons from "../../icons";

const CollectionCardCoverImage = ({ cover_url, title, posts }) => {

    return (
        <div className="cover-image">
            { cover_url || posts.edges.length > 0 ?
                <img src={ cover_url ? cover_url : posts.edges[0].node.media[0].thumbnail } alt={ title } /> :
                <div>
                    <Icons.NoImage />
                </div>
            }
        </div>
    );
};

export default CollectionCardCoverImage;