import React from "react";

import ChatMessageType from "./type";
import AvatarPhoto from "components/avatar/avatar-photo";
import { LinkedMention } from "components/service";
import Icons from "components/icons";

import "./chat-message-item.css";


const ChatMessageItem = ({
        message,
        myMessage,
        showName,
        showAvatar,
        noReply = false
    }) => {

    return(
        <div className={ `chat-message ${ myMessage ? "my-message" : "" } ${ showAvatar ? "" : "no-avatar" }` }>
            { showAvatar &&
                <div className="chat-message-avatar-wrap">
                    <AvatarPhoto
                        photoUrl={ message.author.avatar }
                        size={32}
                        initials={  message.author.initials }
                    />
                </div>
            }
            <div className="chat-message-content-wrap">
                { showName &&
                    <h5 className="chat-message-author company-color">
                        { message.author.name }
                    </h5>
                }
                <div className="chat-message-content">
                    { message.response && !noReply &&
                    <ChatMessageType.Reply
                        message={ {...message.response } }
                    />
                    }
                    <div>
                        <LinkedMention searchSigns={ ['@', '^', '#'] }>
                            {message.content}
                        </LinkedMention>
                    </div>
                    <div className="chat-message-status">
                        <span className="date site-sub-text-light-color" title={message.created_at}>
                            { message.message_date }
                        </span>
                        { myMessage &&
                            <span className={ `state ${ message.status }` }>
                                { message.status === "send" &&
                                    <Icons.Check />
                                }
                                { message.status !== "send" &&
                                    <Icons.Check type="double" />
                                }
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

};

export default ChatMessageItem;