import { ApolloLink, Observable } from "apollo-link";

class WsEchoLink extends ApolloLink {
    request(operation, forward) {
        return new Observable((observer) => {
            let subscriptionChannel;

            // Check the result of the operation
            forward(operation).subscribe({
                next: (data) => {
                    // If the operation has the subscription channel, it's a subscription
                    subscriptionChannel = WsEchoLink.getChannelFromResponse(
                        data,
                        operation
                    );

                    // No subscription found in the response, pipe data through
                    if (!subscriptionChannel) {
                        observer.next(data);
                        observer.complete();

                        return;
                    }

                    WsEchoLink.subscribeToChannel(subscriptionChannel, observer);
                },
            });

            // Return an object that will unsubscribe _if_ the query was a subscription.
            return {
                closed: false,
                unsubscribe: () => {
                    if (subscriptionChannel !== null) {
                        WsEchoLink.unsubscribeFromChannel(subscriptionChannel);
                    }
                },
            };
        });
    }

    static subscribeToChannel(subscriptionChannel, observer) {
        const privateChannelName = subscriptionChannel.split("private-").pop();

        window.Echo.private(privateChannelName).listen(
            ".lighthouse-subscription",
            (payload) => {
                if (!payload.more) {
                    WsEchoLink.unsubscribeFromChannel(subscriptionChannel);
                    return;
                }

                const { result } = payload;
                if (result) {
                    observer.next({
                        data: result.data,
                        extensions: result.extensions,
                    });
                }
            }
        );
    }

    static unsubscribeFromChannel(subscriptionChannel){
        window.Echo.leave(subscriptionChannel);
    }

    static getChannelFromResponse(response, operation) {
        return !!response.extensions &&
        !!response.extensions.lighthouse_subscriptions &&
        !!response.extensions.lighthouse_subscriptions.channels
            ? response.extensions.lighthouse_subscriptions.channels[
                operation.operationName
                ]
            : null;
    }
}

export default WsEchoLink;