import React from 'react';

import { ContainerWrap } from "../../wraps";
import PostUpdate from "../../posts/post-update";

const PostUpdatePage = () => {
    return (
        <ContainerWrap className="container-margin">
            <PostUpdate />
        </ContainerWrap>
    );
};

export default PostUpdatePage;