import React from "react";

import "./chat-create-selected-users.css";


const ChatCreateSelectedUsers = ({ selectedUsers, setSelectedUsers }) => {

    return(
        <div className={ `chat-create-selected-users ${ selectedUsers.length < 1 ? "empty" : "" }` }>
            <h4 className="label">To</h4>
            { selectedUsers.length > 0 &&
                <ul className="list">
                {
                    selectedUsers.map(( user ) => {
                        return (
                            <li key={ user.id }>
                                <button
                                    className="company-bg-color with-font-color"
                                    onClick={
                                        () => setSelectedUsers([
                                            ...selectedUsers.filter( (item) => item.id !== user.id)
                                        ])
                                    }
                                >
                                    { user.slug }
                                </button>
                            </li>
                        );
                    })
                }
                </ul>
            }
        </div>
    )
};

export default ChatCreateSelectedUsers;