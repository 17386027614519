import { gql } from "apollo-boost";

import { collectionPostActionFields, collectionFieldsData } from "../fragment/collection-frgm";

export const ADD_COLLECTION = gql`
    mutation AddCollection($input : CollectionInput!) {
        addCollection(input  : $input){
            label
            message
            collection{
                slug
            }
        }
    }`;

export const UPDATE_COLLECTION = gql`
    mutation EditCollection(
        $collection_id : ID!,
        $input: CollectionInput!
    ) {
        editCollection(
            collection_id : $collection_id,
            input: $input
        ){
            label
            message
            collection{
                ...collectionFieldsData
                status
            }
        }
    }
    ${ collectionFieldsData } 
`;

export const UPLOAD_COLLECTION_COVER = gql`
    mutation UploadCoverCollection($collection_id: ID!, $file: Upload!) {
        uploadCoverCollection(collection_id: $collection_id, file: $file){
            label
            message 
            collection{
                ...collectionFieldsData
            }
        }
    }
    ${ collectionFieldsData }
`;

export const REMOVE_COLLECTION = gql`
    mutation RemoveCollection($collection_id: ID!) {
        removeCollection(collection_id: $collection_id){
            label
            message 
            collection{
                id
                author{
                    id
                    nickname
                }
            }
        }
    }`;

export const COLLECTION_ADD_POST = gql`
    mutation AddPostToCollection($collection_id: ID, $post_id: ID!) {
        addPostToCollection(collection_id: $collection_id, post_id: $post_id){
            label
            message 
            collection{
                ...collectionPostActionFields
            }
        }
    }
    ${ collectionPostActionFields }
`;

export const COLLECTION_REMOVE_POST = gql`
    mutation RemovePostInCollection($collection_id: ID!, $post_id: ID!) {
        removePostInCollection(collection_id: $collection_id, post_id: $post_id){
            label
            message 
            collection{
                ...collectionPostActionFields
            }
        }
    }
    ${ collectionPostActionFields }
`;