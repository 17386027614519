import React from 'react';
import { Col, Row } from "antd";


const UpdateWrap = ({children, className = null}) => {
    return (
        <Row className={className} type="flex" gutter={24} justify="center">
            <Col xs={23} md={24} lg={24} xl={22} xxl={20} span={24}>
                {children}
            </Col>
        </Row>
    );
};

export default UpdateWrap;