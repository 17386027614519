import React from 'react';
import { Icon } from 'antd';

const SuccessSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M12.7,19.5l-5-5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3.6,3.6l7-7c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L12.7,19.5z"/>
        <path d="M14,28C6.3,28,0,21.7,0,14S6.3,0,14,0s14,6.3,14,14S21.7,28,14,28z M14,2C7.4,2,2,7.4,2,14s5.4,12,12,12s12-5.4,12-12
		S20.6,2,14,2z"/>
    </svg>
);

const ErrorSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M19.2,8.8c-0.4-0.4-1-0.4-1.4,0L14,12.6l-3.8-3.8c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3.8,3.8l-3.8,3.8
		c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3.8-3.8l3.8,3.8c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
		c0.4-0.4,0.4-1,0-1.4L15.4,14l3.8-3.8C19.6,9.8,19.6,9.2,19.2,8.8z"/>
        <path d="M14,0C6.3,0,0,6.3,0,14s6.3,14,14,14s14-6.3,14-14S21.7,0,14,0z M14,26C7.4,26,2,20.6,2,14S7.4,2,14,2s12,5.4,12,12
		S20.6,26,14,26z"/>
    </svg>
);

const InfoSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M14,7.5c-0.6,0-1,0.4-1,1v7.8c0,0.6,0.4,1,1,1s1-0.4,1-1V8.5C15,7.9,14.6,7.5,14,7.5z"/>
        <path d="M14,18.2c-0.6,0-1,0.4-1,1v0.3c0,0.6,0.4,1,1,1s1-0.4,1-1v-0.3C15,18.6,14.6,18.2,14,18.2z"/>
        <path d="M14,0C6.3,0,0,6.3,0,14s6.3,14,14,14s14-6.3,14-14S21.7,0,14,0z M14,26C7.4,26,2,20.6,2,14S7.4,2,14,2s12,5.4,12,12
		S20.6,26,14,26z"/>
    </svg>
);


const ActionStatusIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    if(props.type === "success"){
        return <Icon component={ SuccessSvg }  />;
    }

    if(props.type === "error"){
        return <Icon component={ ErrorSvg }  />;
    }

    return <Icon component={ InfoSvg }  />;
};

export default ActionStatusIcon;