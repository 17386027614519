import React from 'react';
import { Row } from "antd";
import ListType from './card-list-item-types';

import './card-list.css';


const Cards = (props) => {

    const {
        cardItems,
        type,
        action,
        path,
        view = "grid",
        showAuthor = true,
    } = props;

    return cardItems.map(({node}) => {

        const {id} = node;

        switch (type) {
            case 'homeTimeline':

                if(node.post === null){
                    console.error('HOME TIMELINE POST NULL', node);
                    return null;
                }

                if( node.post.status !== "public" ){
                    return null;
                }

                return <ListType.Dashboard key={id} { ...node } />;

            case 'draft':
                return <ListType.DraftGrid key={id} post={node} />;

            case 'actionPostGrid' :
                return <ListType.ActionGrid
                    key={id}
                    post={node}
                    action={ action }
                />;

            case 'collectionPreview' :
                return <ListType.CollectionPreview
                    key={id}
                    node={node}
                    prefixPath={ path }
                    view={ view }
                />;

            case 'hashtagPreview' :
                return <ListType.HashtagPreview
                    key={id}
                    node={node}
                    prefixPath={ path }
                    view={ view }
                />;

            case 'timeline' :

                if(node.post){

                    if( node.post.status !== undefined && node.post.status === "deleted" ){
                        return null;
                    }
                } else {
                    if( node.status !== undefined && node.status === "deleted" ){
                        return null;
                    }
                }

                return <ListType.Timeline
                    key={id}
                    node={
                        node.post ?
                            node :
                            { id : node.id, post: node }
                    }
                />;

            default:
                return <ListType.Preview
                    key={id}
                    post={node}
                    view={ view }
                    showAuthor={ showAuthor }
                />;
        }

    });
};

const CardList = (props) => {

    const gutter = props.view === 'row' ?
                [{ xs: 0, sm: 0 }, { xs: 0, sm: 0}] :
                [{ xs: 2, sm: 24}, { xs: 2, sm: 24}];

    return (
        <Row
            type="flex"
            gutter={ [...gutter] }
            className={ `card-list ${props.className !== undefined ? props.className : ""}`}
        >
            <Cards {...props} />
        </Row>
    );
};

export default CardList;