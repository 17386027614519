import React, { useState } from 'react';

import SearchForm from "../search-form";
import SearchResultList from "../search-result-list";

import '../search.css';


const SearchUsers = ({ actionButton, elementClass, scrollElementClass }) => {

    const [searchVariables, setSearchVariables] = useState({ type: 'user', input: '' });

    return (
        <div className="search-holder">
            <SearchForm
                setSearchVariables={ setSearchVariables }
                searchVariables={ searchVariables }
                placeholder={ "SEARCH.Input_Placeholder_User" }
                prefix={ false }
                fixedType
            />
            <SearchResultList
                searchVariables={searchVariables}
                elementClass={ elementClass }
                scrollElementClass={ scrollElementClass }
                actionButton={ actionButton }
            />
        </div>
    );
};

export default SearchUsers;