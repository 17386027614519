import React from 'react';
import { Icon } from 'antd';

const NoImageSvg = () => (
    <svg fill="currentColor"  width="1.52em" height="1em" viewBox="0 0 170 116.7">
        <polygon points="150.1,86.3 130.2,55.9 110.2,86.3 108,89.8 94.2,68.8 62.8,20.9 31.4,68.8 0,116.7 62.8,116.7
		                 90.3,116.7 125.6,116.7 130.2,116.7 170,116.7 	"/>
        <path d="M113.4,29.7c8.2,0,14.8-6.6,14.8-14.8c0-8.2-6.6-14.8-14.8-14.8c-8.2,0-14.8,6.6-14.8,14.8
		         C98.5,23,105.2,29.7,113.4,29.7z"/>
    </svg>
);


const NoImageIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ NoImageSvg }/>;
};


export default NoImageIcon;