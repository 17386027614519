export const copyLink = (link) =>{

    if(link.indexOf(window.location.hostname)){
        copyText(window.location.protocol + '//' + window.location.hostname + link );
    } else {
        copyText( link );
    }

};

export const copyText = (text) => {

    const textField = document.createElement('textarea');

    textField.innerText = text;

    document.body.appendChild(textField);
    textField.select();

    document.execCommand('copy');

    textField.remove();

}