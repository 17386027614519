import React from "react";
import {notification} from "antd";

import Icons from "../../icons";


const errorNotification = (error) => {

    if( error.graphQLErrors !== undefined ){

        error.graphQLErrors.map(({ message: label, extensions : {reason} }) => {
            notification['error']({
                message: label ,
                description: reason,
                className: "error-notification",
                closeIcon: <Icons.Close />,
                icon: <Icons.ActionStatus type="error"/>
            });

            return false;
        });

    }

    return null;
};

export default errorNotification;