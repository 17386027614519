import React from "react";
import { /*useApolloClient,*/ useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";

import { SEARCH_CHATS } from "graphql/query/chat-gql";
import { GET_ME } from "graphql/query/user-gql";

import ChatListItem from "../chat-list-item";
import { LoadMoreOnScroll } from "../../service";
import { Loader } from "components/result";
import { chatsFetchMoreHelper } from "../helpers";
import { decode, getHolderElement } from "utils";

import "./chat-list.css";


const ChatListItems = ({ chats, me, chatId }) => {

    chatSortByMessageCreateAt(chats);

    return chats.map( ( {node} ) => {
        return(
            <ChatListItem
                chat={ node }
                me={ me }
                activeChatId={ chatId }
                key={ node.id }
            />
        )
    });

};

const ChatList = ({ filterQuery = "", scrollElementClass }) => {

    const { chatSlug } = useParams();

    const { loading, data , fetchMore, client } = useQuery(SEARCH_CHATS, {
        variables: {
            filter : filterQuery
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const { me } = client.readQuery({ query: GET_ME });

    const [ chatId ] = chatSlug ? decode.fromSlug(chatSlug) : [] ;

    const {
        chatSearch : {
            pageInfo: {
                hasNextPage,
                endCursor
            } = {},
            edges : chats = []
        } = {}
    } = data !== undefined ? data : {};

    const resultHolder = getHolderElement(".chat-list", scrollElementClass);

    return (
        <ul className="chat-list">
            { data !== undefined &&
                <>
                    <ChatListItems
                        chats={ chats }
                        me={ me }
                        chatId={ chatId }
                    />
                    { hasNextPage &&
                        <LoadMoreOnScroll
                            fetchAction={() => chatsFetchMoreHelper({
                                hasNextPage,
                                endCursor,
                                loading,
                                fetchMore,
                                chats
                            })}
                            loading={ false }
                            {...resultHolder}
                        />
                    }
                </>
            }
            { loading && <li><Loader /></li> }
        </ul>
    );

};

const chatSortByMessageCreateAt = (chats) => {

    chats.sort( ( { node : nextChat}, { node : currentChat}) => {

        let curDate = 0,
            nextDate = 0;

        if(currentChat.messages !== undefined && currentChat.messages.edges.length > 0){
            curDate = Date.parse(currentChat.messages.edges[0].node.created_at);
        } else {
            curDate = Date.parse(currentChat.created_at);
        }

        if(nextChat.messages !== undefined && nextChat.messages.edges.length > 0){
            nextDate = Date.parse(nextChat.messages.edges[0].node.created_at);
        } else {
            nextDate = Date.parse(nextChat.created_at);
        }

        return curDate > nextDate ? 1 : -1;

    });

    return chats;
};


export default ChatList;