import React from "react";
import { useHistory } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Avatar } from "antd";

import { NOTIFICATIONS_READ } from "graphql/mutation/notification-gql";
import { GET_NOTIFICATION } from "graphql/query/notification-gql";

import AvatarBlock from "../../avatar";
import { LinkedMention } from "../../service";
import { errorNotification } from "../../result";


const NotificationItems = (props) => {

    const {
        notifications,
        onClickItem = () => {}
    } = props;
    const history = useHistory();

    const client = useApolloClient();

    const [ clickNotification ] = useMutation(NOTIFICATIONS_READ);

    return notifications.map( ({ node }) => {

        const {
            id,
            type,
            status,
            notifi_data
        } = node;

        if(!notifi_data){
            return null;
        }

        const {
            message,
            obj_left,
            obj_right
        } = notifi_data;

        const onClickNotification = (event) => {

            onClickItem();

            if(!status) {

                clickNotification({
                    variables: {
                        ids: [id]
                    },
                    update : () => {

                        const {myNotification} = client.readQuery({
                            query: GET_NOTIFICATION,
                            variables: {
                                id: id,
                            }
                        });

                        myNotification.status = true;

                        client.writeQuery({
                            query: GET_NOTIFICATION,
                            data: {
                                myNotification
                            },
                        });

                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }

            if(event.target.className !== 'inner-link') {
                if(type === "new_subscription") {
                    history.push(`/${obj_left.slug}`);
                } else {
                    history.push(`/post/${obj_right.slug}`);
                }
            }
        };

        const notificationClass = `post-notification ${ !status ? 'not-read' : ''}`;

        const Label = () => {

            return(
                <>
                    <LinkedMention  searchSigns={ ['@', '^', '#'] }>
                        { message }
                    </LinkedMention>
                    { obj_right.text !== "" && type !== "new_subscription" ?
                        <span>{`: "${obj_right.text}"`}</span> : null
                    }
                </>
            );

        };

        return (
            <li
                key={id}
                className={ notificationClass  }
            >
                <button onClick={ (event) => onClickNotification(event)}>
                    <AvatarBlock
                        photoUrl={ obj_left.image }
                        initials={ obj_left.text }
                        label={ <Label />  }
                        size={ 50 }
                    />
                    { type !== "new_subscription" &&
                        <div className="right-side">
                            <Avatar
                                src={obj_right.image}
                                size={50}
                                shape="square"
                            />
                        </div>
                    }
                </button>
            </li>
        );
    });
};

export default NotificationItems;