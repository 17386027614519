import React from 'react';
import {Col} from "antd";

import CardPreview from "../../card-preview";


const CardListDraftGrid  = (  { post } ) => {

    return (
        <Col span={8} className="card-preview-holder">
            <CardPreview
                post={post}
                title
                path={'/post/edit/'}
            />
        </Col>
    );
};


export default CardListDraftGrid;