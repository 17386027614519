import React from "react";

import { AvatarThreeRow } from "components/avatar";
import CardMenu from "components/card/card-menu";
import { PostCardMenu } from "components/posts";
import { Localize } from "components/service";


const CardHeroTypeUser = (props) => {

    const {
        post : {
            author : {
                avatar,
                initials,
                nickname,
                name,
                status,
                email
            }
        }
    } = props;

    return(
        <div className="card-hero">
            <AvatarThreeRow
                photoUrl={ avatar }
                initials={ initials }
                blocked={ status === "block" ? "normal" : "" }
                size={ 45 }
                link={`/${ nickname }`}
                nickname={ nickname }
                title={ status !== "block" ? name : "" }
                text={
                    <>
                        { !!email && status !== "block" &&
                            email
                        }
                        { status === "block"  ? <Localize>GLOBAL.Text_InactiveUser</Localize> : "" }
                    </>
                }
                type="two"
            />
            <CardMenu>
                <PostCardMenu { ...props } />
            </CardMenu>
        </div>
    )

};

export default CardHeroTypeUser