import React from 'react';
import { Link } from 'react-router-dom';

import { ContainerWrap } from '../../wraps';
import HeaderActions from "./header-actions";

import './header.css';

import logo from 'assets/desktop_logo.svg';


const Header = () => {

    return (
        <div className="header-fixer">
            <header className="header-bg-color header-color">
                <ContainerWrap>
                    <div className="header-row">
                        <div className="left-side">
                            <div className="logo-img">
                                <Link to="/">
                                    <img src={logo} alt="logo"/>
                                </Link>
                            </div>
                        </div>
                        <div className="right-side">
                            <HeaderActions/>
                        </div>
                    </div>
                </ContainerWrap>
            </header>
            <div className="header-placeholder" />
        </div>
    );
};

export default Header;