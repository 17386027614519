import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "antd";

import { GET_POST_COMMENTS } from "graphql/query/comment-gql";
import { DELETE_COMMENT } from "graphql/mutation/comment-gql";

import { errorNotification, successNotification } from "../../result";
import Icons from "../../icons";

import "./comment-delete-button.css";


const CommentDeleteButton = ({ id, postId }) => {

    const [ onDeleteClick, { loading } ] = useMutation(DELETE_COMMENT, {
        update : (cache, { data : { deleteComment } }) => {

            const {label, message} = deleteComment;

            const {post} = cache.readQuery({
                query: GET_POST_COMMENTS,
                variables: {
                    post_id: postId,
                }
            });

            const {
                comments : {
                    pageInfo,
                    edges,
                    __typename
                }
            } = post;

            cache.writeQuery({
                query: GET_POST_COMMENTS,
                variables: {
                    post_id : postId
                },
                data: {
                    post: {
                        ...post,
                        comments: {
                            pageInfo : {
                                ...pageInfo,
                                total : pageInfo.total - 1
                            },
                            edges : edges.filter( ({node}) => node.id !== id),
                            __typename
                        }
                    }
                },
            });

            successNotification({
                title: label,
                description: message,
            });
        }
    });

    const onClickDelete = () => {
        if(!loading) {

            onDeleteClick({
                variables: {
                    id: id,
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };


    return (
      <Button
          className="comment-delete-button"
          onClick={ onClickDelete }

      >
          <Icons.Trash loading={loading} />
      </Button>
    );
};

export default CommentDeleteButton;