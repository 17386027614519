import React from 'react';
import {Col} from "antd";

import CardPost from "../../card-post";


const CardListTimeline  = (props) => {

    const {
        node : {
            id,
            post
        }
    } = props;

    return (
        <Col id={ `post-in-timeline-${id}`} span={24} className="card-timeline-holder" >
            <CardPost
                post={post}
            />
        </Col>
    );

};


export default CardListTimeline;