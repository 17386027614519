import React from 'react';
import { Icon } from 'antd';

const PublicSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 18 18">
        <path d="M9,1.7C5,1.7,1.7,5,1.7,9S5,16.3,9,16.3S16.3,13,16.3,9S13,1.7,9,1.7z M9,14.9c-1.6,0-3-0.6-4.1-1.6L5,13.2
		         c0-0.1,0.1-0.1,0.1-0.2v-1.1l0.5-0.5c0.1-0.1,0.1-0.2,0.1-0.2v-0.9c0-0.1,0-0.2-0.1-0.2L5,9.3C4.9,9.3,4.9,9.2,4.8,9.2H3.3
		         c-0.1,0-0.1,0-0.2,0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2h0.5c0.2,0,0.4-0.2,0.4-0.4V7.9l0.5-0.4H5c0.2,0,0.3-0.1,0.3-0.3
		         c0.1-1-0.4-1.6-0.9-1.9C5.5,3.9,7.2,3.1,9,3.1c1,0,2,0.3,2.8,0.7l-0.3,0.3h-1.5c-0.1,0-0.2,0-0.2,0.1L9.2,4.8c-0.4,0-1.2,0-1.7,0.4
		        C7.2,5.5,7.1,5.8,7.1,6.2v1.2c0,0.2,0.2,0.4,0.4,0.4h2.2l1.2,1.2C11,9,11.1,9,11.2,9h1c0.1,0,0.2,0,0.2-0.1l0.4-0.4h0.3l0.6,1.1
		        c0.1,0.1,0.2,0.2,0.3,0.2h0.7C14.5,12.7,12,14.9,9,14.9z"
        />
        <path d="M11.6,10.1L10,8.5C9.9,8.4,9.8,8.4,9.7,8.4H8.1c-0.1,0-0.2,0-0.2,0.1L7.1,9.3C7,9.4,7,9.4,7,9.5v1.3c0,0.2,0.2,0.4,0.4,0.4
		         h1.2l0.3,0.4v2.3c0,0.2,0.2,0.4,0.4,0.4H10c0.1,0,0.2,0,0.2-0.1l1.4-1.3c0.1-0.1,0.1-0.2,0.1-0.3l-0.1-2.2
		         C11.7,10.2,11.7,10.2,11.6,10.1z"
        />
    </svg>
);


const PublicIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ PublicSvg }/>;
};


export default PublicIcon;