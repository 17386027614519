import { InMemoryCache } from "apollo-cache-inmemory";


const cache = new InMemoryCache({
    cacheRedirects: {
        Query: {
            post : (_, args, { getCacheKey }) => {
                if(args.post_id) {
                    return getCacheKey({__typename: 'Post', id: args.post_id});
                }
            },
            user : (_, args, { getCacheKey }) => {
                if(args.user_id) {
                    return getCacheKey({__typename: 'User', id: args.user_id});
                }
            },
            myNotification : (_, args, { getCacheKey }) => {
                if(args.id) {
                    return getCacheKey({__typename: 'Notification', id: args.id});
                }
            },
        },
    },
});

export default cache;