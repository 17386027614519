import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";

import { GET_SEARCH_RESULTS } from "graphql/query/search-gql";

import AvatarBlock from "../../avatar";
import {LoadMoreOnScroll, Localize} from "../../service";
import { errorNotification, Loader } from "../../result";
import { getHolderElement } from "utils";


const getLinkPrefix = (searchType) => {

    switch(searchType){
        case 'user':
            return '';
        case 'hashtag':
            return 'tag/';
        case 'collection':
            return 'collection/';
        case 'team':
            return 'team/';
        default: return 'post/';
    }

};

const RenderItems = (props) => {

    const {
        resultItems,
        searchType,
        ActionButton,
        withAction
    } = props;

    return resultItems.map( (item) => {

        const { id, title, image, short_description, slug } = item;

        let label = title;
        let short_text = short_description.length >= 1 ? `${short_description.slice(0, 30)}...` : '';
        let linkPrefix = getLinkPrefix(searchType);
        let shape = 'circle';

        if(searchType === 'user'){
            short_text = title;
            label = slug;
        }

        if(searchType === 'post'){
            shape = 'square';
        }

        if(withAction){
            return(
                <li key={id}>
                    <AvatarBlock
                        photoUrl={ image }
                        initials={ title.slice(0, 2) }
                        label={label}
                        text={ short_text }
                        shape={shape}
                    />
                    <ActionButton id={id} objData={ item } />
                </li>
            );
        }

        return (
            <li key={id}>
                <Link to={`/${linkPrefix}${slug}`}>
                    <AvatarBlock
                        photoUrl={ image }
                        initials={ title.slice(0, 2) }
                        label={label}
                        text={ short_text }
                        shape={shape}
                    />
                </Link>
            </li>
        )
    });
};

const SearchResultList = (props) => {

    const {
        searchVariables,
        actionButton,
        elementClass,
        scrollElementClass
    } = props;

    const {loading, data, fetchMore, networkStatus} = useQuery(GET_SEARCH_RESULTS, {
        variables: {
            text : searchVariables.input === "$$$" ? '' : searchVariables.input,
            type : searchVariables.type
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        search:{
            data : searchResult,
            __typename : typename,
            paginatorInfo
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        const { hasMorePages, currentPage } = paginatorInfo;

        if( !loading && hasMorePages){

            fetchMore({
                variables: {
                    page : currentPage + 1
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newResults = fetchMoreResult.search.data;
                    const paginatorInfo = fetchMoreResult.search.paginatorInfo;

                    const newSearchResults = {
                        search: {
                            data : [
                                ...searchResult,
                                ...newResults
                            ],
                            __typename: typename,
                            paginatorInfo
                        }
                    };

                    return newResults.length ? newSearchResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    const resultHolder = getHolderElement(elementClass, scrollElementClass);

    return(
        <ul className="search-result-list">
            {
                data !== undefined && networkStatus !== 2 &&
                <>
                    <RenderItems
                        searchType={ searchVariables.type }
                        resultItems={ searchResult }
                        withAction={ !!actionButton }
                        ActionButton= { actionButton }
                    />
                    {
                        paginatorInfo.hasMorePages && resultHolder.element !== null &&
                            <LoadMoreOnScroll
                                fetchAction={() => fetchMoreAction()}
                                loading={ false }
                                { ...resultHolder }
                            />
                    }
                </>
            }
            {
                data !== undefined && paginatorInfo.count === 0 &&
                <li className="search-result-not-found">
                    <Localize br={ <br /> } wrap="h3">NO_DATA.Text_Search</Localize>
                </li>
            }
            { loading && <li><Loader /></li> }
        </ul>
    );
};

export default SearchResultList;