import React from 'react';
import { Icon } from 'antd';

const HashtagSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M23.1,16.3H19l0.7-4.7h3.4c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-3.2l0.8-5.2c0.1-0.4-0.2-0.8-0.6-0.9
	             c-0.4-0.1-0.8,0.2-0.9,0.6l-0.8,5.5h-7.6l0.8-5.2c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.8,0.2-0.9,0.6l-0.8,5.5H4.9
	             c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8H9l-0.7,4.7H4.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h3.2l-0.8,5.2
	             c-0.1,0.4,0.2,0.8,0.6,0.9c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.7-0.6l0.8-5.5h7.6l-0.8,5.2c-0.1,0.4,0.2,0.8,0.6,0.9c0,0,0.1,0,0.1,0
	             c0.4,0,0.7-0.3,0.7-0.6l0.8-5.5h4.4c0.4,0,0.8-0.3,0.8-0.8S23.5,16.3,23.1,16.3z M9.8,16.3l0.7-4.7h7.6l-0.7,4.7H9.8z"
        />
    </svg>
);

const HashtagSmlSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 10 10">
        <path d="M8.6,4.4C8.8,4.4,9,4.2,9,3.9V3.1c0-0.2-0.2-0.4-0.4-0.4H7.8l0.2-1.6c0-0.1,0-0.3-0.1-0.4
	        C7.9,0.7,7.8,0.6,7.7,0.6c0,0,0,0,0,0l-0.8,0C6.6,0.6,6.5,0.8,6.4,1L6.2,2.7H4.6l0.2-1.6c0-0.1,0-0.3-0.1-0.4
	        C4.6,0.7,4.5,0.6,4.4,0.6c0,0,0,0,0,0l-0.8,0C3.4,0.6,3.2,0.8,3.2,1L2.9,2.7H1.4C1.2,2.7,1,2.9,1,3.1v0.8c0,0.2,0.2,0.4,0.4,0.4h1.2
	        L2.4,6h-1C1.2,6,1,6.2,1,6.4v0.8c0,0.2,0.2,0.4,0.4,0.4h0.7L1.9,8.9c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0l0.8,0
	        c0.2,0,0.4-0.2,0.4-0.4l0.2-1.4h1.6L5.2,8.9c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0l0.8,0c0.2,0,0.4-0.2,0.4-0.4
	        l0.2-1.4h1.5C8.8,7.7,9,7.5,9,7.3V6.4C9,6.2,8.8,6,8.6,6H7.3l0.3-1.6H8.6z M5.9,4.4L5.6,6H4.1l0.3-1.6H5.9z"
        />
    </svg>
);


const HashtagIcon = props => {

    if(props.type === "small"){
        return <Icon component={ HashtagSmlSvg }/>;
    }

    return <Icon component={ HashtagSvg }/>;
};


export default HashtagIcon;