import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Radio } from "antd";

import { SEARCH_POSTS } from 'graphql/query/search-gql';

import { CardList } from "components/card";
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";
import Icons from "components/icons";


const SearchPosts = (props) => {

    const { query, type } = props;

    const [ sortField, setSortField ] = useState( 'CREATED_AT' );
    const [ postsView, setPostsView ] = useState(query === "" ? "grid" : "row");

    const {loading, data, fetchMore} = useQuery(SEARCH_POSTS, {
        variables: {
            top: sortField === "TOP" ? true : undefined,
            text : query,
            type
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        searchPosts : {
            pageInfo,
            edges : posts
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        if( !loading && pageInfo.hasNextPage){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const searchPosts = fetchMoreResult.searchPosts;

                    const newSearchResults = {
                        searchPosts: {
                            edges : [
                                ...posts,
                                ...searchPosts.edges
                            ],
                            __typename: searchPosts.__typename,
                            pageInfo : searchPosts.pageInfo
                        }
                    };

                    return searchPosts.edges.length ? newSearchResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return(
        <>
            { data !== undefined && pageInfo.total > 0 &&
                <>
                    <div className="search-sort-and-view border-color-light">
                        <div className="search-sort">
                            <Radio.Group
                                onChange={
                                    (e) => { setSortField(e.target.value) }
                                }
                                defaultValue={ sortField }
                            >
                                <Radio.Button value="CREATED_AT">
                                    <Localize>SORT.Label_Newest</Localize>
                                </Radio.Button>
                                <Radio.Button value="TOP">
                                    <Localize>SORT.Label_Popular</Localize>
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                        <div className="posts-view-type">
                            <Radio.Group
                                onChange={ (e) => { setPostsView(e.target.value) }}
                                defaultValue={ postsView }
                                className="input-bg-color"
                            >
                                <Radio.Button value="grid"><Icons.View type="grid" /></Radio.Button>
                                <Radio.Button value="row"><Icons.View type="list" /></Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    <CardList
                        className={ postsView === "grid" ? "card-list-grid" : "card-list-row" }
                        cardItems={ posts }
                        view={ postsView }
                    />
                    { pageInfo.hasNextPage &&
                        <LoadMoreOnScroll
                            fetchAction={() => fetchMoreAction()}
                            loading={ false }
                        />
                    }
                </>
            }
            { !loading && data !== undefined && pageInfo.total === 0  &&
            <div className="search-result-not-found">
                <Localize br={ <br /> } wrap="h3">NO_DATA.Text_Search_Posts</Localize>
            </div>
            }
            { loading && <Loader /> }
        </>
    );
};

export default SearchPosts;