import React, {useState} from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, Input, Modal } from "antd";

import { ADD_COLLECTION } from "graphql/mutation/collection-gql";

import Icons from "components/icons";
import {errorNotification} from "components/result";
import { Localize } from "components/service";

import './collection-create-button.css';


const CollectionCreateButton = (props) => {

    const {
        onCreatedCollection = () => {},
        hideOnComplete = false
    } = props;

    const [ onCreateCollection, { loading : createCollectionLoading } ] = useMutation(ADD_COLLECTION);

    const [ showPopup, setShowPopup ] = useState(false);
    const [ collectionTitle, setCollectionTitle ] = useState('');

    const onCreateCollectionClick = () => {
        onCreateCollection( {
            variables: {
                input: {
                    title: collectionTitle,
                    status: 'private'
                }
            },
            update : (cache, { data }) => {
                const { addCollection : { collection } } = data;

                onCreatedCollection(collection);

                if(hideOnComplete){
                    setShowPopup(false);
                }
            }
        }).catch((error) => {
            errorNotification(error);
        });
    };

    return (
        <div className="create-collection-button-holder company-link-hover">
            <button
                title="Create new collection"
                className="create-collection-button button-bg-color site-text-color company-link-hover"
                onClick={ () => setShowPopup(true) }
            >
                <Icons.Plus />
                <span className="label">
                    <Localize>COLLECTION.Button_Create</Localize>
                </span>

            </button>
            <Modal
                title= {<Localize wrap="h3">COLLECTION.Modal_Title_Create</Localize> }
                closeIcon={
                    <Icons.Close />
                }
                destroyOnClose={ true }
                footer={null}
                visible={ showPopup }
                closable={ true }
                onCancel={ () => setShowPopup(false) }
                wrapClassName="modal-actions create-collection-modal"
                centered
            >
                <div className="create-collection-form">
                    <div>
                        <p className="site-label-text-color">
                            <Localize>FORMS.Input_Label_CollectionName</Localize>
                        </p>
                        <Input onChange={ (e ) => setCollectionTitle(e.target.value )  } />
                    </div>
                    <div className="create-collection-form-actions">
                        <Button
                            disabled={ collectionTitle.length < 1 }
                            type="primary"
                            className="rounded-button"
                            loading={ createCollectionLoading }
                            onClick={ onCreateCollectionClick }
                        >
                            <Localize>GLOBAL.Button_Text_Create</Localize>
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CollectionCreateButton;