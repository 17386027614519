import React from 'react';
import { Affix, Col, Row } from "antd";

import { useColumnsChildren } from "../../use-hooks";
import { ElementVisibility } from "../../service";


const TimelineWrap = ({children, className = null}) => {

    const { leftColumn, rightColumn } = useColumnsChildren(children);

    return (
        <Row className={className} type="flex" gutter={ 24 } justify="center">
            <Col xs={24} sm={24} lg={18} xl={16} xxl={14} span={16} className="content">
                { leftColumn }
            </Col>
            <Col xs={0} sm={0} lg={6} xl={6} xxl={6} span={8}>
                <ElementVisibility maxWidth={ 768 } type="hide" comparison="<">
                    <Affix offsetTop={90}>
                        <div>{ rightColumn }</div>
                    </Affix>
                </ElementVisibility>
            </Col>
        </Row>
    );
};

export default TimelineWrap;