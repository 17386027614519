import React from 'react';

import {ContainerWrap} from "../../wraps";
import Hashtag from "../../hashtag";


const HashtagPage = () => {

    return (
        <ContainerWrap className="container-margin">
            <Hashtag />
        </ContainerWrap>
    );
};

export default HashtagPage;