import React from 'react';
import { Icon } from 'antd';

const ScaleSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 20 20">
        <path d="M19.2,0h-5.5C13.4,0,13,0.3,13,0.8s0.3,0.8,0.8,0.8h3.6l-4.3,4.3c-0.3,0.3-0.3,0.8,0,1.1
		c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l4.3-4.3v3.6c0,0.4,0.3,0.8,0.8,0.8C19.7,7,20,6.6,20,6.2V0.8C20,0.3,19.7,0,19.2,0
		z"/>
        <path d="M2.6,1.5h3.6C6.6,1.5,7,1.2,7,0.8S6.6,0,6.2,0H0.8C0.3,0,0,0.3,0,0.8v5.4C0,6.6,0.3,7,0.8,7s0.8-0.3,0.8-0.8
		V2.6l4.3,4.3C6,7.1,6.2,7.1,6.4,7.1s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L2.6,1.5z"/>
        <path d="M19.2,13c-0.4,0-0.8,0.3-0.8,0.8v3.6l-4.3-4.3c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l4.3,4.3h-3.6
		c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h5.5c0.4,0,0.8-0.3,0.8-0.8v-5.4C20,13.4,19.7,13,19.2,13z"/>
        <path d="M6.9,13.1c-0.3-0.3-0.8-0.3-1.1,0l-4.3,4.3v-3.6c0-0.4-0.3-0.8-0.8-0.8S0,13.4,0,13.8v5.4
		C0,19.7,0.3,20,0.8,20h5.5C6.6,20,7,19.7,7,19.2s-0.3-0.8-0.8-0.8H2.6l4.3-4.3C7.2,13.9,7.2,13.4,6.9,13.1z"/>
    </svg>
);

const ScaleIcon = props => {

    return <Icon component={ ScaleSvg } />;

};

export default ScaleIcon;