import { gql } from "apollo-boost";


export const dealerFields = gql`
    fragment dealerFields on Dealer {
        id
        company_name
        dealer_number
        street
        zip_code
        city
        region
        district
    }`;


export const userDealerFragment = gql`
    fragment userDealer on User {
        dealer{
            ...dealerFields
        }
    }
    ${ dealerFields }
`;