import React, { useState } from "react";
import { Modal } from "antd";

import { SearchUsers } from "components/search";
import { PostShareButton } from "components/posts";
import Icons from "components/icons";
import { Localize } from "components/service";

import './card-action-share-post.css';


const CardActionSharePost = (props) => {

    const [ showPopup, setShowPopup ] = useState(false);

    return (
        <div>
            <button
                className="share-but"
                onClick={
                    () => setShowPopup(true)
                }
            >
                <Icons.Share />
            </button>
            <Modal
                title={
                    <Localize wrap="h3">POST.Modal_Title_Share</Localize>
                }
                destroyOnClose={ true }
                closeIcon={
                    <Icons.Close />
                }
                footer={null}
                visible={ showPopup }
                closable={ true }
                onCancel={ () => setShowPopup(false) }
                wrapClassName="modal-actions share-post-modal"
                centered
            >
                <SearchUsers
                    {...props}
                    elementClass={ '.share-post-modal .search-holder' }
                    scrollElementClass={ '.share-post-modal .ant-modal-body' }
                    actionButton={
                        ({ id }) => {
                            return(
                                <PostShareButton
                                    userId={ id }
                                    postId={ props.postId }
                                />
                            )
                        }
                    }
                />
            </Modal>
        </div>
    )

};

export default CardActionSharePost;