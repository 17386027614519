import AvatarBlock from "./avatar-block";
import AvatarCustom from "./avatar-custom";
import AvatarThreeRow from "./avatar-three-row";

import './avatar.css';

export default AvatarBlock;

export {
    AvatarThreeRow,
    AvatarCustom
}

