import React from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Button } from "antd";

import { commentsFields } from "graphql/fragment/comment-frgm";
import { LIKE_COMMENT } from "graphql/mutation/follow-gql";

import { errorNotification } from "components/result";
import Icons from "components/icons";

import "./comment-like-button.css";



const CommentLikeButton = (props) => {

    const client = useApolloClient();

    const {
        commentId,
        liked,
    } = props;

    const [ onLikeClick, { loading : loadingLike } ] = useMutation(LIKE_COMMENT, {
        update : (cache, { data : { commentLikeDislike } }) => {

            const { liked, like_count} = commentLikeDislike;

            const comment = client.readFragment({
                id : `Comment:${commentId}`,
                fragment : commentsFields,
                fragmentName : "commentsFields"
            });

            cache.writeFragment({
                id : `Comment:${commentId}`,
                fragment : commentsFields,
                fragmentName : "commentsFields",
                data : {
                    ...comment,
                    liked,
                    like_count
                }
            });

        }
    });

    const onClickLike = () => {
        if(!loadingLike) {

            onLikeClick({
                variables: {
                    comment_id: commentId,
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <Button
            className={ `comment-like-but ${liked ? 'active' : ''}` }
            onClick={ onClickLike }
        >
            <Icons.Like filled={ liked } loading={ loadingLike } />
        </Button>
    );
};

export default CommentLikeButton;