import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "antd";

import { SHARE_POST  } from "graphql/mutation/post-gql";

import { errorNotification, successNotification } from "../../result";
import { Localize } from "components/service";


const PostShareButton = (props) =>  {

    const { userId, postId } = props;

    const [postShared, setPostShared ] = useState(false);


    const [ clickShare, { loading : loadingShare } ] = useMutation(SHARE_POST, {
        update : (cache, { data : { sharePost } }) => {

            const { label, message } = sharePost;

            setPostShared(true);

            successNotification({
                title: label,
                description: message,
            });
        }
    });

    const onClickShare = () => {

        if(!loadingShare) {
            clickShare({
                variables: {
                    post_id: postId,
                    user_id: userId
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }

    };

    return(
        <Button
            disabled={ postShared }
            className="rounded-button"
            type="primary"
            loading={ loadingShare }
            onClick={ onClickShare }
        >
            <Localize wrap>
                { postShared ?
                    "POST.Button_Text_Shared":
                    "POST.Button_Text_Share"
                }
            </Localize>
        </Button>
    );
};

export default PostShareButton;