import React from 'react';
import { Icon } from 'antd';


const UserSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M15.4,14.1c2.3-0.6,4-2.7,4-5.1c0-2.9-2.4-5.3-5.3-5.3c-3,0-5.3,2.4-5.3,5.3c0,2.5,1.7,4.5,4,5.1
	             c-4.7,0.7-8.3,4.7-8.4,9.6c0,0.4,0.3,0.8,0.7,0.8c0,0,0,0,0,0c0.4,0,0.7-0.3,0.8-0.7c0-4.5,3.8-8.2,8.3-8.2s8.2,3.7,8.3,8.2
	             c0,0.4,0.3,0.7,0.8,0.7c0,0,0,0,0,0c0.4,0,0.7-0.3,0.7-0.8C23.7,18.8,20.1,14.8,15.4,14.1z M10.2,8.9c0-2.1,1.7-3.8,3.8-3.8
	             c2.1,0,3.8,1.7,3.8,3.8c0,2.1-1.7,3.8-3.8,3.8C11.9,12.8,10.2,11.1,10.2,8.9z"
        />
    </svg>
);

const UserFillSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 18 18">
        <path d="M15.2,15.6c0-3.4-2.8-6.2-6.2-6.2s-6.2,2.8-6.2,6.2C2.8,15.6,15.2,15.9,15.2,15.6z"/>
        <circle cx="9" cy="5.4" r="3.2"/>
    </svg>
);

const UserIcon = props => {

    return <Icon component={ props.filled ? UserFillSvg : UserSvg} />;

};



export default UserIcon;