import React from "react";
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/react-hooks";

import { GET_COLLECTION } from "graphql/query/collection-gql";

import CollectionHeader from "./collection-header";
import CollectionPosts from "./collection-posts";
import { TopAppBar } from "../menu-bar";
import CollectionHeaderPopup from "./collection-header-popup";
import { ElementVisibility } from "../service";
import { ErrorResult, Loader } from "../result";

import './collection.css';


const Collection = () => {

    const { collectionSlug } = useParams();

    const { loading, error, data } = useQuery(GET_COLLECTION, {
        variables: {
            slug : collectionSlug
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        collection: {
            author,
        } = {}
    } = data !== undefined ? data : {};

    if(error || (!loading && data.collection === null)) {
        return (
            <ErrorResult
                error={error}
                goToLink="/"
                goToText="Go to timeline"
            />
        );
    }

    return (
        <div className="collection">
            { data !== undefined &&
                <>
                    <ElementVisibility maxWidth={ 768 } type="show" comparison="<">
                        <TopAppBar
                            title={ <h3>{ `^${collectionSlug}` }</h3> }
                            rightSide={
                                <>
                                    { author.itsMe &&
                                        <CollectionHeaderPopup
                                            id={data.collection.id}
                                            slug={data.collection.slug}
                                        />
                                    }
                                </>
                            }
                        />
                    </ElementVisibility>
                    <CollectionHeader
                        collection={ data.collection }
                        itsMe={ author.itsMe}
                    />
                    <CollectionPosts
                        collectionId={ data.collection.id }
                        collectionSlug={ data.collection.slug }
                    />
                </>
            }
            { loading && <Loader /> }
        </div>
    );

};

export default Collection;