import React from "react";
import {Link} from "react-router-dom";
import {Result} from "antd";

import './error-result.css';

const ErrorResult = (props) => {

    const { error, goToLink, goToText} = props;

    const ErrorMessage = error.graphQLErrors.map(({ message: label, extensions : {reason, action} }) => {
        return (
            <div
                className="error-result"
                key={action}
            >
                <h3>{label}</h3>
                <p>{reason}</p>
            </div>
        );
    });

    return(
        <Result
            status="warning"
            title={ErrorMessage}
            extra={
                <Link to={goToLink}>{ goToText }</Link>
            }
        />
    );
};

export default ErrorResult;