import React from 'react';
import { Icon } from 'antd';

const PlusSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 12 12">
        <path d="M11,5H7V1c0-0.6-0.5-1-1-1S5,0.5,5,1v4H1C0.5,5,0,5.4,0,6s0.5,1,1,1h4v4c0,0.6,0.5,1,1,1s1-0.5,1-1V7h4
	            c0.6,0,1-0.5,1-1S11.5,5,11,5z"/>
    </svg>
);

const PlusIcon = props => <Icon component={PlusSvg} {...props} />;

export default PlusIcon;