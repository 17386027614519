import React from 'react';
import { Icon } from 'antd';

const LikeSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 26 26">
        <path d="M13,23.3c-0.2,0-0.3-0.1-0.5-0.2c-1-0.8-9.8-7.9-10.9-10.3C0.2,9.9,0.9,6.4,3.4,4.3c3.2-2.7,6.5-1.5,7.4-1.1
                 c1,0.5,1.7,1.1,2.2,1.7c0.5-0.5,1.2-1.2,2.2-1.7c0.9-0.4,4.2-1.6,7.4,1.1c2.5,2.1,3.2,5.6,1.8,8.5c-1.2,2.5-9.9,9.5-10.9,10.3
                 C13.3,23.3,13.2,23.3,13,23.3z M7.9,4.1c-1,0-2.3,0.3-3.5,1.4c-2,1.7-2.5,4.4-1.4,6.7c0.7,1.5,6.2,6.3,10,9.4
                 c3.8-3.1,9.2-7.8,10-9.4c1.1-2.3,0.5-5.1-1.4-6.7l0,0c-2.5-2.1-5.1-1.2-5.8-0.9c-1.3,0.6-2.1,1.7-2.2,1.9c-0.3,0.4-1,0.4-1.2,0
                 c-0.1-0.2-0.9-1.3-2.2-1.9C9.8,4.4,9,4.1,7.9,4.1z"
        />
    </svg>
);

const LikeFillSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 26 26">
        <path d="M13,23.3c-0.2,0-0.3-0.1-0.5-0.2c-1-0.8-9.8-7.9-10.9-10.3C0.2,9.9,0.9,6.4,3.4,4.3c3.2-2.7,6.5-1.5,7.4-1.1
	             c1,0.5,1.7,1.1,2.2,1.7c0.5-0.5,1.2-1.2,2.2-1.7c0.9-0.4,4.2-1.6,7.4,1.1c2.5,2.1,3.2,5.6,1.8,8.5c-1.2,2.5-9.9,9.5-10.9,10.3
	             C13.3,23.3,13.2,23.3,13,23.3z"
        />
    </svg>
);


const LikeIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ props.filled ? LikeFillSvg : LikeSvg}  />;
};

export default LikeIcon;