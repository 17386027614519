import React from 'react';
import { Icon } from 'antd';

const MultiImageSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 22 22">
        <path d="M19,2.5H6.5C6.2,2.5,6,2.7,6,3v12.5C6,15.8,6.2,16,6.5,16H19c0.3,0,0.5-0.2,0.5-0.5V3
		         C19.5,2.7,19.3,2.5,19,2.5z"/>
        <path d="M4.5,16.9V5.8H3.2c-0.4,0-0.7,0.3-0.7,0.7v12.3c0,0.3,0.2,0.5,0.5,0.5h12.5c0.4,0,0.7-0.3,0.7-0.7v-1.2H5
			     C4.7,17.4,4.5,17.2,4.5,16.9z"/>
    </svg>
);


const MultiImageIcon = props => {


    return <Icon component={ MultiImageSvg }/>;
};


export default MultiImageIcon;