import React, { useState } from "react";
import { Modal } from "antd";

import Icons from "components/icons";
import { Localize } from "components/service";


const AdditionalMenuModal = (props) => {

    const [ showModal, setShowModal ] = useState(false);

    return (
        <>
            <button
                className="additional-menu-button"
                onClick={ () => setShowModal(true) }
            >
                <span>
                    <span />
                    <span />
                    <span />
                </span>
            </button>
            <Modal
                title={
                    <Localize wrap="h3">GLOBAL.Modal_Title_Menu</Localize>
                }
                closeIcon={
                    <Icons.Close />
                }
                footer={ null }
                visible={ showModal }
                closable={true}
                onCancel={ () => setShowModal(false) }
                wrapClassName="modal-actions additional-menu-modal"
                centered
            >
                { props.children }
            </Modal>
        </>
    );
};

export default AdditionalMenuModal;