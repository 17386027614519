import { GET_CHAT_MESSAGES } from "graphql/query/chat-gql";


const chatMessagesUpdateHelper = ({ chatId, chatMessage, client, callBack = () => {} }) => {

    let updateMessage = false;

    const { chatMessages } = client.readQuery({
        query: GET_CHAT_MESSAGES,
        variables: {
            chat_id: chatId,
            defaultCount: 20
        }
    });

    const updatedMessages = chatMessages.map((message) => {

        if (message.id === chatMessage.id) {
            updateMessage = true;

            return chatMessage;
        }

        return message;
    });

    client.writeQuery({
        query: GET_CHAT_MESSAGES,
        variables: {
            chat_id: chatId,
            defaultCount: 20
        },
        data: {
            chatMessages: updateMessage ? updatedMessages : [chatMessage, ...chatMessages]
        }
    });

    callBack();

}

export default chatMessagesUpdateHelper;