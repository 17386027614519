import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input } from "antd";

import { USER_UPDATE_META } from "graphql/mutation/user-gql";

import { errorNotification, successNotification } from "components/result";
import { userNormalMeta } from "utils"
import { Localize } from "components/service";


const formSubmit = ({ event, form, updateUserMeta }) => {
    event.preventDefault();

    let inputValues = [];

    for (let [key, meta_value] of Object.entries(form.getFieldsValue())) {

        let [meta_key, meta_type, id] = key.split('-');

        inputValues.push({
            id,
            meta_key,
            meta_type,
            meta_value
        });

    }

    form.validateFields((err) => {
        if (!err) {

            updateUserMeta({
                variables : {
                    input: [...inputValues]
                }
            }).catch((error) => {
                errorNotification(error);
            });

        }
    });
};

const NormalAdditionalInformationForm = (props) => {

    const {
        metaFields,
        form,
        history,
        requestLoading,
        updateUserMeta,
        nickname
    } = props;

    const {getFieldDecorator} = form;

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    return (
        <Form
            className="profile-form"
            layout="horizontal"
            onSubmit={(event) => {
                formSubmit({event, form, updateUserMeta});
            }}
        >
            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_JobDescription</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                { getFieldDecorator(
                    `jobDescription-string${ metaFields.jobDescription !== undefined ? `-${metaFields.jobDescription.id}` : "" }`,
                    {
                        initialValue: metaFields.jobDescription !== undefined ? metaFields.jobDescription.meta_value : ""
                    }
                )(
                    <Input placeholder={ Localize({children: "FORMS.Input_Placeholder_JobDescription" }).props.children } />
                )
                }
            </Form.Item>
            <Form.Item
                {...formItemLayout}
            >
                { getFieldDecorator(
                    `contactPhone-string${ metaFields.contactPhone !== undefined ? `-${metaFields.contactPhone.id}` : "" }`,
                        {
                            rules: [
                                {
                                    type: "string",
                                    pattern: /^[\d\-+ ]+$/,
                                    message: <Localize>FORM_RULES.ContactPhone</Localize>
                                },
                            ],
                            initialValue: metaFields.contactPhone !== undefined ? metaFields.contactPhone.meta_value : ""
                        }
                    )(
                        <Input placeholder={ Localize({children: "FORMS.Input_Placeholder_ContactPhone" }).props.children } />
                    )
                }
            </Form.Item>
            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_ContactEmail</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                {getFieldDecorator(
                    `contactEmail-string${ metaFields.contactEmail !== undefined ? `-${metaFields.contactEmail.id}` : "" }`, {
                    rules: [
                        {
                            type: 'email',
                            message: <Localize>FORM_RULES.Email</Localize>,
                        }
                    ],
                    initialValue: metaFields.contactEmail !== undefined ? metaFields.contactEmail.meta_value : ""
                })(
                    <Input placeholder={ Localize({children: "FORMS.Input_Placeholder_ContactEmail" }).props.children } />
                )}
            </Form.Item>
            <Form.Item wrapperCol={{span: 24}} >
                <div className="form-actions">
                    <Button
                        className="rounded-button"
                        onClick={
                            () => history.push(`/${nickname}`)
                        }
                    >
                        <Localize>GLOBAL.Button_Text_Cancel</Localize>
                    </Button>
                    <Button
                        loading={requestLoading}
                        className="rounded-button"
                        type="primary"
                        htmlType="submit"
                    >
                        <Localize wrap>GLOBAL.Button_Text_Save</Localize>
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

const AdditionalInformationContainer = (props) => {

    const { me } = props;
    const history = useHistory();

    let metaFields = userNormalMeta(me.meta_fields);

    const [ updateUserMeta, { loading }] = useMutation(USER_UPDATE_META,
        {
            update(cache, { data }) {

                const {
                    userMetaSet:{
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            }
        });

    return (
        <NormalAdditionalInformationForm
            {...props}
            updateUserMeta={updateUserMeta}
            requestLoading={loading}
            metaFields={metaFields}
            nickname={ me.nickname }
            history={history}
        />
    );
};

const AdditionalInformationForm = Form.create({ name: 'additional_info' })(AdditionalInformationContainer);

export default AdditionalInformationForm;