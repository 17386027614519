import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Col, Row, Upload } from "antd";

import { UPLOAD_USER_AVATAR } from "graphql/mutation/user-gql";
import { GET_ME } from "graphql/query/user-gql";

import { SettingsForm } from '../../forms';
import AvatarBlock from "components/avatar";
import { UserTopBar } from "components/user";
import {ElementVisibility, Localize} from "components/service";
import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";


const ProfileTabSettings = ({ me }) => {

    const [userUploadAvatar, { loading }] = useMutation(UPLOAD_USER_AVATAR,
        {
            update(cache, { data }) {

                const { me } = cache.readQuery({ query: GET_ME });

                const {
                    uploadAvatar : {
                        label,
                        message,
                        avatar
                    }
                } = data;

                cache.writeQuery({
                    query: GET_ME,
                    data: {
                        me: {...me, avatar}
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });
            }
        });

    const uploadProps = {
        accept: 'image/*',
        beforeUpload: (file) => {
            userUploadAvatar({
                variables: {
                    user_id: me.id,
                    file: file
                }
            }).catch((error) => {
                errorNotification(error);
            });

            return false;
        },
        showUploadList: false
    };

    return (
        <div>
            <ElementVisibility
                maxWidth={ 768 }
                type="show"
                comparison="<"
            >
                <UserTopBar
                    itsMe
                    title={ <Localize wrap>GLOBAL.Title_EditProfile</Localize> }
                    backLink={ `/${me.nickname}` }
                />
            </ElementVisibility>
            <Row type="flex" className="profile-row">
                <Col span={24}>
                    <div className="profile-avatar">
                        <Upload {...uploadProps}>
                            <AvatarBlock
                                photoUrl={ me.avatar }
                                initials={ <div className="site-badge"><Icons.Plus /></div> }
                                avatarClass={ me.avatar.length === 0 ? 'empty' : '' }
                                dataClass="profile"
                                size={150}
                                loading={loading}
                                avatarAction={
                                    <button className="upload-avatar-but company-color">
                                        <Localize wrap>GLOBAL.Button_Text_ChangePhoto</Localize>
                                    </button>
                                }
                            />
                        </Upload>
                    </div>
                    <SettingsForm myData={ me } />
                </Col>
            </Row>
        </div>
    );
};

export default ProfileTabSettings;