import {gql} from "apollo-boost";


export const hashtagPostsFields = gql`
    fragment hashtagPostsFields on HashtagPost {
        id
        post_id
        hashtag_id
        created_at
        updated_at      
    }
`;

export const hashtagFields = gql`
    fragment hashtagFields on Hashtag {
        id
        slug
        me_following
        cover_url
        count_posts
        count_followers
        created_at
        updated_at
    }
`;