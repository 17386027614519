import { useEffect } from "react";


const RealWindowHeight = () => {

    useEffect( () => {

        function setVH(){
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setVH();

        window.addEventListener('resize', setVH);
        window.addEventListener('orientationchange', setVH);

    },[]);

    return null;
};

export default RealWindowHeight;