import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_MY_COLLECTIONS } from "graphql/query/collection-gql";

import CollectionList from '../collection-list';
import { errorNotification, Loader } from "../../result";

import "./collection-to-add-post.css";


const CollectionToAddPost = ( props ) => {

    const {
        postId,
        refetchCollections,
        setRefetchCollections
    } = props;

    const { loading, data, refetch } = useQuery(GET_MY_COLLECTIONS, {
        variables: {
            post_id : postId
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        me: {
            collections : {
                pageInfo,
                edges : collections
            } = {}
        } = {}
    } = data !== undefined ? data : {};

    if(refetchCollections){
        refetch().catch((error) => {
            errorNotification(error);
        });
        setRefetchCollections(false);
    }

    const postCollectionsCount = collections === undefined ? 0 :
                                    collections.filter(
                                        ({ node }) => node.postInCollection
                                    ).length;

    return (
        <div className="collection-to-add-post">
            {data !== undefined && pageInfo.total > 0  &&
                <CollectionList
                    collections={ collections }
                    post_id={ postId }
                    postCollectionsCount={ postCollectionsCount }
                    view="row"
                    withActions
                    showStatus
                />
            }
            { loading && <Loader /> }
        </div>
    );
};


export default CollectionToAddPost;