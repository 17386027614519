import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { GET_COLLECTION } from "graphql/query/collection-gql";

import AvatarBlock from "../../avatar";
import { Loader } from "../../result";
import Icons from "../../icons";


const CollectionAvatar = ({ collectionSlug }) => {

    const { loading, data } = useQuery(GET_COLLECTION, {
        variables: {
            slug : collectionSlug
        }
    });

    const { collection } = data || {};

    return (
        <>
            { !loading ?
                <AvatarBlock
                    photoUrl={collection.cover_url}
                    initials={ <Icons.NoImage /> }
                    link={`/collection/${collection.slug}`}
                    label={`^${collection.slug}`}
                    badgeIcon={ <Icons.Collection type="small" /> }
                    size={ 50 }
                    shape="square"
                    avatarAction={
                        <div className="author">
                            <Link to={`/${collection.author.nickname}`} className="site-text-color">
                                <Icons.User filled/>
                                <span>{collection.author.nickname}</span>
                            </Link>
                        </div>
                    }
                /> :
                <Loader />
            }
        </>
    );
};

export default CollectionAvatar;