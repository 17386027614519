import React from 'react';
import { Icon } from 'antd';

const AddedFilesSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 9 11">
        <path d="M5.7,0H0v11h9V3.6L5.7,0z M4.9,4.3V0.8l3.3,3.5H4.9z"/>
    </svg>
);

const ImageFileSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 13 16">
        <path d="M8.3,0H0v16h13V5.2L8.3,0z M8.1,14.5H6.8l0,0H4H1.3l1.4-1.6L4,11.4l1.4,1.6l0.1,0.1l0.8-1.2l1.8-2.7l1.8,2.7
	             l1.8,2.7H8.1z M7.1,6.3V1.1l4.7,5.2H7.1z"
        />
    </svg>
);

const WordFileSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 13 16">
        <path d="M8.3,0H0v16h13V5.2L8.3,0z M7.1,6.3V1.1l4.7,5.2H7.1z"/>
        <path fill="#FFFFFF" d="M4.5,12.8l-1-4.3h0.9L5,11.4l0.8-2.9h1l0.8,3l0.7-3H9l-1,4.3H7.1L6.2,9.6l-0.8,3.2H4.5z"/>
    </svg>
);

const ExcelFileSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 13 16">
        <path d="M8.3,0H0v16h13V5.2L8.3,0z M7.1,6.3V1.1l4.7,5.2H7.1z"/>
        <path fill="#FFFFFF" d="M4.4,12.8l1.5-2.2l-1.3-2h1l0.9,1.4l0.8-1.4h1l-1.3,2.1l1.5,2.2h-1l-0.9-1.5l-1,1.5H4.4z"/>
    </svg>
);

const PdfFileSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 13 16">
        <g>
            <path d="M8.3,0H0v16h13V5.2L8.3,0z M7.1,6.3V1.1l4.7,5.2H7.1z"/>
            <g>
                <path fill="#FFFFFF" d="M2.1,12.4V9.1h1.1c0.4,0,0.7,0,0.8,0c0.2,0,0.4,0.2,0.5,0.3c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2,0,0.4-0.1,0.5
			c-0.1,0.1-0.2,0.2-0.3,0.3C4.1,11,4,11.1,3.9,11.1c-0.2,0-0.4,0-0.7,0H2.7v1.3H2.1z M2.7,9.6v0.9h0.4c0.3,0,0.4,0,0.5-0.1
			c0.1,0,0.2-0.1,0.2-0.2s0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3C3.8,9.7,3.7,9.7,3.6,9.6c-0.1,0-0.2,0-0.5,0H2.7z"/>
                <path fill="#FFFFFF" d="M5.2,9.1h1.2c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.3C7.7,9.6,7.8,9.8,7.9,10C7.9,10.2,8,10.4,8,10.8
			c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1,0-0.3,0.1-0.6,0.1H5.2V9.1z M5.9,9.6v2.2h0.5
			c0.2,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.1s0.1-0.2,0.2-0.3c0-0.1,0.1-0.4,0.1-0.6c0-0.3,0-0.5-0.1-0.6C7.2,10,7.1,9.9,7,9.8
			S6.8,9.7,6.7,9.7c-0.1,0-0.3,0-0.6,0H5.9z"/>
                <path fill="#FFFFFF" d="M8.6,12.4V9.1h2.3v0.6H9.2v0.8h1.4V11H9.2v1.4H8.6z"/>
            </g>
        </g>
    </svg>
);



const FileIcon = props => {

    if(props.type ===  "image"){
        return <Icon component={ ImageFileSvg }/>;
    }

    if(props.type ===  "word"){
        return <Icon component={ WordFileSvg }/>;
    }

    if(props.type ===  "excel"){
        return <Icon component={ ExcelFileSvg }/>;
    }

    if(props.type ===  "pdf"){
        return <Icon component={ PdfFileSvg }/>;
    }

    return <Icon component={ AddedFilesSvg }/>;
};


export default FileIcon;