import { useMutation } from "@apollo/react-hooks";

import { CREATE_OR_UPDATE_MESSAGE } from "graphql/mutation/chat-gql";

import { chatMessagesUpdateHelper } from "../helpers";


const useChatMessageCreateMutation = (props) => {

    const { chatId } = props;

    const [onCreateOrUpdateMessage, { error, loading, client }] = useMutation(CREATE_OR_UPDATE_MESSAGE, {
        update : (cache, { data }) => {

            const {
                chatMessageCreateOrUpdate : chatMessage
            } = data;

            chatMessagesUpdateHelper({
                chatId,
                chatMessage,
                client
            });

        }
    });

    return { onCreateOrUpdateMessage, loading, error };
};

export default useChatMessageCreateMutation;