import React from 'react';
import {Col} from "antd";

import {CardPreview, CardPreviewRow } from "../../../card";


const CardListPreview  = (  { post, view, showAuthor } ) => {

    if( view === 'row'){
        return (
            <Col span={ 24 } className="card-preview-row-holder">
                <CardPreviewRow
                    post={ post }
                    showAuthor={ showAuthor }
                />
            </Col>
        );
    }

    return (
        <Col span={8} className="card-preview-holder">
            <CardPreview
                post={ post }
                counters
            />
        </Col>
    );

};


export default CardListPreview;