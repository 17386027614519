import React from 'react';
import { Icon } from 'antd';


const CheckSvg = () => (
    <svg fill="currentColor"  width="1em" height="0.5em" viewBox="0 0 16 8">
        <path d="M6,7.2L6,7.2C5.8,7.2,5.6,7.1,5.5,7L2.3,3.8C2,3.5,2,3.1,2.3,2.8s0.8-0.3,1.1,0L6,5.4l5.2-5.2c0.3-0.3,0.8-0.3,1.1,0
	            s0.3,0.8,0,1.1L6.5,7C6.4,7.1,6.2,7.2,6,7.2z"
        />
    </svg>
);

const CheckDoubleSvg = () => (
    <svg fill="currentColor"  width="1em" height="0.5em" viewBox="0 0 16 8">
        <path d="M3.9,7.2L3.9,7.2C3.7,7.2,3.5,7.1,3.4,7L0.2,3.8c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l2.6,2.6l5.2-5.2
	            c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L4.5,7C4.3,7.1,4.1,7.2,3.9,7.2z"
        />
        <path d="M8,7.2C7.8,7.2,7.6,7.2,7.4,7C7.1,6.7,7.1,6.3,7.4,6l5.7-5.7c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L8.5,7
	            C8.3,7.2,8.1,7.2,8,7.2z"
        />
    </svg>
);

const CheckIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    if(props.type === "double"){
        return <Icon component={ CheckDoubleSvg }/>;
    }

    return <Icon component={ CheckSvg }/>;
};


export default CheckIcon;