import React from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Checkbox } from "antd";

import { COLLECTION_ADD_POST, COLLECTION_REMOVE_POST } from "graphql/mutation/collection-gql";
import { GET_POST_SHORT } from "graphql/query/post-gql";

import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";


const CollectionCardAction = (props) => {

    const {
        postCollectionsCount,
        collection_id,
        post_id,
        postInCollection
    } = props;

    const client = useApolloClient();

    const [ addPostClick, { loading : loadingAdd } ] = useMutation(COLLECTION_ADD_POST);
    const [ removePostClick, {loading: loadingRemove} ] = useMutation(COLLECTION_REMOVE_POST);

    const clickToggle = (checked) => {
        if(!checked){
            removePostClick({
                variables: {
                    collection_id: collection_id,
                    post_id: post_id
                },
                update: (cache, {data}) => {

                    const {
                        removePostInCollection : {
                            label,
                            message
                        }
                    } = data;

                    if(postCollectionsCount === 1){

                        const {post} = client.readQuery({
                            query: GET_POST_SHORT,
                            variables: {
                                post_id: post_id,
                            }
                        });

                        client.writeQuery({
                            query: GET_POST_SHORT,
                            data: {
                                post: {...post, inCollection : false}
                            },
                        });
                    }

                    successNotification({
                        title: label,
                        description: message,
                    });

                }
            }).catch((error) => {
                errorNotification(error);
            });

        } else {
            addPostClick({
                variables: {
                    collection_id: collection_id,
                    post_id: post_id
                },
                update: (cache, {data}) => {
                    const {
                        addPostToCollection : {
                            label,
                            message
                        }
                    } = data;

                    successNotification({
                        title: label,
                        description: message,
                    });
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <div className="card-action">
            { loadingAdd || loadingRemove ?
                <Icons.Lock loading /> :
                <Checkbox
                    checked={ postInCollection }
                    onChange={ (e) => clickToggle(e.target.checked) }
                />
            }
        </div>
    );

};

export default CollectionCardAction;