import { gql } from "apollo-boost";

import { chatMessageFieldsFull, chatFieldsFull } from "../fragment/chat-frgm";


export const CREATE_OR_UPDATE_MESSAGE = gql`
    mutation CreateOrUpdateMessage(
        $id: ID
        $chat_id: ID!
        $content: String
        $response_id: ID
    ) {
        chatMessageCreateOrUpdate(
            id : $id
            chat_id : $chat_id
            content : $content
            response_id : $response_id
        ){
            ...chatMessageFieldsFull 
        }
    }
    ${ chatMessageFieldsFull }
`;

export const UPDATE_CHAT_MESSAGE = gql`
    mutation ChatMessageUpdate(
        $id: ID!
        $content: String
    ) {
        chatMessageUpdate(
            id : $id
            content : $content
        ){
            ...chatMessageFieldsFull
        }
    }
    ${ chatMessageFieldsFull }
`;

export const CREATE_CHAT = gql`
    mutation Create_Chat(
        $users: [ID!]!
    ) {
        chatCreate(
            users : $users
        ){
            label
            message
            chat{
                ...chatFieldsFull
            } 
        }
    }
    ${ chatFieldsFull }
`;



export const DELETE_CHAT_MESSAGE = gql`
    mutation Delete_Chat_Message(
        $id: ID!
    ) {
        chatMessageDelete(
            id : $id
        ){
            id
            status
        }
    }
`;
