import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import { Popover } from "antd";


const AdditionalMenuPopover = ( props ) => {

    // const location = useLocation();
    const [popoverVisible, setPopoverVisible] = useState(false);

    // useEffect(() => {
    //     setTimeout( () => setPopoverVisible(false), 10000);
    // }, [location]);


    return (
        <Popover
            content={ props.children }
            overlayClassName={"additional-menu-popover"}
            title={ null }
            trigger={ ["click"] }
            placement="bottomRight"
            visible={ popoverVisible }
            onVisibleChange={ () => setPopoverVisible(!popoverVisible) }
        >
            <button
                className={`additional-menu-button ${popoverVisible ? "open" : ""}`}
                onClick={ () => setPopoverVisible(true) }
            >
                <span>
                    <span />
                    <span />
                    <span />
                </span>
            </button>
        </Popover>
    );
};

export default AdditionalMenuPopover;