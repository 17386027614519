import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Radio } from "antd";

import { GET_HASHTAG_POSTS } from "graphql/query/hashtag-gql";

import { CardList } from "../../card";
import {LoadMoreOnScroll, Localize} from "../../service";
import { errorNotification, Loader } from "../../result";
import Icons from "../../icons";


const normalizedArr = (postsArr) => {

    if(postsArr.length === 0){
        return {
            posts : [],
            lastPostId : false
        };
    }

    const posts = postsArr.map(item => {
        return {node: {...item}}
    });

    const lastPostId = posts[posts.length - 1].node.id;

    return {
        posts,
        lastPostId
    };

};

const HashtagPosts = (props) => {

    const {
        hashtagId,
        hashtagSlug,
        postsPerPage = 12
    } = props;

    const [ hasMorePosts, setHasMorePosts ] = useState( true );
    const [ postsView, setPostsView ] = useState("grid");

    const { loading, data, fetchMore } = useQuery(GET_HASHTAG_POSTS, {
        variables: {
            hashtag_id : hashtagId,
            defaultCount: postsPerPage
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const { posts, lastPostId } = normalizedArr(
        data !== undefined ?
            data.hashtagPosts : []
    );

    const fetchMoreAction = (direction) => {
        if(!loading && hasMorePosts && posts.length >= postsPerPage){

            fetchMore({
                variables: {
                    id : lastPostId,
                    paginate:  direction
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newPostList = fetchMoreResult.hashtagPosts;

                    const newPosts = [
                            ...data.hashtagPosts,
                            ...newPostList,
                        ];

                    if(newPostList.length < postsPerPage){
                        setHasMorePosts(false);
                    }

                    return newPosts.length ? { hashtagPosts : [...newPosts]} : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <div className="hashtag-posts">
            { data !== undefined &&
                <>
                    { posts.length > 0 ?
                        <>
                            <div className="hashtag-posts-actions">
                                <div className="posts-view-type">
                                    <Radio.Group
                                        onChange={ (e) => { setPostsView(e.target.value) }}
                                        defaultValue={ postsView }
                                        className="input-bg-color"
                                    >
                                        <Radio.Button value="grid"><Icons.View type="grid" /></Radio.Button>
                                        <Radio.Button value="row"><Icons.View type="list" /></Radio.Button>
                                    </Radio.Group>
                                </div>
                            </div>
                            <CardList
                                type="hashtagPreview"
                                cardItems={ posts }
                                path={ `/tag/${hashtagSlug}` }
                                view={postsView}
                            />
                            {posts.length >= postsPerPage &&
                            <LoadMoreOnScroll
                                fetchAction={() => fetchMoreAction('next')}
                                loading={loading}
                            />
                            }
                        </>:
                        <div className="empty-hashtag">
                            <div className="no-content-icon site-icon-color-second">
                                <Icons.NoContent type="posts" />
                            </div>
                            <Localize wrap="p">NO_DATA.Text_HashtagPosts</Localize>
                        </div>
                    }
                </>
            }
            { loading && data === undefined && <Loader /> }
        </div>
    );
};

export default HashtagPosts;