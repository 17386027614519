import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input } from "antd";

import { CHANGE_PASSWORD } from "graphql/mutation/user-gql";
import { GET_ME } from "graphql/query/user-gql";

import { errorNotification, successNotification } from "components/result";
import { Localize } from "components/service";

import './change-password-form.css';


const formSubmit = ({ event, form, changePasswordRequest }) => {
    event.preventDefault();

    form.validateFields((err, values) => {
        if (!err) {

            changePasswordRequest({
                variables : {
                    input: {
                        password: values.current,
                        new_password: values.password,
                        new_password_confirmation: values.confirm,
                    }
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    });
};



const NormalChangePasswordForm = (props) => {

    const {
        form,
        id,
        nickname,
        history,
        requestLoading,
        changePasswordRequest
    } = props;
    const {getFieldDecorator} = form;

    const confirmDirty = false;

    let compareErrorText = Localize({children: "FORM_RULES.Required_PasswordInconsistent" }).props.children;

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback(compareErrorText);
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {

        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: false });
        }
        callback();
    };


    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    return (
        <Form
            className="profile-form"
            layout="horizontal"
            onSubmit={(event) => {
                formSubmit({event, form, id, changePasswordRequest});
            }}
        >
            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_CurrentPassword</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                {getFieldDecorator('current', {
                    rules: [
                        {
                            required: true,
                            message: <Localize>FORM_RULES.Required_CurrentPassword</Localize>
                        }
                    ]
                })(
                    <Input.Password
                        placeholder={ Localize({children: "FORMS.Input_Placeholder_CurrentPassword" }).props.children }
                    />
                )}
            </Form.Item>
            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_NewPassword</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                {getFieldDecorator('password', {
                    rules: [
                        {min: 8, message: <Localize minLength={ 8 }>FORM_RULES.Min_Password</Localize> },
                        {required: true, message: <Localize>FORM_RULES.Required_NewPassword</Localize> },
                        {
                            validator: validateToNextPassword,
                        }
                    ]
                })(
                    <Input.Password
                        placeholder={ Localize({children: "FORMS.Input_Placeholder_NewPassword" }).props.children }
                    />
                )}
            </Form.Item>
            <Form.Item
                label={
                    <span className="site-label-text-color">
                        <Localize>FORMS.Input_Label_ConfirmPassword</Localize>
                    </span>
                }
                {...formItemLayout}
            >
                {getFieldDecorator('confirm', {
                    rules: [
                        {required: true, message: <Localize>FORM_RULES.Required_ConfirmPassword</Localize>},
                        {
                            validator: compareToFirstPassword,
                        }
                    ]
                })(
                    <Input.Password
                        placeholder={ Localize({children: "FORMS.Input_Placeholder_ConfirmPassword" }).props.children }
                    />
                )}
            </Form.Item>
            <Form.Item wrapperCol={{span: 24}} >
                <div className="form-actions">
                    <Button
                        className="rounded-button"
                        onClick={
                            () => history.push(`/${nickname}`)
                        }
                    >
                        <Localize>GLOBAL.Button_Text_Cancel</Localize>
                    </Button>
                    <Button
                        loading={requestLoading}
                        className="rounded-button"
                        type="primary"
                        htmlType="submit"
                    >
                        <Localize wrap>FORMS.Button_Text_ChangePassword</Localize>
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );

}

const ChangePasswordContainer = (props) => {

    const history = useHistory();

    const [changePasswordRequest, { loading, client }] = useMutation(CHANGE_PASSWORD,
        {
            update(cache, { data }) {

                const {
                    changePassword : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            }
        });

    const { me } = client.readQuery({query: GET_ME});

    return (
        <NormalChangePasswordForm
            {...props}
            changePasswordRequest={changePasswordRequest}
            requestLoading={loading}
            id={me.id}
            nickname={ me.nickname }
            history={history}
        />
    );
};

const ChangePasswordForm = Form.create({ name: 'change_password' })(ChangePasswordContainer);

export default ChangePasswordForm;