import { gql } from "apollo-boost";

import { postFieldsFull, postFields, postThumbnailFragment } from "../fragment/post-frgm";
import { mediaFragment } from "../fragment/media-frgm";
import { pageInfoFieldsFull } from "../fragment/pagination-frgm";
import { collectionFields, collectionPostsFields, collectionAuthorFragment  } from "../fragment/collection-frgm";


export const GET_USER_COLLECTIONS = gql`
    query CollectionsUser($user_id : ID!, $endCursor : String){
        collectionsUser(user_id: $user_id, after: $endCursor){
            pageInfo{
                ...pageInfoFieldsFull
            }
            edges{
                node{
                    ...collectionFields
                    ...collectionAuthorFragment
                    posts(first : 4){
                        pageInfo{
                            total
                        }
                        edges{
                            node{
                                id
                                title
                                ...postThumbnailFragment
                            }
                        }
                    }
                }
            }
        }
    }
    ${ pageInfoFieldsFull }
    ${ collectionFields }
    ${ collectionAuthorFragment }
    ${ postThumbnailFragment }
`;

export const GET_COLLECTION = gql`
    query GetCollection($slug : String){
        collection(slug: $slug){
            ...collectionFields
            ...collectionAuthorFragment
            posts(first : 1){
                pageInfo{
                    total
                }   
            }    
        }
    }
    ${ collectionFields }
    ${ collectionAuthorFragment }
`;

export const GET_COLLECTION_POSTS = gql`
    query GetCollectionPosts(
        $id: ID,
        $collection_id : ID!,
        $paginate: TypePaginate,
        $defaultCount: Int!
    ){
        collectionPosts(
            id: $id,
            collection_id: $collection_id,
            paginate: $paginate,
            defaultCount: $defaultCount
        ){
            ...collectionPostsFields
            post{
                ...postFields
                ...mediaFragment
            }
        }
    }
    ${ collectionPostsFields }
    ${ postFields }
    ${ mediaFragment }    
`;

export const GET_COLLECTION_POSTS_DETAILED = gql`
    query GetCollectionPosts(
        $id: ID,
        $collection_id : ID!,
        $paginate: TypePaginate,
        $defaultCount: Int!
    ){
        collectionPosts(
            id: $id,
            collection_id: $collection_id,
            paginate: $paginate,
            defaultCount: $defaultCount
        ){
            ...collectionPostsFields
            post{
                ...postFieldsFull
            }
        }
    }
    ${ collectionPostsFields }
    ${ postFieldsFull }
`;

export const GET_MY_COLLECTIONS = gql`
    query GetMyCollections($post_id : ID){
        me{
            id
            collections(first: 24){
                pageInfo{
                    ...pageInfoFieldsFull
                }
                edges{
                    node{
                        ...collectionFields
                        postInCollection(post_id : $post_id)
                        posts(first : 4){
                            pageInfo{
                                total
                            }
                            edges{
                                node{
                                    id
                                    title
                                    ...postThumbnailFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${ pageInfoFieldsFull }
    ${ collectionFields }
    ${ postThumbnailFragment }
`;