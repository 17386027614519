import deutsch from "./de";
import english from "./en";
import russian from "./ru";


const translation = {
    de : deutsch,
    en : english,
    ru : russian
}

export default translation;