import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";

import { GET_CHAT } from "graphql/query/chat-gql";
import { GET_ME } from "graphql/query/user-gql";

import ChatMessageListHeader from "../chat-message-list-header";
import ChatMessageList from "../chat-message-list";
import { ChatMessageType } from "../chat-message-item";
//import ChatAttachmentsForm from "../chat-attachments-form";
import ChatMessageForm from "../chat-message-form";
import ChatMessageActionModal from "../chat-message-action-modal";
import ChatMessageActions from "../chat-message-actions";
import { ErrorResult, Loader } from "components/result";
import Icons from "components/icons";
import { decode } from "utils";

import "./chat-messages-block.css";



const useGetChat = ({ chatId }) => {

    const { error, loading, data, client } = useQuery(GET_CHAT, {
        variables: {
            id: chatId
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true
    });

    const { me } = client.readQuery({query: GET_ME});

    return { me, error, loading, data };
};

const ChatMessagesBlock = () => {

    const [ selectedMessage, setSelectedMessage ] = useState({});
    const [ currentMessage, setCurrentMessage ] = useState({});

    const { chatSlug } = useParams(),
          [ chatId ] = chatSlug !== undefined ? decode.fromSlug(chatSlug) : [];

    const { data, loading, error : chatError, me } = useGetChat({ chatId });

    useEffect( () => {

        return () => {

            if(currentMessage.action && +currentMessage.chat_id !== +chatId){
                setCurrentMessage({});
            }
        }

    });

    return(
        <div className={`chat-messages-block ${ data !== undefined && data.chat.group_flag ? "group" : "one2one" }`}>
            { !loading && !chatError &&
                <>
                    <ChatMessageListHeader
                        chat={ data.chat }
                        me={ me }
                        chatSlug={ chatSlug }
                    />
                    <div className="chat-message-list-wrap">
                        <ChatMessageList
                            chatId={ chatId }
                            chatSlug={ chatSlug }
                            groupFlag={ data.chat.group_flag }
                            setSelectedMessage={ setSelectedMessage }
                            selectedMessage={ selectedMessage }
                            me={ me }
                        />
                        <ChatMessageActionModal
                            selectedMessage={ selectedMessage }
                            setSelectedMessage={ setSelectedMessage }
                            setCurrentMessage={ setCurrentMessage }
                            me={ me }
                        >
                            <ChatMessageActions
                                selectedMessage={ selectedMessage }
                            />
                        </ChatMessageActionModal>
                    </div>
                    <div className={ `chat-message-action-wrap ${ currentMessage.action ? "with-message" : "" }` }>
                        { currentMessage.action &&
                            <ChatMessageType.Reply
                                message={ currentMessage }
                                action={{
                                    button:
                                    <button onClick={ () => setCurrentMessage({}) }>
                                        <Icons.Close />
                                    </button>,
                                    type: currentMessage.action
                                }}
                            />
                        }
                        <div className="chat-message-action-forms-wrap">
                            {/*<ChatAttachmentsForm*/}
                            {/*    chatId={ chatId }*/}
                            {/*/>*/}
                            <ChatMessageForm
                                chatId={ chatId }
                                currentMessage={ currentMessage }
                                unsetCurrentMessage={ () => setCurrentMessage({}) }
                            />
                        </div>
                    </div>
                </>
            }
            { loading && <Loader /> }
            { !loading && chatError &&
                <ErrorResult
                    error={chatError}
                    goToLink={`/chat`}
                    goToText="Go to all chats"
                />
            }
        </div>
    );

};

export default ChatMessagesBlock;