import {gql} from "apollo-boost";

import { userFields } from "../fragment/user-frgm";


export const chatMessageLinkFields = gql`
    fragment chatMessageLinkFields on ChatMessageLink {
        id
        author_id
        message_id
        title
        description
        url
        created_at
        updated_at
    }
`;

export const chatMessageFieldsShort = gql`
    fragment chatMessageFieldsShort on ChatMessage {
        id
        author_id
        chat_id
        content
        author{
            ...userFields
        }
        status
        message_date
        created_at
    }
    ${ userFields }
`;

export const chatMessageFieldsResponseShort = gql`
    fragment chatMessageFieldsResponseShort on ChatMessageResponse {
        id
        author_id
        chat_id
        content
        author{
            ...userFields
        }
        status
        created_at
        updated_at
    }
    ${ userFields }
`;

export const chatMessageFields = gql`
    fragment chatMessageFields on ChatMessage {
        ...chatMessageFieldsShort
        response{
            ...chatMessageFieldsResponseShort
        }
        
        
        updated_at
    }
    ${ chatMessageFieldsShort }
    ${ chatMessageFieldsResponseShort }
`;



export const chatFieldsShort = gql`
    fragment chatFieldsShort on Chat {
        id
        title
        group_flag
        cover_url
    }
`;

export const chatFields = gql`
    fragment chatFields on Chat {
        ...chatFieldsShort
        messages{
            edges{
                node{
                    ...chatMessageFieldsShort
                }
            }
        }
        count_user
        count_message
        created_at
        updated_at
    }
    ${ chatFieldsShort }
    ${ chatMessageFieldsShort }
`;

export const chatFieldsFull = gql`
    fragment chatFieldsFull on Chat {
        ...chatFields
        users{
            ...userFields
        }
    }
    ${ chatFields }
    ${ userFields }
`;

export const chatMessageFieldsFull = gql`
    fragment chatMessageFieldsFull on ChatMessage {
        ...chatMessageFields
        chat{
            ...chatFields
        }
        links{
            ...chatMessageLinkFields
        }
    }
    ${ chatMessageFields }
    ${ chatFields }
    ${ chatMessageLinkFields }
`;

export const chatUserFields = gql`
    fragment chatUserFields on ChatUser {
        id
        user( id : $id ){
            ...userFields
            
        }
        chat{
           ...chatFields 
        }
        status
        created_at
        updated_at
    }
    ${ chatFields }
    ${ userFields }
`;