import React, { forwardRef, useState, useEffect } from "react";
import { Mentions } from "antd";

import { useAutosize } from "../use-hooks";
import AvatarBlock from "../avatar";
import MentionSearch from "./mention-search";
import Icons from "../icons";

import "./mentions.css";


const { Option } = Mentions;

const onSelect = (props) => {
    const { setQueryOptions, setMentionItems } = props;

    setQueryOptions({
        type: "",
        query: ""
    });

    setMentionItems([]);
};

const useMentionEvent = ({ mentionId, events }) => {

    useEffect(() => {

        const input = document.getElementById(mentionId);

        if(events){

            events.forEach( ( { eventType, eventCallback } ) => {

                const eventHandler = (e) => eventCallback(e, input);

                if(input.getAttribute(eventType) !== "ok"){
                    input.addEventListener(eventType, eventHandler);
                    input.setAttribute(eventType, "ok");
                }


                return () => {
                    input.removeEventListener(eventType, eventHandler);
                }
            });
        }


    }, [ mentionId, events ]);

    return null;
};

const Mention = forwardRef((props, ref) => {

    const {
        id : mentionId,
        autosize = "false",
        position = "before",
        events = [],
        ...options
    } = props;


    useAutosize({
        elementId : autosize === "true" ? mentionId : null
    })

    useMentionEvent({ mentionId, events });

    const [mentionItems, setMentionItems ] = useState([]);
    const [loading, setLoading ] = useState(false);
    const [queryOptions, setQueryOptions ] = useState({ type : "", query: "" });

    return(
        <>
            { queryOptions.type !== "" &&
                <MentionSearch
                    type={ queryOptions.type }
                    query={ queryOptions.query }
                    setItems={ setMentionItems }
                    setLoading={setLoading}
                />
            }
            { position === "before" &&
                <div id={`mention_${mentionId}_result`} className="mention-result before" />
            }
            <Mentions
                {...options}
                id={ mentionId }
                prefix={['@', '#', '^']}
                loading={ loading }
                placement="top"
                filterOption={ false }
                onSearch={ (text, prefix) => {
                        setQueryOptions({
                            type: prefix,
                            query: text
                        })
                    }
                }
                onSelect={ () => onSelect({ setQueryOptions, setMentionItems }) }
                getPopupContainer={ () => { return document.querySelector(`#mention_${mentionId}_result`)} }
                ref={ ref }
            >
                {
                    mentionItems.map( ({ node }) => {

                            if(node.__typename === "User") {

                                const { id, nickname, name, initials, avatar } = node;

                                return (
                                    <Option key={`user_${id}`} value={nickname}>
                                        <AvatarBlock
                                            size={ 44 }
                                            photoUrl={ avatar }
                                            initials={ initials }
                                            label={`@${nickname}`}
                                            text={ name  }
                                        />
                                    </Option>
                                );
                            }

                            if(node.__typename === "Hashtag") {

                                const { id, slug, cover_url, count_posts, count_followers } = node;

                                return (
                                    <Option key={`hashtag_${id}`} value={`${slug}`} >
                                        <AvatarBlock
                                            size={ 44 }
                                            photoUrl={ cover_url }
                                            initials={ <Icons.NoImage /> }
                                            shape="square"
                                            badgeIcon={ <Icons.Hashtag type="small" /> }
                                            label={`#${slug}`}
                                            text={
                                                <div className="card-counters">
                                                    <div className="card-post-count site-sub-text-light-color">
                                                        <Icons.Post filled  />
                                                        <span>{ count_posts }</span>
                                                    </div>
                                                    <div className="card-follower-count site-sub-text-light-color">
                                                        <Icons.Followers />
                                                        <span>{ count_followers }</span>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </Option>
                                );

                            }

                            if(node.__typename === "Collection") {

                                const { id, slug, cover_url, author } = node;

                                return (
                                    <Option key={`collection_${id}`} value={slug} >
                                        <AvatarBlock
                                            shape='square'
                                            size={ 44 }
                                            photoUrl={ cover_url }
                                            initials={ <Icons.NoImage /> }
                                            badgeIcon={ <Icons.Collection type="small" /> }
                                            label={`^${slug}`}
                                            text={
                                                <div className="author">
                                                    <Icons.User filled />
                                                    <span>{author.nickname}</span>
                                                </div>
                                            }
                                        />
                                    </Option>
                                );

                            }

                            return null;
                        }
                    )
                }
            </Mentions>
            { position === "after" &&
                <div id={`mention_${mentionId}_result`} className="mention-result after" />
            }
        </>
    )
});

export default Mention;