import Collection from './collection';
import CollectionAvatar from "./collection-avatar";
import CollectionList from "./collection-list";
import CollectionCreateButton from "./collection-create-button";
import CollectionUpdate from "./collection-update";
import CollectionCardMenu from "./collection-card-menu";
import CollectionFollowButton from "./collection-follow-button";
import CollectionPost from "./collection-post";
import CollectionAddPostPopup from "./collection-add-post-popup";


export default Collection;

export {
    CollectionList,
    CollectionAvatar,
    CollectionCreateButton,
    CollectionUpdate,
    CollectionCardMenu,
    CollectionFollowButton,
    CollectionPost,
    CollectionAddPostPopup
};