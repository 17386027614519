import React, { useState } from "react";

import ChatSearchForm from "../chat-search-form";
import ChatList from "../chat-list";
import ChatCreatePopup from "../chat-create-popup";
import { ElementVisibility } from "components/service";

import "./chats-block.css";


const ChatsBlock = () => {

    const [searchVariables, setSearchVariables] = useState({ type: 'messages', input: '' });

    return(
        <div className="chats-block">
            <ElementVisibility maxWidth={ 768 } type="show" comparison="<">
                <div className="chats-block-header">
                    <h1>Chats</h1>
                    <ChatCreatePopup type="short" />
                </div>
            </ElementVisibility>
            <div className="chat-search-wrap">
                <ChatSearchForm
                    setSearchVariables={ setSearchVariables }
                    searchVariables={ searchVariables }
                />
            </div>
            <div className="chat-list-wrap">
                <ChatList
                    filterQuery={ searchVariables.input.length > 2 ? searchVariables.input : "" }
                    scrollElementClass={ ".chat-list-wrap" }
                />
            </div>
            <ElementVisibility maxWidth={ 767 } type="show" comparison=">">
                <div className="actions-wrap">
                    <ChatCreatePopup />
                </div>
            </ElementVisibility>
        </div>
    );
};

export default ChatsBlock;