import React from 'react';
import { Icon } from 'antd';

const NewsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M17.9,10.4l-1.5-6.7c-0.1-0.5-0.5-0.8-0.9-0.9c-0.5-0.1-0.9,0.1-1.2,0.5c-0.9,1.3-3.5,4.5-6.9,5.3L1.7,9.9
		c-0.9,0.2-1.4,1.1-1.2,2l1.1,4.9c0.2,0.9,1.1,1.4,2,1.2l0.9-0.2L6,20.7c0.2,0.5,0.6,0.8,1.1,1c0.2,0.1,0.5,0.1,0.7,0.1
		c0.3,0,0.5-0.1,0.8-0.2c1-0.4,1.4-1.6,1-2.6l-1.2-2.2l0.8-0.2c3.5-0.8,7.2,0.9,8.6,1.7c0.4,0.2,0.9,0.2,1.3-0.1
		c0.4-0.3,0.5-0.8,0.4-1.2L17.9,10.4z M3.3,16.5c-0.1,0-0.2,0-0.2-0.1l-1.1-4.9c0-0.1,0-0.2,0.1-0.2L7,10.2l1.3,5.1l-1,0.2
		c0,0,0,0,0,0l-2,0.5l0,0l-0.6,0.1l-0.3,0.1c0,0,0,0,0,0L3.3,16.5z M8,20.3c-0.1,0-0.2,0.1-0.3,0c-0.1,0-0.2-0.1-0.3-0.3L6,17.4
		l0.9-0.2l1.3,2.5C8.3,19.9,8.2,20.2,8,20.3z M9.7,15L8.4,9.8c3.1-1,5.3-3.4,6.6-5.1l1.4,6l1.5,6C16,15.7,12.9,14.6,9.7,15z"/>
        <path d="M19.5,9.2c-0.4,0.1-0.7,0.5-0.6,0.9c0.1,0.3,0.4,0.6,0.7,0.6c0.1,0,0.1,0,0.2,0l2.4-0.6c0.4-0.1,0.7-0.5,0.6-0.9
		c-0.1-0.4-0.5-0.7-0.9-0.6L19.5,9.2z"/>
        <path d="M19.1,8.1c0.1,0,0.3,0,0.4-0.1l2.1-1.3c0.3-0.2,0.5-0.7,0.2-1c-0.2-0.3-0.7-0.5-1-0.2l-2.1,1.3c-0.3,0.2-0.5,0.7-0.2,1
		C18.6,8,18.8,8.1,19.1,8.1z"/>
        <path d="M22.9,12.1l-2.5-0.3c-0.4,0-0.8,0.3-0.8,0.7s0.3,0.8,0.7,0.8l2.5,0.3c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.7-0.7
		C23.6,12.6,23.3,12.2,22.9,12.1z"/>
    </svg>
);

const PlatformNewsSvg = () => (
    <svg fill="currentColor"  width="3.613em" height="1em" viewBox="0 0 108.4 30">
        <g>
            <path className="st0" d="M39.7,26.6V10.5h5.5l3.9,13.2h0.2V10.5h3.3v16.1h-5.4l-4-13.2h-0.2v13.2H39.7z"/>
            <path className="st0" d="M56.1,26.6V10.5h10.4v2.8h-7.1v3.8H65v2.8h-5.7v3.9h7.1v2.8H56.1z"/>
            <path className="st0" d="M67.9,10.5h3.4l2,13.3h0.4l2.9-13.3h3.8l2.9,13.3h0.4l2-13.3H89l-3.1,16.1h-4.9l-2.5-12.2l-2.5,12.2h-4.9
			L67.9,10.5z"/>
            <path className="st0" d="M96.1,13.1c-1.7,0-2.6,0.6-2.6,1.7c0,0.5,0.2,0.9,0.6,1.2c0.4,0.3,1.4,0.7,3,1.2c1.6,0.5,2.7,1.1,3.3,1.7
			c0.6,0.6,0.9,1.6,0.9,2.9c0,1.7-0.5,3-1.5,3.8c-1,0.9-2.4,1.3-4,1.3c-1.2,0-2.7-0.2-4.5-0.6l-0.9-0.2l0.3-2.5
			c2.1,0.3,3.7,0.4,4.8,0.4c1.7,0,2.5-0.7,2.5-2.1c0-0.5-0.2-0.9-0.6-1.2c-0.4-0.3-1.2-0.6-2.3-0.9c-1.8-0.5-3.1-1.1-3.9-1.9
			s-1.1-1.7-1.1-3c0-1.6,0.5-2.8,1.5-3.6s2.3-1.2,4-1.2c1.2,0,2.6,0.2,4.4,0.5l0.9,0.2l-0.3,2.6C98.6,13.2,97,13.1,96.1,13.1z"/>
        </g>
        <g id="g42_00000155827301510555995760000014887997491368485303_">
            <polygon id="polygon36_00000183935529976724649520000003117498083696201348_" className="st0" points="23.4,10.6 19,3.1 14.6,10.6
			19,18.4 		"/>
            <polygon id="polygon38_00000005243335698088624030000015479306696560596153_" className="st0" points="23.4,25.9 32.3,25.9 27.9,18.4
			19,18.4 		"/>
            <polygon id="polygon40_00000003085988932179479650000011848489653612650386_" className="st0" points="14.6,25.9 5.8,25.9 10.1,18.4
			19,18.4 		"/>
        </g>
    </svg>
);


const NewsIcon = props => {

    if(props.type === "platform"){
        return <Icon component={ PlatformNewsSvg }/>;
    }

    return <Icon component={ NewsSvg }/>;
};


export default NewsIcon;