import React from 'react';
import { Icon } from 'antd';

const TrashSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 22 22">
        <path d="M17,3.5h-2.9C13.8,2.1,12.5,1,11,1S8.2,2.1,7.9,3.5H5c-0.7,0-1.3,0.6-1.3,1.3v1.5c0,0.6,0.4,1.1,1,1.3v12
		         c0,0.8,0.6,1.4,1.4,1.4h9.9c0.8,0,1.4-0.6,1.4-1.4v-12c0.6-0.2,1-0.6,1-1.3V4.8C18.3,4.1,17.8,3.5,17,3.5z M11,2.5
		         c0.7,0,1.3,0.4,1.6,1H9.4C9.7,2.9,10.3,2.5,11,2.5z M5.2,5h11.7v1.2H5.2V5z M6.1,19.5V7.7h9.7v11.9H6.1z"
        />
    </svg>
);

const TrashFillSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 26 26">
        <path d="M5.5,9.4v14.8c0,0.4,0.4,0.8,0.9,0.8h13.3c0.5,0,0.9-0.4,0.9-0.8V9.4C20.6,9.5,5.5,9.4,5.5,9.4z"/>
        <path d="M21.4,4.2h-3.7v-1c0-1.2-1-2.2-2.2-2.2h-5.1C9.2,1,8.3,2,8.3,3.2v1H4.6c-0.5,0-0.9,0.4-0.9,0.9v2C3.7,7.6,4.1,8,4.6,8h5.6
		         c0.1,0,0.2,0,0.3,0h5.1c0.1,0,0.2,0,0.3,0h5.6c0.5,0,0.9-0.4,0.9-0.9v-2C22.3,4.6,21.9,4.2,21.4,4.2z M16.2,4.2H9.8v-1
		         c0-0.3,0.3-0.6,0.6-0.6h5.1c0.3,0,0.6,0.3,0.6,0.6V4.2z"
        />
    </svg>
);

const TrashIcon = props => {

    if(props.loading === true){
        return <Icon type="loading" />;
    }

    return <Icon component={ props.filled ? TrashFillSvg : TrashSvg} />;

};

export default TrashIcon;