import React from 'react';
import {Col, Row} from "antd";


const FollowWrap = ({children, className = null}) => {
    return (
        <Row className={className} type="flex" gutter={0} justify="center">
            <Col xs={24} md={18} xl={14}>
                {children}
            </Col>
        </Row>
    );
};

export default FollowWrap;