import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Button, Input, Modal} from "antd";

import { USER_QUESTION_REQUEST } from "graphql/mutation/user-gql";

import Icons from "components/icons";
import { Localize } from "components/service";
import { errorNotification } from "components/result";

import "./user-request.css";


const UserRequestModal = () => {

    const [ showPopup, setShowPopup ] = useState(false);
    const [ requestText, setRequestText ] = useState("");
    const [ sended, setSended ] = useState(false);

    const [userRequest, {loading}] = useMutation( USER_QUESTION_REQUEST,
        {
            update() {
                setSended(true);
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return (
        <>
            <button
                className={ `favorite-but` }
                onClick={ () => setShowPopup(true) }
            >
                <Icons.Contact />
                <Localize wrap>MENUS.Label_HelpAndAdvice</Localize>
            </button>
            <Modal
                destroyOnClose={ true }
                footer={
                    <>
                        { sended ?
                            <Button
                                onClick={ () => setShowPopup(false) }
                                className="rounded-button"
                                type="primary"
                            >
                                <Localize>GLOBAL.Button_Text_Done</Localize>
                            </Button> :
                            <>
                                <Button
                                    onClick={ () => setShowPopup(false) }
                                    className="rounded-button"
                                    type="default"
                                >
                                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                                </Button>
                                <Button
                                    loading={ loading }
                                    type="primary"
                                    onClick={ () => {
                                        if (requestText.length > 10){
                                            userRequest({
                                                variables: {
                                                    input: {
                                                        question: requestText
                                                    }
                                                }
                                            }).catch((error) => {
                                                errorNotification(error);
                                            });
                                        }
                                    }}
                                    className="rounded-button"
                                >
                                    <Localize wrap>GLOBAL.Button_Text_Send</Localize>
                                </Button>
                            </>
                        }
                    </>
                }
                closeIcon={
                    <Icons.Close />
                }
                visible={ showPopup }
                closable={ true }
                onCancel={ () => setShowPopup(false) }
                wrapClassName={`modal-actions user-request-modal${ sended ? " sended" : "" }`}
                centered
            >
                <div className="user-request">
                    { sended ?
                        <div className="result">
                            <Localize wrap={"p"}>USER.Request_Text_Result</Localize>
                        </div> :
                        <>
                            <div className="intro">
                                <Localize wrap={"h3"}>USER.Request_Text1</Localize>
                                <Localize
                                    wrap="p"
                                    link = { <Link to={"/chat"} ><b>Chat</b></Link> }
                                    br ={ <br /> }

                                >USER.Request_Text2</Localize>
                                <Localize wrap="p">USER.Request_Text3</Localize>
                            </div>
                            <div className="request">
                                <Input.TextArea
                                    placeholder={ Localize({ children: "USER.Request_Input_Placeholder" }).props.children  }
                                    onChange={(e) => setRequestText( e.target.value )}
                                    value={requestText}
                                    autoSize={{ minRows: 10, maxRows: 15 }}
                                />
                            </div>
                        </>
                    }
                </div>
            </Modal>
        </>
    );
};

export default UserRequestModal;