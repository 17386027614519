import { gql } from "apollo-boost";

import { postFieldsData } from "../fragment/post-frgm";
import { mediaFragmentFull } from "../fragment/media-frgm";
import { attachmentsFragmentFull } from "../fragment/attachments-frgm";


export const CREATE_POST = gql`
    mutation CreatePost($files: [Upload!]!) {
        createPost(files: $files){
            label
            message
            post{
                ...postFieldsData
                ...mediaFragmentFull
                author{
                    id
                }
            }
        }
    }
    ${ postFieldsData }
    ${ mediaFragmentFull }
`;

export const TRASH_POST = gql`
    mutation TrashPost($post_id : ID!) {
        trashPost(post_id : $post_id){
            label
            message
        }
    }`;

export const SHARE_POST = gql`
    mutation SharePost(
        $post_id : ID!,
        $user_id : ID!
    ){
        sharePost(
            post_id : $post_id,
            user_id : $user_id
        ){
            label
            message
        }
    }`;

export const DUPLICATE_POST = gql`
    mutation SharePost(
        $post_id : ID!
    ){
        postDuplicate(
            post_id : $post_id
        ){
            label
            message
            post{
                ...postFieldsData
                author{
                    id
                }
            }
        }
    }
    ${ postFieldsData }
`;

export const UPDATE_POST_DATA = gql`
    mutation UpdatePost(
        $post_id : ID!,
        $input: PostInput!
    ) {
        updatePost(
            post_id : $post_id,
            input: $input
        ){
            label
            message
            post{
                ...postFieldsData
            }
        }
    }
    ${ postFieldsData }
`;

export const POST_ADD_MEDIA = gql`
    mutation AddMedia(
        $post_id : ID!,
        $files: [Upload!]!
    ){
        addMedia(
            post_id : $post_id,
            files: $files
        ){
            label
            message
            post{
                ...postFieldsData
                ...mediaFragmentFull
            }
        }
    }
    ${ postFieldsData }
    ${ mediaFragmentFull }
`;

export const POST_DELETE_MEDIA = gql`
    mutation DeleteMedia($media_id : ID!) {
        deleteMedia(media_id : $media_id){
            label
            message
        }
    }`;

export const POST_ADD_ATTACHMENT = gql`
    mutation AddAttached(
        $post_id : ID!,
        $files: [Upload!]!
    ){
        addAttached(
            post_id : $post_id,
            files: $files
        ){
            label
            message
            post{
                ...postFieldsData
                ...attachmentsFragmentFull
            }
        }
    }
    ${ postFieldsData }
    ${ attachmentsFragmentFull }
`;

export const POST_DELETE_ATTACHMENT = gql`
    mutation DeleteAttached($attached_id : ID!) {
        deleteAttached(attached_id : $attached_id){
            label
            message
        }
    }`;

export const POST_MEDIA_UPDATE_FIELD = gql`
    mutation MediaUpdateField(
        $id: ID!
        $fieldKey: MediaFieldKey
        $fieldValue: String
    ) {
        mediaUpdateField(
              id: $id,
              fieldKey: $fieldKey,
              fieldValue: $fieldValue
            ) {
          label
          message
          post {
            id
            slug
            media {
              id
              description
              order
            }
          }
        }
    }`;


export const POST_MEDIA_SET_ORDER = gql`
    mutation MediaSetOrder(
        $post_id: ID!,
        $ids: [ID!]!
         ) {
        mediaSetOrder(
            post_id: $post_id,
            ids: $ids
             ) {
            label
            message
            post {
                id
                media {
                id
                description
                }
             }
          }
      }`;
