import React from "react";
import { Col, Row } from "antd";

import { DealerInformationForm } from "../../forms";
import { UserTopBar } from "components/user";
import { ElementVisibility, Localize } from "components/service";


const ProfileTabDealerInfo = ({ me }) => {

    return (
        <div>
            <ElementVisibility
                maxWidth={ 768 }
                type="show"
                comparison="<"
            >
                <UserTopBar
                    itsMe
                    title={ <Localize wrap>GLOBAL.Title_AdditionalInfo</Localize> }
                    backLink={ `/${me.nickname}` }
                />
            </ElementVisibility>
            <Row type="flex" className="profile-row">
                <Col span={24}>
                    { !!me.dealer ?
                        <DealerInformationForm me={me} /> :
                        <div className="no-dealer">
                            <Localize wrap="h2">NO_DATA.Text_Dealer</Localize>
                        </div>
                    }

                </Col>
            </Row>
        </div>
    );
};

export default ProfileTabDealerInfo;