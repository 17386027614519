import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { SEARCH_ATTACHMENTS } from 'graphql/query/search-gql';

import { AttachmentsList } from "components/attachments";
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";


const SearchAttachments = (props) => {

    const { query } = props;

    const {loading, data, fetchMore} = useQuery(SEARCH_ATTACHMENTS, {
        variables: {
            text : query,
            orderBy :  [{
                column : 'CREATED_AT',
                order : 'DESC'
            }]
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        searchAttachments : {
            pageInfo,
            edges : attachments
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        if( !loading && pageInfo.hasNextPage){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const searchAttachments = fetchMoreResult.searchAttachments;

                    const newSearchResults = {
                        searchAttachments: {
                            edges : [
                                ...attachments,
                                ...searchAttachments.edges
                            ],
                            __typename: searchAttachments.__typename,
                            pageInfo : searchAttachments.pageInfo
                        }
                    };

                    return searchAttachments.edges.length ? newSearchResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return(
        <>
            { data !== undefined &&
                <>
                    { pageInfo.total > 0 ?
                        <>
                            <AttachmentsList
                                attachments={
                                    attachments.map( ({ node }) => {return {...node}})
                                }
                                className="search-documents-list"
                                rowClass="border-color-light"
                                withAuthor
                                showTextOnButton
                                subText
                                postLink
                            />
                            { pageInfo.hasNextPage && pageInfo.total >= 15 &&
                                <LoadMoreOnScroll
                                    fetchAction={() => fetchMoreAction()}
                                    loading={ false }
                                />
                            }
                        </> :
                        <div className="search-result-not-found">
                            <Localize br={ <br /> } wrap="h3">NO_DATA.Text_Search_Documents</Localize>
                        </div>
                    }
                </>
            }
            { loading && <Loader /> }
        </>
    );
};

export default SearchAttachments;