import React from 'react';
import {Col, Row} from "antd";

import './container-wrap.css';


const ContainerWrap = ({children, className = null}) => {
    return (
        <Row className={className} type="flex" justify="center" gutter={0}>
            <Col xs={24} md={23} lg={20} xl={17} xxl={16} span={24}>
                {children}
            </Col>
        </Row>
    );
};

export default ContainerWrap;