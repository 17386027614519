import { gql } from "apollo-boost";

import { userFieldsShort } from "../fragment/user-frgm";


export const GET_FOLLOWERS_BY = gql`
    query FollowersBy(
        $id : ID!,
        $model: modelSubscription!,
        $endCursor : String
    ){
        followersBy(
            id: $id,
            model: $model,
            after: $endCursor
        ){
            pageInfo{
                hasNextPage
                total
                endCursor
                lastPage
            }
            edges{
                node{
                    ...userFieldsShort
                    me_following
                    itsMe
                }
            }
        }
    }
    ${ userFieldsShort }
`;



export const GET_USER_FOLLOWING = gql`
    query UserFollowing(
        $user_id : ID!,
        $endCursor : String
    ){
        userFollowing(
            user_id: $user_id,
            after: $endCursor
        ){
            pageInfo{
                hasNextPage
                total
                endCursor
                currentPage
                lastPage
            }
            edges{
                node{
                    ...userFieldsShort
                    me_following
                    itsMe
                    counters : user_counters{
                        following_users
                        following_teams
                        following_collections
                        following_tags
                    }
                }
            }
        }
    }
    ${ userFieldsShort }
`;

export const GET_USER_COLLECTION_FOLLOWING = gql`
    query CollectionFollowing(
        $user_id : ID!,
        $endCursor : String
    ){
        collectionFollowing(
            user_id: $user_id,
            after: $endCursor
        ){
            pageInfo{
                hasNextPage
                total
                endCursor
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    title
                    slug
                    cover_url
                    me_following
                    author{
                        id
                        nickname
                        name
                    }
                }
            }
        }
    }`;

export const GET_USER_HASHTAG_FOLLOWING = gql`
    query HashtagFollowing(
        $user_id : ID!,
        $endCursor : String
    ){
        hashtagFollowing(
            user_id: $user_id,
            after: $endCursor
        ){
            pageInfo{
                hasNextPage
                total
                endCursor
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    slug
                    me_following
                    cover_url
                }
            }
        }
     }`;

export const GET_USER_LIKED_POST = gql`
    query ListUsersLikedPost(
        $post_id : ID!,
        $endCursor : String
    ){
        listUsersLikedPost(
            post_id: $post_id,
            after: $endCursor
        ){
            pageInfo{
                hasNextPage
                total
                endCursor
                currentPage
                lastPage
            }
            edges{
                node{
                    id
                    author{
                        ...userFieldsShort
                        me_following
                    }
                    post{
                        id
                    }
                }
            }
        }
    }
    ${ userFieldsShort }
`;