import React from 'react';
import { useQuery } from "@apollo/react-hooks";

import { GET_COLLECTION } from "graphql/query/collection-gql";

import { ContainerWrap, FollowWrap } from "../../wraps";
import { FollowersByType, FollowHeader } from "../../follow";
import { Loader } from "../../result";
import { Localize } from "../../service";


const CollectionFollowersPage = ({ match }) => {

    const { collectionSlug } = match.params;

    const {loading, data } = useQuery(GET_COLLECTION, {
        variables: { slug : collectionSlug },
        fetchPolicy:"cache-and-network"
    });

    return (
        <ContainerWrap className="followers-page container-margin">
            <FollowHeader
                backLink={`/collection/${collectionSlug}`}
                title={ <Localize slug={ `^${collectionSlug}` }>GLOBAL.Title_Followers</Localize> }
            />
            <FollowWrap>
                { !loading ?
                    <FollowersByType
                        id={ data.collection.id }
                        type={"Collection"}
                    /> :
                    <Loader />
                }
            </FollowWrap>
        </ContainerWrap>
    );
};

export default CollectionFollowersPage;