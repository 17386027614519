import React from "react";
import { Button, Form} from "antd";

import useCommentFormMutation from "./comment-form-mutation-hook";

import Icons from "../../icons";
import Mention from "../../mentions";
import { errorNotification } from "../../result";
import { Localize } from "components/service";

import './comment-form.css';


const commentFormSubmit = ({ props, onAddComment }) => {

    const {
        form,
        //postStatus,
        lastCommentId,
        postId,
        type
    } = props;

    form.validateFields((err, values) => {

        onAddComment({
            variables : {
                input : {
                    post_id: postId,
                    content: values[`message_${postId}${type !== undefined ? `_${type}` : ""}`],
                    last_comment_id: parseInt(lastCommentId)
                }
            }
        }).catch((error) => {
            errorNotification(error);
        });

    });

};

const NormalCommentForm = (props) => {

    const {
        form,
        postId,
        mentionPosition = "before",
        type
    } = props;

    const { onAddComment, loading } = useCommentFormMutation({ postId });

    const { getFieldDecorator  } = form;

    return (
        <Form
            className="add-comment"
            onSubmit={ (event)=> {

                event.preventDefault();

                if(!loading) {
                    commentFormSubmit({
                        props,
                        onAddComment
                    });

                    form.setFieldsValue({[`message_${postId}${type !== undefined ? `_${type}` : ""}`]: ''});
                }
            }}
        >
            <div className="comment-field-wrap">
            {getFieldDecorator(`message_${postId}${type !== undefined ? `_${type}` : ""}`)(
                <Mention
                    className="input-bg-color"
                    rows={1}
                    autosize="true"
                    placeholder={
                        Localize({
                            children: "POST.Input_Placeholder_Comments" }
                        ).props.children
                    }
                    position={ mentionPosition }
                />
            )}
            </div>
            <Button
                htmlType="submit"
                disabled={ !form.getFieldValue(`message_${postId}${type !== undefined ? `_${type}` : ""}`) }
            >
                <Icons.ArrowRight full loading={ loading } />
            </Button>
        </Form>
    );
};

const CommentForm = Form.create({ name: 'post_add_comment' })(NormalCommentForm);

export default CommentForm;