import React from 'react';
import {Col, Row} from "antd";

import {ContainerWrap} from "../../wraps";
import ProfileTabs from '../../profile/profile-tabs';


const ProfilePage = ({match, history}) => {

    const { profileTab } = match.params;

    return (
        <ContainerWrap className="container-margin">
            <Row type="flex" gutter={ 24 } justify="center">
                <Col xs={24} sm={24} lg={22} xl={20} xxl={20} span={16} className="content">
                    <ProfileTabs
                        history={history}
                        activeTab={profileTab}
                    />
                </Col>
            </Row>
        </ContainerWrap>
    );
};

export default ProfilePage;