import React from "react";
import { Link } from "react-router-dom";
import Linkify from "react-linkify";



/*
const parseRules = {
    '#' : {
        type : "hashtag",
        rule : "\\B#[A-Za-z0-9\\-_]+(?:|$)",
        route: "/tag/"
    },
    '@' : {
        type : "user",
        rule :  "\\B@[A-Za-z0\\-9-_.]+(?:|$)\\b",
        route: "/"
    },
    '^' : {
        type : "collection",
        rule :  "\\B\\^[A-Za-z0-9\\-_.]+(?:|$)",
        route: "/collection/"
    },
    '~' : {
        type : "team",
        rule :  "\\B~[A-Za-z0-9\\-_.]+(?:|$)",
        route: "/team/"
    }
};
*/
const parseSigns = {
    '#' : {
        type : "hashtag",
        rule : /\B#[\p{L}\d\-_]+(?:|$)/ugi,
        route: "/tag/"
    },
    '@' : {
        type : "user",
        rule :  /\B@[\p{L}\d\-_.]+(?:|$)\b/ugi,
        route: "/"
    },
    '^' : {
        type : "collection",
        rule :  /\B\^[A-Za-z0-9\-_.]+(?:|$)/g,
        route: "/collection/"
    },
    '~' : {
        type : "team",
        rule :  /\B~[A-Za-z0-9\-_.]+(?:|$)/g,
        route: "/team/"
    }
};

//const mainRegExp = /(\B[\^|@|#|＃][^\s,?!:]+)/g;
const mainRegExp = /(\B[\^@#＃][^\s,?!:]+)+(?:|$)/g;


const parseRules = {

    "bold": {
        sign    : "*",
        regExp  : "\\*(?<bold>.*?)\\*",
        tag     : "b"
    },
    "italic" :{
        sign    : "_",
        regExp  : "_(?<italic>.*?)_",
        tag     : "em"
    },
    "underline": {
        sign    : "~",
        regExp  : "~(?<underline>.*?)~",
        tag     : "u"
    },
}


const getAllParseRules = () => {

    const rules = [];

    for (let [, rule] of Object.entries(parseRules)) {
        rules.push(rule.regExp);
    }

    return new RegExp( `${ rules.join("|") }`, "gim");
}

const str2Tag= (items = []) => {

    return items.map( row => {

        if(Array.isArray(row)){
            return str2Tag(row);
        }

        const allRulesReg = getAllParseRules();

        if(typeof row === "string" && allRulesReg.test(row)){

            let matchAll = row.match(allRulesReg);

            const allStrings = row.split(allRulesReg);

            matchAll.forEach( (item) => {
                let match;

                while ((match = allRulesReg.exec(item)) !== null) {

                    for (let [key, field] of Object.entries(match.groups)) {

                        if(field){
                            let CustomTag = parseRules[key].tag;

                            allStrings.forEach( (str, idx) => {
                                if(str === field){
                                    allStrings[idx] = <CustomTag className={ parseRules[key]?.class }>{ field }</CustomTag>;
                                }
                            })
                        }
                    }

                }

            })

            return allStrings;
        }

        return row;
    })
}



const parseString = (props) => {

    const {strToParse, searchSigns, onClickAction} = props;


    return strToParse.split(mainRegExp).map( (item) => {

        if(searchSigns.indexOf(item[0]) !== -1){

            const {
                route,
                rule
            } = parseSigns[item[0]];

            if(item.match(rule) !== null){

                const str = item.match(rule)[0];
                const strParts = item.split(str);

                let slug = str.slice(1);

                return(
                    <React.Fragment key={ str }>
                        { strParts[0] }
                        <Link
                            className="inner-link"
                            to={ `${route}${slug}`}
                            onClick={ onClickAction }
                        >
                            { item.match(rule)[0] }
                        </Link>
                        { strParts[1] }
                    </React.Fragment>
                );
            }
        }

        return item !== "" ?
            item : null;

    });

};

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" className="outer-link" rel="noopener noreferrer">
        {text}
    </a>
);

const LinkedMention = (props) => {

    const {
        searchSigns = [],
        children,
        onClick
    } = props;

    let items;

    if(typeof children === 'string'){

        items = parseString({
            strToParse: children,
            searchSigns,
            onClick
        });

    } else {

        items = children.map((str) => {
            return parseString({
                strToParse: str,
                searchSigns,
                onClick
            });
        });

    }


    return (
        <Linkify componentDecorator={ componentDecorator }>
            { str2Tag(items) }
        </Linkify>
    );
};

export default LinkedMention;