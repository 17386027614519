import React, { useEffect } from "react";
import { Button, Form} from "antd";

import { useChatMessageCreateMutation, useChatMessageUpdateMutation } from "../hooks";

import Icons from "components/icons";
import Mention from "components/mentions";
import { errorNotification } from "components/result";

import './chat-message-form.css';


const messageCreateForm = ({
        form,
        chatId,
        type,
        replyId,
        onCreateOrUpdateMessage
    }) => {

    form.validateFields((err, values) => {

        onCreateOrUpdateMessage({
            variables : {
                chat_id: chatId,
                content: values[`chat_message_${chatId}${type !== undefined ? `_${type}` : ""}`],
                response_id : replyId
            }
        }).catch((error) => {
            errorNotification(error);
        });

    });

};

const messageUpdateForm = ({ props, onUpdateMessage }) => {

    const {
        form,
        messageId,
        content
    } = props;

    form.validateFields((err, values) => {

        onUpdateMessage({
            variables : {
                id: messageId,
                content,
            }
        }).catch((error) => {
            errorNotification(error);
        });

    });

};


const NormalMessageForm = (props) => {

    const {
        form,
        chatId,
        mentionPosition = "before",
        type,
        currentMessage,
        unsetCurrentMessage
    } = props;

    const { onCreateOrUpdateMessage, loading } = useChatMessageCreateMutation({ chatId });
    const { onUpdateMessage, loading : updateLoading } = useChatMessageUpdateMutation();

    const { getFieldDecorator, setFieldsValue  } = form;
    const messageFieldName = `chat_message_${chatId}${type !== undefined ? `_${type}` : ""}`;

    useEffect( () => {
        if(currentMessage && currentMessage.action === "edit"){
            setFieldsValue({[messageFieldName]: currentMessage.content});
        } else {
            setFieldsValue({[messageFieldName]: ""});
        }

        const element = document.querySelector( `#chat_add_message_${messageFieldName}` );

        if(element){
            setTimeout(
                () => {
                    element.dispatchEvent( new CustomEvent('input'));
                }, 10
            );
        }

    }, [ currentMessage, messageFieldName, setFieldsValue ]);


    return (
        <Form
            id={ `${messageFieldName}_form` }
            className="chat-message-form"
            onSubmit={ (e) => {
                e.preventDefault();

                if(!loading && currentMessage.action !== "edit") {

                    messageCreateForm({
                        form,
                        chatId,
                        type,
                        replyId: currentMessage.action === "reply" ? +currentMessage.id : null,
                        onCreateOrUpdateMessage
                    });

                    form.setFieldsValue({[messageFieldName]: ''});

                }

                if(!updateLoading && currentMessage.action === "edit"){
                    messageUpdateForm({
                        props: {
                            form,
                            messageId: currentMessage.id,
                            content: form.getFieldValue(messageFieldName),
                        },
                        onUpdateMessage
                    });
                }

                if(currentMessage){
                    unsetCurrentMessage();
                }
            } }
        >
            <div className="chat-message-field-wrap">

                {getFieldDecorator(
                    messageFieldName,
                )(
                    <Mention
                        className="input-bg-color"
                        rows={1}
                        autosize="true"
                        events={ [
                            {
                                eventType : "keydown",
                                eventCallback : (e) => {
                                    if ((e.keyCode === 13 && !e.shiftKey) && form.getFieldValue(messageFieldName) && !loading) {
                                        e.preventDefault();

                                        document.querySelector( "#chat_message_form_submit" ).click();
                                    }

                                    if (e.shiftKey && e.keyCode === 8 && form.getFieldValue(messageFieldName) && !loading) {
                                        setFieldsValue({[messageFieldName]: ''});
                                    }
                                }
                            }
                        ] }
                        placeholder="Message…"
                        position={ mentionPosition }
                    />
                )}
            </div>
            <Button
                id="chat_message_form_submit"
                htmlType="submit"
                disabled={ !form.getFieldValue(messageFieldName) }
            >
                <Icons.ArrowRight full loading={ loading } />
            </Button>
        </Form>
    );
};

const ChatMessageForm = Form.create({ name: 'chat_add_message' })(NormalMessageForm);

export default ChatMessageForm;