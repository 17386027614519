import React from "react";
import {Col, Row} from "antd";

import CollectionCard from "../collection-card";
import CollectionCardRow from "../collection-card-row";
import CollectionCardAction from "../collection-card-action";

import './collection-list.css';


const CollectionList = (props) => {

    const {
        collections,
        view,
        post_id,
        withActions,
        withAuthor,
        showCounters,
        showFollow,
        showStatus,
    } = props;



    const CollectionItems = collections.map(({ node }) => {

        if( view === "row" ){
            return (
                <Col span={24} key={node.id}>
                    <CollectionCardRow
                        collection={ node }
                        byDefault={ node.slug === 'private_collection' }
                        showStatus={ showStatus }
                        showCounters={ showCounters }
                        showFollow={ showFollow }
                        withAuthor={ withAuthor }
                        cardActions={
                            <>
                                { withActions &&
                                    <CollectionCardAction
                                        postCollectionsCount={ props.postCollectionsCount }
                                        post_id ={ post_id }
                                        collection_id={ node.id }
                                        postInCollection={ node.postInCollection }
                                    />
                                }
                            </>
                        }
                    />
                </Col>
            );
        }

        return (
            <Col xs={12} lg={8} span={8} key={node.id}>
                <CollectionCard
                    collection={ node }
                    byDefault={ node.slug === 'private_collection' }
                />
            </Col>
        );
    });

    return (
        <Row gutter={ view === "row" ? 0 : 24 } className="collection-list">
            { CollectionItems }
        </Row>
    );

};

export default CollectionList;