import { gql } from "apollo-boost";

import { chatMessageFieldsFull, chatFieldsFull } from "../fragment/chat-frgm";


export const CHAT_MESSAGE_CREATED_SUBSCRIPTION = gql`
    subscription chatMessageCreated {
        chatMessageCreated{
            ...chatMessageFieldsFull
        }
    }
    ${ chatMessageFieldsFull }
`;

export const CHAT_MESSAGE_UPDATED_SUBSCRIPTION = gql`
    subscription chatMessageUpdated {
        chatMessageUpdated{
            ...chatMessageFieldsFull
        }
    }
    ${ chatMessageFieldsFull }
`;

export const CHAT_CREATED_SUBSCRIPTION = gql`
    subscription chatCreated {
        chatCreated{
            ...chatFieldsFull
        }
    }
    ${ chatFieldsFull }
`;