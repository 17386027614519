import React from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";

import { GET_POST_SHORT } from "graphql/query/post-gql";
import { LIKE } from "graphql/mutation/follow-gql";

import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";


const CardActionLike = (props) => {

    const client = useApolloClient();

    const {
        postId,
        liked
    } = props;

    const [ onLikeClick, { loading : loadingLike } ] = useMutation(LIKE, {
        update : (cache, { data : { like } }) => {

            const {label, message, liked, like_count} = like;

            const {post} = client.readQuery({
                query: GET_POST_SHORT,
                variables: {
                    post_id: postId,
                }
            });

            client.writeQuery({
                query: GET_POST_SHORT,
                data: {
                    post: {...post, liked, like_count}
                },
            });

            successNotification({
                title: label,
                description: message,
            });
        }
    });

    const onClickLike = () => {
        if(!loadingLike) {

            onLikeClick({
                variables: {
                    post_id: postId,
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <button
            className={ `like-but ${liked ? 'active' : ''}` }
            onClick={ onClickLike }
        >
            <Icons.Like filled={ liked } loading={ loadingLike } />
        </button>
    );
};

export default CardActionLike;