import React from 'react';
import { Icon } from 'antd';

const EventsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M21.5,3.6h-3.2V2.1c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v1.4H7.5V2.1c0-0.4-0.3-0.8-0.8-0.8S6,1.7,6,2.1v1.4H2.6
		c-0.4,0-0.8,0.3-0.8,0.8v16.9c0,0.4,0.3,0.8,0.8,0.8h18.9c0.4,0,0.8-0.3,0.8-0.8V4.3C22.2,3.9,21.9,3.6,21.5,3.6z M6,5.1V6
		c0,0.4,0.3,0.8,0.8,0.8S7.5,6.4,7.5,6V5.1h9.3V6c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V5.1h2.5v3H3.4v-3H6z M3.4,20.4V9.6h17.4
		v10.8H3.4z"/>
        <path d="M8.1,11H5.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1c0.4,0,0.8-0.3,0.8-0.8S8.5,11,8.1,11z"/>
        <path d="M8.1,14.1H5.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1c0.4,0,0.8-0.3,0.8-0.8S8.5,14.1,8.1,14.1z"/>
        <path d="M8.1,17.1H5.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1c0.4,0,0.8-0.3,0.8-0.8S8.5,17.1,8.1,17.1z"/>
        <path d="M12.9,11h-2.1c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1c0.4,0,0.8-0.3,0.8-0.8S13.3,11,12.9,11z"/>
        <path d="M12.9,14.1h-2.1c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1c0.4,0,0.8-0.3,0.8-0.8S13.3,14.1,12.9,14.1z"/>
        <path d="M12.9,17.1h-2.1c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1c0.4,0,0.8-0.3,0.8-0.8S13.3,17.1,12.9,17.1z"/>
        <path d="M17.7,11h-2.1c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1c0.4,0,0.8-0.3,0.8-0.8S18.2,11,17.7,11z"/>
        <path d="M17.7,14.1h-2.1c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h2.1c0.4,0,0.8-0.3,0.8-0.8S18.2,14.1,17.7,14.1z"/>
    </svg>
);

const EventsIcon = props => <Icon component={EventsSvg} {...props} />;

export default EventsIcon;