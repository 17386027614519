import React from "react";
import { useHistory } from "react-router-dom";
import { Tabs } from "antd";

import { UserDraftPostsTab, UserAttachmentsTab, UserPostsTab, UserCollectionsTab } from "./tabs";
import { Loader } from "../../result";
import Icons from "../../icons";
import { Localize } from "components/service";

import './user-tabs.css';



const changeTab = (history, tabKey, userLogin) => {
    if(tabKey === 'posts'){
        history.push(`/${userLogin}`);
    } else {
        history.push(`/${userLogin}/${tabKey}`);
    }
};

const UserTabs = (props) => {

    const {
        activeTab = 'posts',
        userLogin,
        itsMe,
        userId,
        loading
    } = props;

    const history = useHistory();
    const { TabPane } = Tabs;

    return (
        <div className="user-tabs">
            { loading ?
                <Loader /> :
                <Tabs
                    type="card"
                    activeKey={ activeTab }
                    onTabClick={(key) => {
                        changeTab(history, key, userLogin);
                    }}
                >
                    <TabPane
                        tab={
                            <span>
                                <Icons.Post />
                                <Localize >MENUS.Label_Posts</Localize>
                            </span>
                        }
                        key="posts"
                    >
                        <UserPostsTab
                            authorId={userId}
                            itsMe={itsMe}
                            currentTab={ activeTab === 'posts' }
                        />
                    </TabPane>
                    { itsMe &&
                        <TabPane
                            tab={
                                <span>
                                    <Icons.Post type="draft" />
                                    <Localize >MENUS.Label_DraftPosts</Localize>
                                </span>
                            }
                            key="drafts"
                        >
                            <UserDraftPostsTab
                                authorId={userId}
                                currentTab={ activeTab === 'drafts' }
                            />
                        </TabPane>
                    }
                    <TabPane
                        tab={
                            <span>
                                <Icons.Collection />
                                <Localize >MENUS.Label_Collections</Localize>
                            </span>
                        }
                        key="collections"
                    >
                        <UserCollectionsTab
                            authorId={userId}
                            itsMe={itsMe}
                            currentTab={ activeTab === 'collections' }
                        />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <Icons.Document />
                                <Localize >MENUS.Label_Documents</Localize>
                            </span>
                        }
                        key="documents"
                    >
                        <UserAttachmentsTab
                            authorId={userId}
                            itsMe={itsMe}
                            currentTab={ activeTab === 'documents' }
                        />
                    </TabPane>
                </Tabs>
            }
        </div>
    );
};

export default UserTabs;