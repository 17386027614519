import React from 'react';
import { useQuery } from "@apollo/react-hooks";

import { GET_ME, GET_USER } from "graphql/query/user-gql";

import { ContainerWrap } from "../../wraps";
import { UserTopBar, UserHeader, UserTabs } from "../../user";
import { ElementVisibility } from "../../service";
//import { userNormalMeta } from "utils";
//import {ErrorResult} from "../../error";


const UserPage = ({ match }) => {

    const { userLogin, userLoginTab } = match.params;

    const {loading, data, client} = useQuery(GET_USER, {
        variables: { nickname : userLogin },
        fetchPolicy:"cache-and-network"
    });

    const { me } = client.readQuery({query: GET_ME});
    /*
    if(!loading && data.user === null) {
        return (
            <ErrorResult
                error={"User not found"}
                goToLink="/"
                goToText="Go to timeline"
            />
        );
    }
    */
    const headerProps = loading ?
        { loading : loading } :
        {
            itsMe : me.nickname === userLogin,
            userLogin : userLogin,
            user: data.user,
            //metaFields :  userNormalMeta(data.user.meta_fields)
        };

    const userTabProps = loading ?
        { loading : loading } :
        {
            itsMe : me.nickname === userLogin,
            activeTab : userLoginTab,
            userLogin : userLogin,
            userId : data.user.id
        };

    return (
        <ContainerWrap className="container-margin">
            <ElementVisibility
                maxWidth={ 768 }
                type="show"
                comparison="<"
            >
                <UserTopBar
                    leftSide={<></>}
                    title={userLogin}
                    itsMe={headerProps.itsMe}
                />
            </ElementVisibility>
            <UserHeader {...headerProps} />
            <UserTabs {...userTabProps} />
        </ContainerWrap>
    );
};

export default UserPage;