import React from "react";

import {
    SearchPosts,
    SearchCollections,
    SearchUsers,
    SearchHashtags,
    SearchAttachments
} from './type';

import './search-by-type.css';

const SearchByType = (props) => {

    const {
        type,
        query
    } = props;

    const readyQuery = query === "$$$" ? '' : query;

    return(
        <div className="search-results">
            { type === 'news' &&
                 <SearchPosts query={ readyQuery } type={ "news" } />
            }
            { type === 'post' &&
                <SearchPosts query={ readyQuery } />
            }
            { type === 'user' &&
                <SearchUsers query={ readyQuery } />
            }
            { type === 'collection' &&
                <SearchCollections query={ readyQuery } />
            }
            { type === 'hashtag' &&
                <SearchHashtags query={ readyQuery } />
            }
            { type === 'document' &&
                <SearchAttachments query={ readyQuery } />
            }
        </div>
    );
};

export default SearchByType;