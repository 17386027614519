import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { WrapColumn, ContainerWrap, TimelineWrap } from "../../wraps";
import { HashtagAvatar, HashtagPost } from '../../hashtag'
import { TopAppBar } from "../../menu-bar";
import { ElementVisibility } from "../../service";


const HashtagPostPage = () => {

    const { tagSlug } = useParams();
    const [pageLoaded, setPageLoaded ] =  useState(false);

    useEffect( () =>
        window.scrollTo(0, 30),
    [pageLoaded]
    );

    return (
        <ContainerWrap className="container-margin">
            <ElementVisibility maxWidth={ 768 } type="show" comparison="<">
                <TopAppBar
                    title={ <h3>{ `#${tagSlug}` }</h3> }
                />
            </ElementVisibility>
            <TimelineWrap>
                <WrapColumn position="left">
                    <HashtagPost setPageLoaded={ setPageLoaded }/>
                </WrapColumn>
                <WrapColumn position="right">
                    <HashtagAvatar tagSlug={ tagSlug } />
                </WrapColumn>
            </TimelineWrap>
        </ContainerWrap>
    );
};

export default HashtagPostPage;