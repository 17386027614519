import React from "react";

import CollectionFollowButton from "../collection-follow-button";
import { Localize } from "components/service";


const CollectionHeaderActions = (props) => {

    const {
        meFollowing
    } = props;

    return (
        <div className="collection-header-actions">
            <CollectionFollowButton
                {...props}
                buttonClass={'collection-header-but follow-button'}
            >
                <Localize wrap>
                    { meFollowing ?
                        "FOLLOW.Button_Text_Following":
                        "FOLLOW.Button_Text_Follow"
                    }
                </Localize>
            </CollectionFollowButton>
        </div>
    );
};

export default CollectionHeaderActions;