import React from 'react';
import { Icon } from 'antd';

const DocumentSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M22.7,9.5l-6.2-6.8c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1H6.3
		         c-0.4,0-0.8,0.3-0.8,0.8v21c0,0.4,0.3,0.8,0.8,0.8h15.8c0.4,0,0.8-0.3,0.8-0.8V10C22.9,9.8,22.8,9.6,22.7,9.5z M16.7,5.1l3.8,4.2
		         h-3.8V5.1z M7.1,23.5V3.9h8.1V10c0,0.4,0.3,0.8,0.8,0.8h5.4v12.7H7.1z"
        />
        <path d="M9.4,14.3h4.2c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H9.4c-0.4,0-0.8,0.3-0.8,0.8S9,14.3,9.4,14.3z"/>
        <path d="M18.8,15.6H9.4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h9.3c0.4,0,0.8-0.3,0.8-0.8S19.2,15.6,18.8,15.6z"/>
        <path d="M18.8,18.4H9.4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h9.3c0.4,0,0.8-0.3,0.8-0.8S19.2,18.4,18.8,18.4z"/>
    </svg>
);

const DocumentFillSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 18 18">
        <path d="M10.1,2.3H4v13.3h10v-9L10.1,2.3z M9.1,7.6V3.3L13,7.6H9.1z"/>
    </svg>
);


const DocumentIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ props.filled ? DocumentFillSvg : DocumentSvg }/>;
};


export default DocumentIcon;