import React from 'react';
import { Icon } from 'antd';

const HomeSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M24.3,13.8l-9.8-9.6c-0.3-0.3-0.8-0.3-1.1,0l-9.8,9.6c-0.2,0.2-0.3,0.5-0.2,0.8S3.9,15,4.2,15h1.5v8.3
	             c0,0.4,0.3,0.8,0.8,0.8h5c0.4,0,0.8-0.3,0.8-0.8v-6.2h3.6v6.2c0,0.4,0.3,0.8,0.8,0.8h4.9c0.4,0,0.8-0.3,0.8-0.8V15h1.5
	             c0.3,0,0.6-0.2,0.7-0.5S24.5,14,24.3,13.8z M21.5,13.5c-0.4,0-0.8,0.3-0.8,0.8v8.3h-3.4v-6.2c0-0.4-0.3-0.8-0.8-0.8h-5.1
	             c-0.4,0-0.8,0.3-0.8,0.8v6.2H7.2v-8.3c0-0.4-0.3-0.8-0.8-0.8H6.1L14,5.7l7.9,7.8H21.5z"
        />
    </svg>
);

const TimelineSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M19.2,6.8H4.9c-0.4,0-0.8,0.3-0.8,0.8v8.8c0,0.4,0.3,0.8,0.8,0.8h14.2c0.4,0,0.8-0.3,0.8-0.8V7.6
		C19.9,7.2,19.6,6.8,19.2,6.8z M18.4,15.6H5.7V8.3h12.7V15.6z"/>
        <path d="M19.2,1.7c-0.4,0-0.8,0.3-0.8,0.8v1.3H5.7V2.5c0-0.4-0.3-0.8-0.8-0.8S4.2,2,4.2,2.5v2.1c0,0.4,0.3,0.8,0.8,0.8h14.2
		c0.4,0,0.8-0.3,0.8-0.8V2.5C19.9,2,19.6,1.7,19.2,1.7z"/>
        <path d="M19.2,18.7H4.9c-0.4,0-0.8,0.3-0.8,0.8v2.1c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-1.3h12.7v1.3c0,0.4,0.3,0.8,0.8,0.8
		s0.8-0.3,0.8-0.8v-2.1C19.9,19,19.6,18.7,19.2,18.7z"/>
    </svg>
);


const HomeIcon = props => {

    if( props.type === "timeline" ){
        return <Icon component={ TimelineSvg } />
    }

    return <Icon component={ HomeSvg } {...props} />

};

export default HomeIcon;