import React from "react";
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/react-hooks";

import { GET_HASHTAG } from "graphql/query/hashtag-gql";

import HashtagHeader from "./hashtag-header";
import HashtagPosts from "./hashtag-posts";
import { TopAppBar } from "../menu-bar";
import { ElementVisibility } from "../service";
import { ErrorResult, Loader } from "../result";

import './hashtag.css';


const Hashtag = () => {

    const { tagSlug } = useParams();

    const { loading, error, data } = useQuery(GET_HASHTAG, {
        variables: {
            slug : tagSlug
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    if(error || (!loading && data.hashtag === null)) {
        return (
            <ErrorResult
                error={error}
                goToLink="/"
                goToText="Go to dashboard"
            />
        );
    }

    return (
        <div className="hashtag">
            <ElementVisibility maxWidth={ 768 } type="show" comparison="<">
                <TopAppBar
                    title={ <h3>{ `#${tagSlug}` }</h3> }
                />
            </ElementVisibility>
            { data !== undefined &&
            <>
                <HashtagHeader
                    hashtag={ data.hashtag }
                />
                <HashtagPosts
                    hashtagId={ data.hashtag.id }
                    hashtagSlug={ data.hashtag.slug }
                />
            </>
            }
            { loading && <Loader /> }
        </div>
    );

};

export default Hashtag;