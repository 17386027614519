import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_AUTH_TOKEN } from "graphql/query/user-gql";

import { Authorization } from '../pages';
import { useJwtAuthorization } from "../use-hooks";
import { Loader } from "../result";


const Authentication = ({ children }) => {

    const { data } = useQuery(GET_AUTH_TOKEN),
          { loading } = useJwtAuthorization();

    const authToken  = (data !== undefined &&
                        data.authToken !== undefined &&
                        data.authToken !== false) ? data.authToken : false;

    if(loading){
        return <Loader type="block" />;
    }

    return (
        <>
            { authToken ? children : <Authorization /> }
        </>
    );
};


export default Authentication;
