import React, { useEffect, useState } from "react";

import { useColumnsChildren } from "../use-hooks";


const checkWidth = (props) => {

    const {
        maxWidth,
        minWidth,
        comparison,
    } = props;

    switch(comparison){
        case ">":
            return window.innerWidth > maxWidth;

        case "<":
            return window.innerWidth < maxWidth;

        case "<>":
            return maxWidth > window.innerWidth && window.innerWidth > minWidth;

        default: return false;
    }

};

const VisibilityToggle = ({ children, hideElement }) => {

    const { showColumn, hideColumn } = useColumnsChildren(children);

    return (
        hideElement ?
            <>{ showColumn }</> :
            <>{ hideColumn }</>
    );
};

const ElementVisibility = (props) => {

    const {
        children,
        type,
    } = props;

    const [hideElement, setHideElement] = useState(checkWidth(props));

    useEffect(() => {
        const handleResize = () => {
            setHideElement(checkWidth(props));
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });

    if( type === "toggle" ){
        return (
            <VisibilityToggle
                children={children}
                hideElement={hideElement}
            />
        );
    }

    if( type === "show"){
        return (
            hideElement ? <>{ children }</> : null
        );
    }

    return (
        !hideElement ? <>{ children }</> : null
    );
};

export default ElementVisibility;