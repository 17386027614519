import React from "react";
import { Link } from "react-router-dom";

import AvatarBlock from "components/avatar";
import HashtagFollowButton from "../hashtag-follow-button";
import Icons from "components/icons";
import { Localize } from "components/service";

import './hashtag-list.css';




const HashtagRow = ({ node, showCounters, showFollow }) => {

    const {
        id,
        cover_url,
        slug,
        count_posts,
        count_followers,
        me_following
    } = node;

    return (
        <div className="hashtag-row border-color-light">
            <Link to={`/tag/${slug}`} className="card-data">
                <AvatarBlock
                    size={ 44 }
                    photoUrl={ cover_url }
                    initials={ <Icons.NoImage /> }
                    shape="square"
                    badgeIcon={ <Icons.Hashtag type="small" /> }
                    label={`#${slug}`}
                />
            </Link>
            { (showCounters || showFollow) &&
            <div className="card-info">
                {showCounters &&
                <div className="card-counters">
                    <div className="card-post-count site-sub-text-light-color">
                        <Icons.Post filled  />
                        <span>{ count_posts }</span>
                    </div>
                    <div className="card-follower-count site-sub-text-light-color">
                        <Icons.Followers />
                        <span>{ count_followers }</span>
                    </div>
                </div>
                }
                {showFollow &&
                <div className="card-follow">

                    <HashtagFollowButton
                        id={ id }
                        meFollowing={ me_following }
                        buttonType={ !me_following }
                        buttonClass="rounded-button"
                    >
                        <Localize wrap>
                            { me_following ?
                                "FOLLOW.Button_Text_Following":
                                "FOLLOW.Button_Text_Follow"
                            }
                        </Localize>
                    </HashtagFollowButton>

                </div>
                }
            </div>
            }
        </div>
    );

};

const HashtagList = ({ hashtags }) => {

    return (
        <div className="hashtags-list">
            {
                hashtags.map( ({ node }) => {
                    return (
                        <HashtagRow
                            node={node}
                            showCounters
                            showFollow
                            key={ node.id }
                        />
                    );
                })
            }
        </div>

    );
};

export default HashtagList;