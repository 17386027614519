import React from "react";
import { Link } from "react-router-dom";

import Icons from "components/icons";
import CollectionCardCoverImage from "./collection-card-cover-image";
import CollectionCardCoverFourPost from "./collection-card-cover-four-post";
import { Localize } from "components/service";

import './collection-card.css';


const CollectionCard = (props) => {

    const {
        byDefault,
        collection : {
            title,
            slug,
            cover_url,
            status,
            posts,
        }
    } = props;

    let addCardClass = (byDefault && posts.pageInfo.total) || cover_url ? '' : 'empty' ;

    return(
        <div className={ `collection-card ${addCardClass}` }>
            <Link to={ `/collection/${ slug }`} className="card-preview-bg-color" >
                { !byDefault && status === 'private' &&
                    <div className="private site-icon"><Icons.Lock /></div>
                }
                <div className="collection-card-cover">
                {   byDefault ?
                    <CollectionCardCoverFourPost
                        posts={posts}
                    /> :
                    <CollectionCardCoverImage
                        cover_url={ cover_url }
                        posts={posts}
                        title={ title }
                        status={ status }
                    />
                }
                </div>
            </Link>
            <div className="card-data">
                <h3>{ byDefault ? <Localize>COLLECTION.Title_Default</Localize> : title  }</h3>
                <div>
                    <span className="site-sub-text-color">
                        <Localize postCount={ posts.pageInfo.total }>
                            COLLECTION.Text_PostCount
                        </Localize>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CollectionCard;