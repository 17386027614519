import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_FOLLOWERS_BY } from 'graphql/query/follow-gql';

import FollowList from "./follow-list";
import { LoadMoreOnScroll } from "../../service";
import { errorNotification, Loader } from "../../result";


const FollowListFollowersContainer = (props) => {

    const { user, me } = props;

    const { loading, data,fetchMore } = useQuery(GET_FOLLOWERS_BY, {
        variables: {
            user_id : user.id
        },
        fetchPolicy: "cache-and-network"
    });

    const {
        followersBy : {
            pageInfo,
            __typename : typename,
            edges : followers = []
        } = {}
    } = data !== undefined ? data  : {} ;

    const nodes = followers.map(
        ({ node }) => {
            return {
                node : {
                    id: node.id,
                    author: node
                }
            }
        }
    );

    const fetchMoreAction = () => {
        if(!loading && data !== undefined){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newEdges = fetchMoreResult.followersBy.edges;
                    const pageInfo = fetchMoreResult.followersBy.pageInfo;

                    const newFollowers = {
                        followersBy: {
                            edges : [
                                ...followers,
                                ...newEdges
                            ],
                            __typename: typename,
                            pageInfo
                        }
                    };

                    return newEdges.length ? newFollowers : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <div className="follow-list-wrap">
            {  data !== undefined && nodes.length > 0 &&
                <>
                    <FollowList
                        items={nodes}
                        myId={me.id}
                    />
                    { pageInfo.hasNextPage &&
                        <LoadMoreOnScroll
                            fetchAction={() => fetchMoreAction()}
                            loading={false}
                        />
                    }
                </>
            }
            { data !== undefined && !nodes.length &&
                 <div className="follower-message">
                    <p>{ user.id === me.id ? 'You' : 'User' } have no Followers</p>
                </div>
            }
            { loading && <Loader /> }
        </div>
    );
};

export default FollowListFollowersContainer;