import { useSubscription } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";

import { CHAT_MESSAGE_UPDATED_SUBSCRIPTION } from "graphql/subscription/chat-gql";
import { GET_CHAT_MESSAGES } from "graphql/query/chat-gql";

import { decode } from "utils";


const useMessageUpdatedSubscription = () => {

    const { chatSlug } = useParams();

    useSubscription(CHAT_MESSAGE_UPDATED_SUBSCRIPTION, {
        onSubscriptionData: ( { client, subscriptionData : { data } }  ) => {

            if(!data.chatMessageUpdated){
                return null;
            }

            const { chatMessageUpdated: chatMessage } = data;
            const [chatId] = decode.fromSlug(chatSlug);

            if(chatMessage.status === "deleted" && +chatId === +chatMessage.chat_id){

                const { chatMessages } = client.readQuery({
                    query: GET_CHAT_MESSAGES,
                    variables: {
                        chat_id: chatId,
                        defaultCount: 20
                    }
                });

                let filteredMessages = chatMessages.filter(
                        (message) => chatMessage.id !== message.id

                ).map( (message) =>  {
                    if(message.response && chatMessage.id === message.response.id){
                        return { ...message, response: null }
                    }

                    return message;
                })

                client.writeQuery({
                    query: GET_CHAT_MESSAGES,
                    variables: {
                        chat_id: chatId,
                        defaultCount: 20
                    },
                    data: {
                        chatMessages: [ ...filteredMessages ]
                    }
                });


            }

        }

    });

};

export default useMessageUpdatedSubscription;
