import { gql } from "apollo-boost";

import { postFieldsFull, postFields } from "../fragment/post-frgm";
import { mediaFragmentFull } from "../fragment/media-frgm";
import { hashtagPostsFields, hashtagFields } from "../fragment/hashtag-frgm";


export const GET_HASHTAG = gql`
    query GetHashtag($slug : String){
        hashtag(slug: $slug){
            ...hashtagFields
        }
    }
    ${ hashtagFields }
`;


export const GET_HASHTAG_POSTS = gql`
    query GetHashtagPosts(
        $id: ID,
        $hashtag_id : ID!,
        $paginate: TypePaginate,
        $defaultCount: Int!
    ){
        hashtagPosts(
            id: $id,
            hashtag_id: $hashtag_id,
            paginate: $paginate,
            defaultCount: $defaultCount
        ){
            ...hashtagPostsFields
            post{
                ...postFields
                ...mediaFragmentFull
            }
        }
    }
    ${ hashtagPostsFields }
    ${ postFields }
    ${ mediaFragmentFull }
`;


export const GET_HASHTAG_POSTS_DETAILED = gql`
    query GetHashtagPosts(
        $id: ID, $hashtag_id : ID!,
        $paginate: TypePaginate,
        $defaultCount: Int!
    ){
        hashtagPosts(
            id: $id,
            hashtag_id: $hashtag_id,
            paginate: $paginate,
            defaultCount: $defaultCount
        ){
            ...hashtagPostsFields
            post{
                ...postFieldsFull
            }
            
        }
    }
    ${ hashtagPostsFields }
    ${ postFieldsFull }
`;