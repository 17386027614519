import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

import HashtagFollowButton from "../hashtag-follow-button";
import AvatarPhoto from "components/avatar/avatar-photo";
import Icons from "components/icons";
import { Localize } from "components/service";

import './hashtag-header.css';



const HashtagHeader = (props) => {

    const {
        hashtag : {
            id,
            slug,
            count_posts,
            count_followers,
            cover_url,
            me_following
        }
    } = props;

    return (
        <Row className="hashtag-header">
            <Col xs={24} sm={24}  md={8} span={8} className="hashtag-header-photo">
                <div className="avatar big-badge">
                    <AvatarPhoto
                        photoUrl={ cover_url }
                        size={150}
                        shape={'square'}
                        initials={  <Icons.NoImage /> }
                        badgeIcon={ <Icons.Hashtag type="small" /> }
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={16} span={16} className="hashtag-header-info">
                <div className="hashtag-header-data">
                    <h1>#{ slug }</h1>
                    <div className="hashtag-header-actions">
                        <HashtagFollowButton
                            id={ id }
                            meFollowing={ me_following }
                            buttonClass={'hashtag-header-but follow-button'}
                        >
                            <Localize wrap>
                                { me_following ?
                                    "FOLLOW.Button_Text_Unfollow":
                                    "FOLLOW.Button_Text_Follow"
                                }
                            </Localize>
                        </HashtagFollowButton>
                    </div>
                </div>
                <ul className="hashtag-header-statistics company-link-hover">
                    <li className="post-amount-holder site-sub-text-color">
                        <b className="site-bold-color">{ count_posts }</b> <Localize wrap>MENUS.Label_Posts</Localize>
                    </li>
                    <li className="followers-amount-holder site-sub-text-color">
                        <Link to={`/tag/${ slug }/followers`}>
                            <b className="site-bold-color">{ count_followers }</b> <Localize wrap>MENUS.Label_Followers</Localize>
                        </Link>
                    </li>
                </ul>
            </Col>
        </Row>
    );
};

export default HashtagHeader;