import React from "react";

import AvatarBlock from "../../avatar";
import FollowButton from "../follow-button";
import { Localize } from "components/service";

import './follow-list.css';


const FollowList = ({items, myId}) => {

    if(!items.length){
        return null;
    }

    const RenderItems = items.map(({ node }) => {

        const { author } = node;

        return (
            <li key={node.id} className="follow-item">
                <AvatarBlock
                    photoUrl={author.avatar}
                    initials={author.initials}
                    link={`/${author.nickname}`}
                    label={author.nickname}
                    text={author.name}
                    size={44}
                />
                { author.id !== myId ?
                    <FollowButton author={ author }/> :
                    <b className="site-label-text-color">
                        <Localize>GLOBAL.Text_ItsYou</Localize>
                    </b>
                }
            </li>
        );
    });

    return (
        <ul className="follow-list">
            { RenderItems }
        </ul>
    );
};

export default FollowList;