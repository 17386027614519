import React, {useState} from 'react';
import {Col} from "antd";

import CardPreview from "../../card-preview";


const CardListActionGrid  = (  { post, action } ) => {

    const [loading, setLoading] = useState(false);

    return (
        <Col span={8} className="card-preview-holder">
            <CardPreview
                post={post}
                loading={ loading }
                action={() => action(post, setLoading)}
            />
        </Col>
    );
};


export default CardListActionGrid;