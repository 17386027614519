import { errorNotification } from "components/result";


const chatsFetchMoreHelper = ({ hasNextPage, endCursor, loading, fetchMore, chats }) => {

    if(hasNextPage && !loading){

        fetchMore({
            variables: {
                end_cursor: endCursor
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {

                const newResults = fetchMoreResult.chatSearch.edges;
                const pageInfo = fetchMoreResult.chatSearch.pageInfo;

                const newChatSearchResults = {
                    chatSearch: {
                        edges : [
                            ...chats,
                            ...newResults
                        ],
                        __typename: pageInfo.__typename,
                        pageInfo
                    }
                };

                return newResults.length ? newChatSearchResults : previousResult;

            }
        }).catch((error) => {
            errorNotification(error);
        });
    }
};


export default chatsFetchMoreHelper;