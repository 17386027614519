import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CollectionPost, CollectionAvatar } from '../../collections'
import { TopAppBar } from "../../menu-bar";
import { WrapColumn, ContainerWrap, TimelineWrap } from "../../wraps";
import { ElementVisibility } from "../../service";


const CollectionPostPage = () => {

    const { collectionSlug } = useParams();
    const [pageLoaded, setPageLoaded ] =  useState(false);

    useEffect( () =>
        window.scrollTo(0, 30),
        [pageLoaded]
    );

    return (
        <ContainerWrap className="container-margin">
            <ElementVisibility maxWidth={ 768 } type="show" comparison="<">
                <TopAppBar
                    title={ <h3>{ `^${collectionSlug}` }</h3> }
                />
            </ElementVisibility>
            <TimelineWrap>
                <WrapColumn position="left">
                    <CollectionPost
                        setPageLoaded={ setPageLoaded }
                    />
                </WrapColumn>
                <WrapColumn position="right">
                    <CollectionAvatar
                        collectionSlug={ collectionSlug }
                    />
                </WrapColumn>
            </TimelineWrap>
        </ContainerWrap>
    );
};

export default CollectionPostPage;