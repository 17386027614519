import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";

import { GET_DRAFT_POSTS } from '../user-tabs-gql';

import { CardList } from "components/card";
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";
import Icons from "components/icons";


const UserDraftPostsTab = ({ authorId, currentTab }) => {

    const { loading, data, fetchMore } = useQuery(GET_DRAFT_POSTS, {
        variables: {
            status : 'draft',
            author_id : authorId
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        posts : {
            edges : posts,
            __typename : typename,
            pageInfo
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {
        if(pageInfo.total > posts.length && currentTab){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newEdges = fetchMoreResult.posts.edges;
                    const pageInfo = fetchMoreResult.posts.pageInfo;

                    const newPosts = {
                        posts: {
                            edges : [
                                ...posts,
                                ...newEdges
                            ],
                            __typename: typename,
                            pageInfo
                        }
                    };

                    return newEdges.length ? newPosts : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    const Posts = () => {

        if(loading && posts === undefined) return <Loader />;

        if(pageInfo.total >= 1 ) {
            return (
                <div className="posts-list-wrap">
                    <CardList
                        cardItems={posts}
                        type={'draft'}
                        className="grid"
                    />
                    <LoadMoreOnScroll
                        fetchAction={() => fetchMoreAction()}
                        loading={loading}
                    />
                </div>
            );
        }

        return (
            <div className="user-tabs-message">
                <div className="no-content-icon site-icon-color-second">
                    <Icons.NoContent type="drafts" />
                </div>
                <Localize wrap="p">NO_DATA.Text_UserDraftPosts</Localize>
                <Link to="/post/create">
                    <Localize>NO_DATA.Link_Text_UserCreatePost</Localize>
                </Link>
            </div>
        );

    };

    return (
        <div className="user-tab">
            <Posts />
        </div>
    );

};

export default UserDraftPostsTab;