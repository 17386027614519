import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Tabs } from "antd";

import { GET_ME, GET_USER } from "graphql/query/user-gql";

import { FollowingUsersTab, FollowingTagsTab, FollowingCollectionsTab } from "./tabs";
import { Loader } from "../../result";
import Icons from "../../icons";

import './following-tabs.css';
import {Localize} from "../../service";


const { TabPane } = Tabs;

const changeTab = (history, tabKey, userLogin) => {
    history.push(`/${userLogin}/following/${tabKey}`);
};

const FollowingTabs = () => {

    const history = useHistory();
    const { userLogin, followTab } = useParams();

    const { loading, data, client } = useQuery(GET_USER, {
        variables: { nickname : userLogin },
        fetchPolicy:"cache-and-network"
    });

    const { me } = client.readQuery({query: GET_ME});

    const { user = {} } = data || {};

    const tabData = {
        itsMe : user.id === me.id,
        user,
        me
    };

    return (
        <div className="following-tabs">
            { !loading ?
                <Tabs
                    type="card"
                    activeKey={followTab ? followTab : 'users'}
                    onTabClick={(key) => {
                        changeTab(history, key, userLogin);
                    }}
                >
                    <TabPane
                        tab={
                            <Localize wrap icon={ <Icons.User /> } >
                                FOLLOW.Type_Label_Users
                            </Localize>
                        }
                        key="users"
                    >
                        <FollowingUsersTab
                            { ...tabData }
                            currentTab={ followTab === "users" }
                        />
                    </TabPane>
                    <TabPane
                        tab={
                            <Localize wrap icon={ <Icons.Collection /> } >
                                FOLLOW.Type_Label_Collections
                            </Localize>
                        }
                        key="collections"
                    >
                        <FollowingCollectionsTab
                            { ...tabData }
                            currentTab={ followTab === "collections" }
                        />
                    </TabPane>
                    <TabPane
                        tab={
                            <Localize wrap icon={ <Icons.Hashtag /> } >
                                FOLLOW.Type_Label_Hashtags
                            </Localize>
                        }
                        key="tags"
                    >
                        <FollowingTagsTab
                            { ...tabData }
                            currentTab={ followTab === "tags" }
                        />
                    </TabPane>
                </Tabs> :
                <Loader />
            }
        </div>
    );
};

export default FollowingTabs;