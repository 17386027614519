import React from "react";

import { FollowButton } from "../../follow";

const PostCardMenuFollow = (props) => {

    const {
        post,
    } = props;

    return (
        <li>
            <FollowButton
                author={ post.author }
                buttonStyle="inline"
            />
        </li>
    )
};

export default PostCardMenuFollow;