import React from "react";

import CardHeroType from "./card-hero-type";

import './card-hero.css';


const CardHero = (props) => {

   const {
       relation = false
   } = props;

   const type = relation ? relation.relationship_type : 'User';

   switch(type){
       case 'Hashtag':
           return <CardHeroType.Hashtag {...props} />;
       case 'Collection':
           return <CardHeroType.Collection {...props} />;
       default:
           return <CardHeroType.User {...props} />;
   }

};

export default CardHero;