import React from "react";
import { Modal } from "antd";

import ChatMessageItem from "../chat-message-item";

import "./chat-message-action-modal.css";


const ChatMessageActionModal = ({
        selectedMessage,
        setSelectedMessage,
        setCurrentMessage,
        me,
        ...props
    }) => {

    const {
        id = false,
        author,
    } = selectedMessage;

    const hideModal = () => setSelectedMessage({});


    return(
        <Modal
            title={ null }
            footer={ null }
            destroyOnClose={ true }
            visible={ !!id }
            closable={ false }
            onCancel={ hideModal }
            wrapClassName="modal-actions chat-message-action-modal"
            centered
        >
            <div className="message-content">
                { !!id &&
                    <ChatMessageItem
                        message={ selectedMessage }
                        myMessage={ me.id === author.id }
                        showAvatar={ false }
                        showName={ false }
                        noReply={ true }
                    />
                }
            </div>
            { props.children &&
                <div className="message-actions-wrap">
                    { React.cloneElement(
                        props.children,
                        {
                            hideModal,
                            setMessage : (action) => {
                                setCurrentMessage({
                                    ...selectedMessage,
                                    action
                                });
                                hideModal();
                            },
                            myMessage: author && me.id === author.id
                        })
                    }
                </div>
            }
        </Modal>
    )
};

export default ChatMessageActionModal;