import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Radio } from "antd";

import { GET_USER_POSTS } from 'graphql/query/user-gql';

import { CardList } from "components/card";
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";
import Icons from "components/icons";

const normalizedArr = (postsArr) => {

    if(postsArr.length === 0){
        return {
            posts : [],
            lastPostId : false,
            lastOrderDate: false
        };
    }

    const posts = postsArr.map(item => {
        return {node: {...item}}
    });

    const lastId = posts[posts.length - 1].node.id;
    const lastOrderDate = posts[posts.length - 1].node.order_date;

    return {
        posts,
        lastId,
        lastOrderDate
    };

};

const UserPostsTab = (props) => {

    const {
        authorId,
        itsMe,
        currentTab,
        postsPerPage = 12
    } = props;

    const [ hasMorePosts, setHasMorePosts ] = useState( true );
    const [ postsView, setPostsView ] = useState("grid");

    const { loading, data, fetchMore } = useQuery(GET_USER_POSTS, {
        variables: {
            author_id : authorId,
            defaultCount: postsPerPage
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const { posts, lastId, lastOrderDate } = normalizedArr(
        data !== undefined ?
            data.userPosts : []
    );


    const fetchMoreAction = (direction) => {

        if(!loading && hasMorePosts && posts.length >= postsPerPage  && currentTab){

            fetchMore({
                variables: {
                    post_id : lastId,
                    paginate:  direction,
                    order_date: parseInt(lastOrderDate)
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newPostList = fetchMoreResult.userPosts;

                    const newPosts = [
                        ...data.userPosts,
                        ...newPostList,
                    ];

                    if(newPostList.length < postsPerPage){
                        setHasMorePosts(false);
                    }

                    return newPosts.length ? { userPosts : [...newPosts]} : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });

        }
    };

    return (
        <div className="user-tab">
            { loading && data === undefined &&
                <Loader />
            }
            { data !== undefined && posts.length >= 1 &&
            <>
                <div className="posts-view-type">
                    <Radio.Group
                        onChange={ (e) => { setPostsView(e.target.value) }}
                        defaultValue={ postsView }
                        className="input-bg-color"
                    >
                        <Radio.Button value="grid"><Icons.View type="grid" /></Radio.Button>
                        <Radio.Button value="row"><Icons.View type="list" /></Radio.Button>
                    </Radio.Group>
                </div>
                <CardList
                    cardItems={posts}
                    className={postsView}
                    view={postsView}
                    showAuthor={false}
                />
                { hasMorePosts &&
                    <LoadMoreOnScroll
                        fetchAction={() => fetchMoreAction('next')}
                        loading={loading}
                    />
                }
            </>
            }
            { data !== undefined && posts.length === 0 &&
                <div className="user-tabs-message">
                    <div className="no-content-icon site-icon-color-second">
                        <Icons.NoContent type="posts" />
                    </div>
                    <Localize
                        wrap="p"
                        who={ itsMe ?
                            <Localize>NO_DATA.Text_Who_You</Localize> :
                            <Localize>NO_DATA.Text_Who_User</Localize>
                        }
                    >
                        NO_DATA.Text_UserPosts
                    </Localize>
                    { itsMe &&
                        <Link to="/post/create">
                            <Localize>NO_DATA.Link_Text_UserCreatePost</Localize>
                        </Link>
                    }
                </div>
            }
        </div>
    );
};

export default UserPostsTab;