import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_CHAT_MESSAGES } from "graphql/query/chat-gql";

import ChatMessageItem from "../chat-message-item";
import {
    chatMessagesFetchMoreHelper,
    ChatMessagesResizeScrollHelper
} from "../helpers";
import { Loader } from "components/result";
import { LoadMoreOnScroll } from "components/service";
import { getHolderElement } from "utils";

import "./chat-message-list.css";


const ChatMessageList = ({
        me,
        chatId,
        groupFlag,
        messagesPerPage = 20,
        setSelectedMessage,
        selectedMessage
    }) => {

    const [ hasMoreMessages, setHasMoreMessages ] = useState(
        {  next : true, prev: true}
    );

    const {error, loading, data : { chatMessages = [] } = {}, fetchMore } = useQuery(GET_CHAT_MESSAGES, {
        variables: {
            chat_id: chatId,
            defaultCount: messagesPerPage
        },
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true
    });

    if(error){
        console.log(error);
        return null;
    }

    if(loading && !chatMessages){
        return(<Loader />);
    }

    const resultHolder = getHolderElement(".chat-message-list", ".chat-message-list-wrap");

    return(
        <div className="chat-message-list" key={ chatId }>

            { chatMessages.map(
                (message, index, messages) => {
                    const myMessage = message.author_id === me.id;

                    if(chatId === parseInt(message.chat_id)) {

                        const nextMessage = messages[index + 1],
                              prevMessage = messages[index - 1];

                        let showName = !myMessage && groupFlag,
                            showAvatar = !myMessage && groupFlag;

                        const selected = selectedMessage && selectedMessage.id === message.id;

                        if(showName && nextMessage !== undefined && nextMessage.author_id === message.author_id){
                            showName = false;
                        }

                        if(showAvatar && prevMessage !== undefined && prevMessage.author_id === message.author_id){
                            showAvatar = false;
                        }

                        return (
                            <div
                                id={ `message-in-chat-${ message.id }`}
                                className={ `chat-message-wrap ${ selected ? "selected" : ""}` }
                                key={message.id}
                                onClick={ (e) => {
                                    if(e.target.localName !== "a" && e.target.localName !== "button"){
                                        setSelectedMessage( message )
                                    }
                                }}
                            >
                                <ChatMessageItem
                                    message={ message }
                                    myMessage={ myMessage }
                                    showName={ showName }
                                    showAvatar={ showAvatar }
                                />
                            </div>
                        );
                    }

                    return null;
                })
            }
            { resultHolder.scrollElement &&
                <LoadMoreOnScroll
                    fetchAction={
                        () => chatMessagesFetchMoreHelper({
                            direction: "prev",
                            loading,
                            lastMessageId : chatMessages[ chatMessages.length - 1 ].id,
                            firstMessageId : chatMessages[0].id,
                            hasMoreMessages,
                            setHasMoreMessages,
                            messagesPerPage,
                            fetchMore,
                            chatMessages
                        })
                    }
                    loading={ loading }
                    direction="up"
                    {...resultHolder}
                />
            }
            { resultHolder.scrollElement &&
                <ChatMessagesResizeScrollHelper
                    messages={ chatMessages }
                    { ...resultHolder }
                />
            }

        </div>
    );
};

export default ChatMessageList;