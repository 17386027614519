import React from 'react';
import { Icon } from 'antd';

const PostsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M23.3,4H4.7C4.3,4,3.9,4.3,3.9,4.7v18.6c0,0.4,0.3,0.8,0.8,0.8h18.6c0.4,0,0.8-0.3,0.8-0.8V4.7
	C24,4.3,23.7,4,23.3,4z M22.5,5.5v10.2l-4.1-4.9c-0.1-0.2-0.4-0.3-0.6-0.3c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.6,0.3l-4.9,6.2L9,14.4
	c-0.3-0.2-0.8-0.2-1,0.1l-2.6,3V5.5H22.5z M5.4,22.5v-2.7c0,0,0,0,0.1,0L8.7,16l3.3,2.5c0.3,0.2,0.8,0.2,1-0.1l4.8-6l4.6,5.6v4.5
	H5.4z"/>
    </svg>
);

const PostsDraftSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M24.5,3.9H10c-0.4,0-0.8,0.3-0.8,0.8S9.6,5.4,10,5.4h13.7v17.1H6.6v-8.3c0-0.4-0.3-0.8-0.8-0.8
		s-0.8,0.3-0.8,0.8v9c0,0.4,0.3,0.8,0.8,0.8h18.6c0.4,0,0.8-0.3,0.8-0.8V4.7C25.2,4.3,24.9,3.9,24.5,3.9z"/>
        <path d="M12.7,18.4l3.7,0.8c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.3-0.4,0.2-0.7l-0.8-3.6
		c0-0.1-0.1-0.3-0.2-0.4L6.9,4.9c-0.3-0.3-0.8-0.3-1.1,0L3,7.8C2.7,8,2.7,8.5,3,8.8l9.4,9.4C12.4,18.3,12.6,18.3,12.7,18.4z
		 M14.3,14.5l-1.8,1.8L6.2,9.9L8,8.1L14.3,14.5z M13.9,17.1l1.3-1.3l0.4,1.6L13.9,17.1z M6.4,6.5l0.6,0.6L5.1,8.8L4.6,8.3L6.4,6.5z"
        />
    </svg>
);
const PostsFiledSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 20 20">
        <path d="M3,3v13.9H17V3H3z M15.5,15.6H4.8v-2.3l2.1-2.8l2.3,1.9l3.1-4.3l3.1,4.2C15.5,12.3,15.5,15.6,15.5,15.6z"/>
    </svg>
);


const PostIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    if(props.type === "draft"){
        return <Icon component={ PostsDraftSvg }  />;
    }

    return <Icon component={ props.filled  ? PostsFiledSvg : PostsSvg }  />;
};

export default PostIcon;