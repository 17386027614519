import React from 'react';
import { Icon } from 'antd';

const SignOutSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M13.5,17.1c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l4.7-4.5c0.2-0.1,0.3-0.3,0.3-0.6s-0.1-0.4-0.3-0.6L14.6,7
		c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l3.4,3.2H8.5c-0.4,0-0.7,0.3-0.7,0.8s0.3,0.8,0.7,0.8h8.4L13.6,16
		C13.3,16.3,13.2,16.8,13.5,17.1z"/>
        <path d="M20.8,7.1V4.9c0-1.6-1.4-2.9-3.2-2.9h-11c-1.8,0-3.2,1.3-3.2,2.9v14.3c0,1.6,1.4,2.9,3.2,2.9h11c1.8,0,3.2-1.3,3.2-2.9
		v-2.3c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v2.3c0,0.8-0.8,1.4-1.7,1.4h-11c-0.9,0-1.7-0.7-1.7-1.4V4.9c0-0.8,0.8-1.4,1.7-1.4h11
		c0.9,0,1.7,0.7,1.7,1.4v2.2c0,0.4,0.3,0.8,0.8,0.8S20.8,7.5,20.8,7.1z"/>
    </svg>
);

const SignOutIcon = props => <Icon component={SignOutSvg} {...props} />;

export default SignOutIcon;