import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Icon } from "antd";

import AvatarBlocked from "../avatar-blocked";


const AvatarPhoto = (props) => {

    const {
        photoUrl,
        initials,
        link,
        avatarLink = !!link,
        size = 40,
        loading = false,
        badgeIcon,
        shape = 'circle',
        blocked = false
    } = props;

    if(loading){
        return (
            <div className="avatar-wrap">
                <Avatar size={size}>
                    <Icon type="loading" />
                </Avatar>
                { badgeIcon !== undefined &&
                    <div className="avatar-badge">
                        {badgeIcon}
                    </div>
                }
            </div>
        );
    }

    if( blocked ){
        return(
            <div className="avatar-wrap">
                <AvatarBlocked className={ blocked } />
            </div>
        );
    }

    if(avatarLink){
        return (
            <div className="avatar-wrap">
                <Link to={ link }>
                    <Avatar
                        src={ photoUrl }
                        size={size}
                        shape={shape}
                    >{ initials }</Avatar>
                    { badgeIcon !== undefined &&
                        <div className="avatar-badge">
                            {badgeIcon}
                        </div>
                    }
                </Link>

            </div>
        );
    }

    return (
        <div className="avatar-wrap">
            <Avatar
                src={ photoUrl }
                size={size}
                shape={shape}
            >{ initials }</Avatar>
            { badgeIcon !== undefined &&
                <div className="avatar-badge">
                    {badgeIcon}
                </div>
            }
        </div>
    );
};

export default AvatarPhoto;