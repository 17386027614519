import React from 'react';
import { Icon } from 'antd';

const LockSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 13 16">
        <path d="M10.8,5.4h-0.3V4.1C10.6,1.9,8.8,0,6.5,0S2.4,1.9,2.4,4.1v1.2H2.2C1,5.4,0,6.3,0,7.6v6.3C0,15,1,16,2.2,16h8.7
	             c1.2,0,2.2-1,2.2-2.2V7.6C13,6.3,12,5.4,10.8,5.4z M7.7,12.8H5.3l0.4-1.9c-0.3-0.2-0.5-0.6-0.5-1c0-0.7,0.6-1.3,1.3-1.3
	             s1.3,0.6,1.3,1.3c0,0.4-0.2,0.8-0.5,1L7.7,12.8z M8.7,5.4H4.3V4.1c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2V5.4z"
        />
    </svg>
);


const LockIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ LockSvg }/>;
};


export default LockIcon;