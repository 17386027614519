import React from 'react';
import { Icon } from 'antd';

const CloseSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 14 14">
        <path d="M8.4,7l5.3-5.3c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L7,5.6L1.7,0.3c-0.4-0.4-1-0.4-1.4,0
                 c-0.4,0.4-0.4,1,0,1.4L5.6,7l-5.3,5.3c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0L7,8.4l5.3,5.3c0.4,0.4,1,0.4,1.4,0
                 c0.4-0.4,0.4-1,0-1.4L8.4,7z"
        />
    </svg>
);


const CloseIcon = props => <Icon component={ CloseSvg } {...props} />;

export default CloseIcon;