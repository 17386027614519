import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Radio } from "antd";

import { SEARCH_ATTACHMENTS } from "graphql/query/search-gql";

import { AttachmentsList } from "components/attachments";
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";
import Icons from "components/icons";


const UserAttachmentsTab = ({ authorId, itsMe, currentTab }) => {

    const [ sortField, setSortField ] = useState( 'CREATED_AT' );

    const { loading, data, fetchMore } = useQuery(SEARCH_ATTACHMENTS, {
        variables: {
            author_id : authorId,
            text: "",
            orderBy :  [{
                column : sortField,
                order : sortField === 'CREATED_AT' ? 'DESC' : 'ASC'
            }]
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        searchAttachments : {
            edges: attachments,
            pageInfo
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        if( !loading && pageInfo.hasNextPage && currentTab){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const searchAttachments = fetchMoreResult.searchAttachments;

                    const newResults = {
                        searchAttachments: {
                            edges : [
                                ...attachments,
                                ...searchAttachments.edges
                            ],
                            __typename: searchAttachments.__typename,
                            pageInfo : searchAttachments.pageInfo
                        }
                    };

                    return searchAttachments.edges.length ? newResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <div className="user-tab">
            { data !== undefined &&
                <>
                    { pageInfo.total >= 1 ?
                        <>
                            <div className="documents-sorting">
                                <Radio.Group
                                    onChange={
                                        (e) => { setSortField(e.target.value) }
                                    }
                                    defaultValue={ sortField }
                                >
                                    <Radio.Button value="CREATED_AT">
                                        <Localize >SORT.Label_Newest</Localize>
                                    </Radio.Button>
                                    <Radio.Button value="TITLE">
                                        <Localize >SORT.Label_AtoZ</Localize>
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                            <AttachmentsList
                                attachments={
                                    attachments.map( ({ node }) => {return {...node}})
                                }
                                className="user-documents-list"
                                rowClass="border-color-light"
                                showTextOnButton
                                subText
                                postLink
                            />
                            { pageInfo.hasNextPage && pageInfo.total >= 15 &&
                                <LoadMoreOnScroll
                                    fetchAction={() => fetchMoreAction()}
                                    loading={ false }
                                />
                            }
                        </>:
                        <div className="user-tabs-message">
                            <div className="no-content-icon site-icon-color-second">
                                <Icons.NoContent type="documents" />
                            </div>
                            <Localize
                                wrap="p"
                                who={ itsMe ?
                                    <Localize>NO_DATA.Text_Who_You</Localize> :
                                    <Localize>NO_DATA.Text_Who_User</Localize>
                                }
                            >
                                NO_DATA.Text_UserDocuments
                            </Localize>
                        </div>
                    }
                </>
            }
            { loading && <Loader /> }
        </div>
    );
};

export default UserAttachmentsTab;