import React, {useState} from "react";
import {Link} from "react-router-dom";

import AvatarBlock from "../../avatar";
import { CollectionFollowButton } from "../../collections";
import Icons from "../../icons";

import './follow-collection-list.css';


const FollowCollectionItem = ({item, myId}) => {

    const [ meFollowing, setMeFollowing ] = useState( item.me_following );

    const { author } = item;

    return (
        <li className="follow-collection-item">
            <AvatarBlock
                photoUrl={item.cover_url}
                initials={ <Icons.NoImage /> }
                link={`/collection/${item.slug}`}
                label={`^${item.slug}`}
                badgeIcon={ <Icons.Collection type="small" /> }
                size={ 44 }
                avatarAction={
                    <div className="author">
                        <Link to={`/${author.nickname}`} className="site-text-color">
                            <Icons.User filled/>
                            <span>{author.nickname}</span>
                        </Link>
                    </div>
                }
                shape="square"
            />
            { author.id !== myId ?
                <CollectionFollowButton
                    id={ item.id }
                    meFollowing={ meFollowing }
                    buttonType={ !meFollowing }
                    buttonClass="rounded-button"
                    onUpdateAction={ setMeFollowing }
                >
                    { meFollowing ? 'Following' : 'Follow' }
                </CollectionFollowButton> :
                <b className="site-label-text-color">Your collection</b>
            }
        </li>
    );
};

const FollowCollectionList = ({items, myId}) => {

    if(!items.length){
        return null;
    }

    const FollowCollectionItems = items.map(({ node }) => {
        return <FollowCollectionItem
            key={node.id}
            item={ node }
            myId={ myId }
        />
    });

    return (
        <ul className="follow-collection-list">
            { FollowCollectionItems }
        </ul>
    );
};

export default FollowCollectionList;