import React, {useState} from "react";
import {Button, Modal} from "antd";
import {Link} from "react-router-dom";

import CollectionDeleteButton from "../collection-delete-button";
import Icons from "../../icons";
import {Localize} from "../../service";


const Popup = (props) => {

    const {
        showPopup,
        hidePopup,
        id,
        slug
    } = props;

    return (
        <Modal
            title={null}
            footer={null}
            visible={ showPopup }
            closable={ false }
            onCancel={hidePopup}
            wrapClassName="modal-actions"
            centered
        >
            <ul className="modal-actions-list">
                <li>
                    <Link to={`/collection/edit/${slug}`}>
                        <Localize>MENUS.Label_EditCollection</Localize>
                    </Link>
                </li>
                { slug !== 'private_collection' &&
                <li>
                    <CollectionDeleteButton
                        collection_id={ id }
                    />
                </li>
                }
                <li>
                    <button onClick={ hidePopup }>
                        <Localize>MENUS.Button_Text_Cancel</Localize>
                    </button>
                </li>
            </ul>
        </Modal>
    );

};

const CollectionHeaderPopup = ({ id, slug }) => {

    const [showPopup, setShowPopup] = useState(false);

    return (
        <>
            <Button
                className="collection-header-small-but site-icon"
                shape="circle"
                onClick={ () => setShowPopup(true) }
            >
                <Icons.Settings />
            </Button>
            <Popup
                showPopup={ showPopup }
                hidePopup={ () => setShowPopup(false) }
                id={id}
                slug={slug}
            />
        </>
    );
};

export default CollectionHeaderPopup;