import React from 'react';
import { Icon } from 'antd';

const DownloadSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 20.1 17">
        <path d="M9.1,12.9c0.1,0.2,0.3,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3l5.1-5.3c0.3-0.3,0.3-0.8,0-1c-0.3-0.3-0.8-0.3-1,0
		         l-3.9,4.1V0.7c0-0.4-0.3-0.7-0.7-0.7C9.3,0,9,0.3,9,0.7v9.9L5.1,6.6c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1L9.1,12.9z"
        />
        <path d="M19.3,9.8c-0.4,0-0.7,0.3-0.7,0.7v5H1.5v-5c0-0.4-0.3-0.7-0.7-0.7S0,10.1,0,10.5v5.8C0,16.7,0.3,17,0.7,17
		         h18.6c0.4,0,0.7-0.3,0.7-0.7v-5.8C20.1,10.1,19.7,9.8,19.3,9.8z"
        />
    </svg>
);


const DownloadIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ DownloadSvg }/>;
};


export default DownloadIcon;