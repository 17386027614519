import React from "react";
import {useApolloClient, useMutation} from "@apollo/react-hooks";
import { Button } from "antd";

import { GET_USER_SHORT } from "graphql/query/user-gql";
import { FOLLOW_USER } from "graphql/mutation/follow-gql"

import { errorNotification, successNotification } from "components/result";
import { Localize } from "components/service";

import './follow-button.css';


const FollowButton = ({ author, buttonStyle }) => {

    const client = useApolloClient();

    const [ onFollowClick, { loading : followLoading } ] = useMutation(FOLLOW_USER, {
        update : (cache, { data }) => {

            const {
                followUser:{
                    label,
                    message,
                    me_following
                }
            } = data;

            const { user } = client.readQuery({
                query: GET_USER_SHORT,
                variables: {
                    user_id : author.id
                },
            });

           client.writeQuery({
                query: GET_USER_SHORT,
                data: {
                    user : {
                        ...user,
                        me_following
                    }
                },
           });

            successNotification({
                title: label,
                description: message,
            });
        }
    });

    const clickFollow = () => {
        onFollowClick({
            variables: {
                user_id: author.id,
            }
        }).catch((error) => {
            errorNotification(error);
        });
    };

    const notBlocked = (author.status === "block" && author.me_following) || author.status !== "block";

    if( !notBlocked){
        return (
            <p className="blocked">
                <b className="site-sub-text-color">
                    <Localize>GLOBAL.Text_InactiveUser</Localize>
                </b>
            </p>
        )
    }

    if( buttonStyle === "inline" ){
        return (
            <Button
                loading={ followLoading }
                className={ author.me_following ? 'bold-text' : ''}
                onClick={ clickFollow }
            >
                <Localize wrap>
                    { author.me_following ?
                        "FOLLOW.Button_Text_Unfollow":
                        "FOLLOW.Button_Text_Follow"
                    }
                </Localize>
            </Button>
        );
    }

    return(
        <Button
            className="rounded-button"
            type={ !author.me_following ? "primary" : null }
            loading={ followLoading }
            onClick={ clickFollow }
        >
            <Localize wrap>
                { author.me_following ?
                    "FOLLOW.Button_Text_Following":
                    "FOLLOW.Button_Text_Follow"
                }
            </Localize>
        </Button>
    );
};

export default FollowButton;