import React from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { GET_POST_SHORT } from "graphql/query/post-gql";

import {CollectionAddPostPopup} from "components/collections";


const CardActionFavorite = (props) => {

    const {
        postId
    } = props;

    const client = useApolloClient();
    const {post} = client.readQuery({
        query: GET_POST_SHORT,
        variables: {
            post_id: postId,
        }
    });

    return (
        <div>
            <CollectionAddPostPopup post={ post } />
        </div>
    );
};

export default CardActionFavorite;