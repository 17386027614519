import React from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Button } from "antd";

import { FOLLOW_USER } from 'graphql/mutation/user-gql';
import { GET_USER } from  "graphql/query/user-gql";

import { errorNotification, successNotification } from "components/result";
import { Localize } from "components/service";


const UserHeaderActions = ({ follow, userId, userNickname }) => {

    const client = useApolloClient();

    const [ onFollowClick, { loading : followLoading } ] = useMutation(FOLLOW_USER, {
        update : (cache, { data }) => {

            const { followUser } = data;
            const { label, message, me_following, counters } = followUser;

            const {user} = client.readQuery({
                query: GET_USER,
                variables: {
                    nickname: userNickname,
                }
            });

            client.writeQuery({
                query: GET_USER,
                data: {
                    user: {...user, me_following, counters}
                },
            });

            successNotification({
                title: label,
                description: message
            });
        }
    });

    return (
        <div className="user-header-actions">
            <Button
                loading={ followLoading }
                className="user-header-but follow-button"
                type={ !follow ? "primary" : null }
                onClick={
                    () => {
                        if(!followLoading) {
                            onFollowClick({
                                variables: {
                                    user_id: userId,
                                }
                            }).catch((error) => {
                                errorNotification(error);
                            });
                        }
                    }
                }
            >
                <Localize wrap>
                    { follow ?
                        "FOLLOW.Button_Text_Following":
                        "FOLLOW.Button_Text_Follow"
                    }
                </Localize>
            </Button>
        </div>
    );
};

export default UserHeaderActions;