import React from "react";
import { useHistory } from 'react-router-dom';
import { useQuery } from "@apollo/react-hooks";

import { GET_USER_COLLECTIONS } from 'graphql/query/collection-gql';

import { CollectionCreateButton, CollectionList  } from 'components/collections';
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";
import Icons from "components/icons";


const UserCollectionsTab = ({authorId, itsMe, currentTab }) => {

    const {loading, data, fetchMore} = useQuery(GET_USER_COLLECTIONS, {
        variables: {
            user_id : authorId
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const history = useHistory();

    const {
        collectionsUser : {
            edges : collections,
            __typename : typename,
            pageInfo
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {
        if(pageInfo.total > collections.length && currentTab){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newEdges = fetchMoreResult.collectionsUser.edges;
                    const pageInfo = fetchMoreResult.collectionsUser.pageInfo;

                    const newCollections = {
                        collectionsUser: {
                            edges : [
                                ...collections,
                                ...newEdges
                            ],
                            __typename: typename,
                            pageInfo
                        }
                    };

                    return newEdges.length ? newCollections : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <div className="user-tab collections-tab">
            { itsMe &&
                <CollectionCreateButton
                    onCreatedCollection= {
                        (collection) => {
                            history.push(`/collection/edit/${ collection.slug }`)
                        }
                    }
                />
            }
            { !loading && !pageInfo.total &&
                <div className="user-tabs-message">
                    <div className="no-content-icon site-icon-color-second">
                        <Icons.NoContent type="collections" />
                    </div>
                    <Localize
                        wrap="p"
                        who={ itsMe ?
                            <Localize>NO_DATA.Text_Who_You</Localize> :
                            <Localize>NO_DATA.Text_Who_User</Localize>
                        }
                    >
                        NO_DATA.Text_UserCollections
                    </Localize>
                </div>
            }
            { data !== undefined &&
                <>
                    <CollectionList collections={collections}/>
                    {
                        pageInfo.total > collections.length &&
                        <LoadMoreOnScroll
                            fetchAction={() => fetchMoreAction()}
                        />
                    }
                </>
            }
            { loading && <Loader /> }
        </div>
    );
};

export default UserCollectionsTab;