import React, {useEffect} from "react";

import {Loader} from "../result";


const LoadMoreOnScroll = (props) => {

    const { direction } = props;

    if(direction === "up"){
        return <ScrollUp {...props} />
    }

    return <ScrollDown {...props} />

};

const ScrollUp = (props) => {

    const {
        fetchAction,
        loading,
        scrollElement = window
    } = props;

    useEffect(() => {
        const handleScroll = () => {

            const scrollY = scrollElement === window ?
                scrollElement.scrollY :
                scrollElement.scrollTop;

            const actionScroll = 0;


            if (scrollY === actionScroll && !loading) {
                fetchAction();
            }
        };

        scrollElement.addEventListener('scroll', handleScroll);

        return () => {
            scrollElement.removeEventListener('scroll', handleScroll);
        }
    });

    return (
        loading ? <Loader /> : null
    );

};

const ScrollDown = (props) => {
    const {
        fetchAction,
        loading,
        element = document.body,
        scrollElement = window
    } = props;

    useEffect(() => {
        const handleScroll = () => {

            const scrollElementHeight = scrollElement === window ?
                scrollElement.innerHeight :
                scrollElement.clientHeight;

            const scrollY = scrollElement === window ?
                scrollElement.scrollY :
                scrollElement.scrollTop;

            const actionScroll = element.clientHeight - scrollElementHeight * 1.5;


            if (scrollY > actionScroll && !loading) {
                fetchAction();
            }
        };

        const continueFetch = scrollElement === window ?
            element.clientHeight < scrollElement.innerHeight :
            element.clientHeight < scrollElement.clientHeight;

        if (continueFetch && !loading) {
            fetchAction();
        }

        scrollElement.addEventListener('scroll', handleScroll);

        return () => {
            scrollElement.removeEventListener('scroll', handleScroll);
        }
    });

    return (
        loading ? <Loader /> : null
    );
};

export default LoadMoreOnScroll;