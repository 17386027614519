import React from "react";
import {Button, Form, Input} from "antd";

import { errorNotification } from "components/result";
import { Localize } from "components/service";

import './change-forgot-password-form.css';


const submitForm = (props) => {

    const {
        event,
        changeForgotRequest,
        form,
        userId,
        forgotToken
    } = props;

    event.preventDefault();

    form.validateFields((err, values) => {
        if (!err) {
            changeForgotRequest({
                variables : {
                    user_id: userId,
                    password: values.password,
                    key: forgotToken
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    });
};

const NormalChangeForgotPasswordForm = (props) => {

    const {
        form,
        sendFormLoading,
    } = props;

    const { getFieldDecorator } =  form;

    const formItemLayout ={
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <Form
            className="change-forgot-password-form"
            onSubmit={
                (event) => {
                    submitForm({
                        event,
                        form,
                        ...props
                    })
                }
            }
        >
            <Form.Item
                label={
                    <Localize wrap wrapClass="site-label-text-color">
                        FORMS.Input_Label_NewPassword
                    </Localize>
                }
                {...formItemLayout}
            >
                {getFieldDecorator('password', {
                    rules: [
                        {min: 8, message: <Localize minLength={ 8 }>FORM_RULES.Min_Password</Localize> },
                        {required: true, message: <Localize>FORM_RULES.Required_NewPassword</Localize> },
                    ]
                })(
                    <Input.Password name="password" />
                )}
            </Form.Item>
            <Form.Item>
                <div className="form-actions">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="rounded-button"
                        loading={sendFormLoading}
                    >
                        <Localize wrap>GLOBAL.Button_Text_Confirm</Localize>
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );

};

export default NormalChangeForgotPasswordForm;