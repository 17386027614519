import React from "react";
import { Link } from "react-router-dom";

import { WrapColumn } from "../../wraps";
import { TopAppBar } from "../../menu-bar";
import { ElementVisibility } from "../../service";
import Icons from "../../icons";


const FollowHeader = ({
        backLink,
        backLinkMobile,
        title
    }) => {

    return(
        <ElementVisibility maxWidth={ 768 } type="toggle" comparison="<">
            <WrapColumn position="show">
                <TopAppBar
                    backLink={ backLinkMobile }
                    title={ <h3>{ title }</h3> }
                />
            </WrapColumn>
            <WrapColumn position="hide">
                <Link className="back-button button-bg-color site-icon" to={ backLink }>
                    <Icons.ArrowLeft full />
                </Link>
                <h2>{ title }</h2>
            </WrapColumn>
        </ElementVisibility>
    );
};

export default FollowHeader;