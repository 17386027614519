import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Button, Modal } from "antd";

import { TRASH_POST } from "graphql/mutation/post-gql";
import { GET_POST } from "graphql/query/post-gql";

import { errorNotification, successNotification } from "../../result";
import { Localize } from "components/service";


const postDelete = (props) => {

    const {
        postId,
        trashPost,
        client,
    } = props;

    trashPost({
        variables: {
            post_id: postId,
        },
        update : (cache, { data }) => {

            const {
                trashPost: {
                    label,
                    message
                }
            } = data;

           const { post } = client.readQuery({
                query: GET_POST,
                variables: {
                    post_id : postId
                }
            });

            post.status = "deleted";

            client.writeQuery({
                query: GET_POST,
                data: { post }
            });

            successNotification({
                title: label,
                description: message,
            });

        }
    }).catch((error) => {
        errorNotification(error);
    });

};


const PostCardMenuDelete = ( props ) =>{

    const {
        post,
    } = props;

    const client = useApolloClient();

    const [visibleConfirmModal, toggleConfirmModal] = useState(false);
    const [trashPost, { loading : trashLoading }] = useMutation(TRASH_POST);

    return (
        <li>
            <Button
                onClick={ () => toggleConfirmModal(true) }
            >
                <Localize>POST.Button_Text_DeletePost</Localize>
            </Button>
            <Modal
                title={ <Localize>POST.Modal_Title_Delete</Localize> }
                className="remove-post-modal"
                visible={ visibleConfirmModal }
                onOk={
                    () => {
                        postDelete({
                            postId : post.id,
                            trashPost,
                            toggleConfirmModal,
                            client
                        })
                    }
                }
                onCancel={() => toggleConfirmModal(false)}
                okText={ <Localize wrap>GLOBAL.Button_Text_Yes</Localize> }
                okButtonProps={
                    {
                        loading : trashLoading,
                        className : "rounded-button"
                    }
                }
                cancelText={ <Localize wrap>GLOBAL.Button_Text_No</Localize> }
                cancelButtonProps={
                    { className : "rounded-button" }
                }
            >
                <Localize wrap="p">POST.Text_ModalDelete_Question</Localize>
            </Modal>
        </li>
    );
};

export default PostCardMenuDelete;