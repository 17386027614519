import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_HASHTAG } from "graphql/query/hashtag-gql";

import AvatarBlock from "../../avatar";
import { Loader } from "../../result";
import Icons from "../../icons";


const HashtagAvatar = ({ tagSlug }) => {

    const { loading, data } = useQuery(GET_HASHTAG, {
        variables: {
            slug : tagSlug
        }
    });

    return(
        <>
            { !loading ?
                <AvatarBlock
                    photoUrl={ data.hashtag.cover_url }
                    initials={
                        <Icons.NoImage />
                    }
                    link={`/tag/${data.hashtag.slug}`}
                    label={`#${data.hashtag.slug}`}
                    badgeIcon={ <Icons.Hashtag type="small" /> }
                    shape="square"
                    size={50}
                /> :
                <Loader />
            }
        </>
    );
};

export default HashtagAvatar;