import React from "react";
import { Link } from "react-router-dom";

import CollectionHeaderPopup from "../collection-header-popup";
import { ElementVisibility, Localize } from "../../service";


const CollectionHeaderActionsSelf = (props) => {

    const { slug } = props;

    return (
        <div className="collection-header-actions">
            <Link
                className="collection-header-but button-bg-color site-text-color"
                to={`/collection/edit/${slug}`}
            >
                <Localize wrap>COLLECTION.Link_Text_Edit</Localize>
            </Link>
            <ElementVisibility maxWidth={ 768 } type="hide" comparison="<">
                <CollectionHeaderPopup {...props} />
            </ElementVisibility>
        </div>
    );
};

export default CollectionHeaderActionsSelf;