import React from "react";
import { Link } from "react-router-dom";

import UserHeaderPopup from "../user-header-popup";
import { ElementVisibility, Localize } from "components/service";


const UserHeaderActionsSelf = () => {

    return (
        <div className="user-header-actions">
            <Link
                className="user-header-but button-bg-color site-text-color"
                to="/profile/settings"
            >
                <Localize>GLOBAL.Link_Text_EditProfile</Localize>
            </Link>
            <ElementVisibility maxWidth={ 768 } type="hide" comparison="<">
                <UserHeaderPopup />
            </ElementVisibility>
        </div>
    );

};

export default UserHeaderActionsSelf;

