import { useMutation } from "@apollo/react-hooks";

import { DELETE_CHAT_MESSAGE } from "graphql/mutation/chat-gql";


const useChatMessageDeleteMutation = () => {

    const [onDeleteMessage, { error, loading }] = useMutation( DELETE_CHAT_MESSAGE );

    return { onDeleteMessage, loading, error };
};

export default useChatMessageDeleteMutation;