import React from 'react';
import { Icon } from 'antd';


const ListViewSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M3.5,5.8h17c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-17C3.1,4.3,2.8,4.6,2.8,5S3.1,5.8,3.5,5.8z"/>
        <path d="M20.5,11.3h-17c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h17c0.4,0,0.8-0.3,0.8-0.8S20.9,11.3,20.5,11.3z"/>
        <path d="M20.5,18.2h-17c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h17c0.4,0,0.8-0.3,0.8-0.8S20.9,18.2,20.5,18.2z"/>
    </svg>
);

const GridViewSvg = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M21.5,1.7h-19c-0.4,0-0.8,0.3-0.8,0.8v19c0,0.4,0.3,0.8,0.8,0.8h19c0.4,0,0.8-0.3,0.8-0.8v-19
	             C22.2,2.1,21.9,1.7,21.5,1.7z M9.7,14.5V9.6h4.9v4.9H9.7z M14.6,16v4.8H9.7V16H14.6z M3.2,9.6h5v4.9h-5V9.6z M9.7,8.1V3.2h4.9v4.8
	             H9.7z M16.1,9.6h4.6v4.9h-4.6V9.6z M20.7,8.1h-4.6V3.2h4.6V8.1z M8.2,3.2v4.8h-5V3.2H8.2z M3.2,16h5v4.8h-5V16z M16.1,20.7V16h4.6
	             v4.8H16.1z"
        />
    </svg>
);

const ViewIcon = props => {

    if(props.type === "list"){
        return <Icon component={ ListViewSvg } />;
    }

    return <Icon component={ GridViewSvg } />;

};


export default ViewIcon;