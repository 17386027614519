import React, { useState } from "react";
import { Button, Modal } from "antd";


const CardMenu = (props) => {

    const { children } = props;

    const [visiblePopup, togglePopup] = useState(false);

    return(
        <div className="card-menu">
            <Button
                shape="circle"
                icon="ellipsis"
                onClick={ () => togglePopup(true)}
            />
            <Modal
                title={null}
                footer={null}
                visible={ visiblePopup }
                closable={ false }
                onCancel={ () => togglePopup(false) }
                wrapClassName="modal-actions"
                centered
            >
                <>
                    {React.cloneElement(
                        children,
                        {
                            hidePopup :  () =>  togglePopup(false)
                        })
                    }
                </>
            </Modal>
        </div>
    );
};

export default CardMenu;