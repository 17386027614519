import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

import CollectionHeaderActions from "./collection-header-actions";
import CollectionHeaderActionsSelf from "./collection-header-actions-self";
import AvatarPhoto from "../../avatar/avatar-photo";
import Icons from "../../icons";

import './collection-header.css';
import {Localize} from "../../service";

const CollectionActions = (props) => {

    const {
        itsMe,
        id,
        slug,
        meFollowing
    } = props;

    if(itsMe){
        return(
            <CollectionHeaderActionsSelf
                id={ id }
                slug={ slug }
            />
        );
    }

    return (
        <CollectionHeaderActions
            id={ id }
            meFollowing={ meFollowing }
        />
    );

};

const CollectionHeader = (props) => {

    const {
        collection : {
            id,
            title,
            description,
            cover_url,
            slug,
            me_following,
            status,
            author,
            count_posts,
            count_followers
        },
        itsMe
    } = props;

    return (
        <Row className="collection-header">
            <Col xs={24} sm={24}  md={8} span={8} className="collection-header-photo">
                <div className="avatar big-badge">
                    <AvatarPhoto
                        photoUrl={ cover_url }
                        size={150}
                        shape={'square'}
                        initials={  <Icons.NoImage /> }
                        badgeIcon={ <Icons.Collection type="small" /> }
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={16} span={16} className="collection-header-info">
                <div className="collection-header-data">
                    <h1>^{ slug }</h1>
                    <CollectionActions
                        itsMe={itsMe}
                        id={ id }
                        slug={ slug }
                        meFollowing={ me_following }
                    />
                </div>
                {itsMe ?
                    <div className="collection-header-status">
                        {status === 'private' &&
                        <div className="private site-label-text-color">
                            <Icons.Lock/>
                            <Localize wrap>COLLECTION.Type_Private</Localize>
                        </div>
                        }
                        {status === 'public' &&
                        <div className="public site-label-text-color">
                            <Icons.Public/>
                            <Localize wrap>COLLECTION.Type_Public</Localize>
                        </div>
                        }
                    </div> :
                    <div className="collection-header-author">
                        <Link to={`/${author.nickname}`} className="site-label-text-color">
                            <Icons.User filled/>
                            <span>{author.nickname}</span>
                        </Link>
                    </div>
                }
                <ul className="collection-header-statistics company-link-hover">
                    <li className="post-amount-holder site-sub-text-color">
                        <b className="site-bold-color">{ count_posts }</b> <Localize wrap>MENUS.Label_Posts</Localize>
                    </li>
                    {slug !== 'private_collection' &&
                        <li className="followers-amount-holder site-sub-text-color">
                            <Link to={`/collection/${slug}/followers`}>
                                <b className="site-bold-color">{count_followers}</b> <Localize wrap>MENUS.Label_Followers</Localize>
                            </Link>
                        </li>
                    }
                </ul>
                <div className="collection-header-about">
                    { title &&
                        <h2 className="collection-header-name">
                            { slug === 'private_collection' ?
                                <Localize>COLLECTION.Text_YourDefault</Localize> : title
                            }
                        </h2>
                    }
                    { description &&
                        <div className="collection-header-description">{ description }</div>
                    }
                </div>
            </Col>
        </Row>
    );
};

export default CollectionHeader;