import React from "react";
import {Spin} from "antd";
import {Localize} from "../../service";


const Loader = ({ type }) => {

    if(type === "block") {
        return (
            <div className="block-loader">
                <Spin size="large" tip={ <Localize>GLOBAL.Text_Loading</Localize> }/>
            </div>
        );
    }

    return (
        <div className="item-loader">
            <Spin size="large" />
        </div>
    );
};

export default Loader;