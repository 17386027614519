import React from 'react';

import { ContainerWrap } from "components/wraps";
import FollowingTabs, { FollowHeader } from "components/follow";
import { Localize } from "components/service";


const UserFollowingPage = ({ match }) => {

    const { userLogin } = match.params;

    return (
        <ContainerWrap className="following-page container-margin">
            <FollowHeader
                backLink={`/${userLogin}`}
                backLinkMobile={ `/${userLogin}` }
                title={ <Localize slug={ `@${userLogin}` }>GLOBAL.Title_Following</Localize>  }
            />
            <FollowingTabs />
        </ContainerWrap>
    );
};

export default UserFollowingPage;