import React from "react";
import {Button, Result} from "antd";
import { Localize } from "components/service";


const ErrorBlock = (props) => {

    const {
        status = "500",
        title = "ERROR",
        subtitle=<Localize>GLOBAL.Text_SystemError</Localize>,
        action,
        buttonText=<Localize>GLOBAL.Link_Text_SystemError</Localize>
    } = props;

    return(
        <Result
            status={ status }
            title={ title }
            subTitle={ subtitle }
            extra={
                <Button
                    className="rounded-button"
                    type="primary"
                    onClick={ action }
                >
                    { buttonText }
                </Button>
            }
        />
    );
};

export default ErrorBlock;