import React, { useState } from "react";
import {Button, Checkbox, Modal} from "antd";

import ChatCreateButton from "../chat-create-button";
import ChatCreateSelectedUsers from "../chat-create-selected-users";
import { SearchUsers } from "components/search";
import Icons from "components/icons";
import { Localize } from "components/service";

import "./chat-create-popup.css";


const ChatCreateSelectUser = ({ user, selectedUsers, setSelectedUsers }) => {

    return(
        <Checkbox
            checked={ selectedUsers.find((item) => item.id === user.id) !== undefined }
            onChange={ (e) => {
                if(e.target.checked){
                    setSelectedUsers( [ ...selectedUsers, user ] );
                } else {
                    setSelectedUsers( [ ...selectedUsers.filter( (item) => item.id !== user.id) ] );
                }
            }}
        />
    )
};


const ChatCreatePopup = ({ type }) => {

    const [ showPopup, setShowPopup ] = useState(false);
    const [ selectedUsers, setSelectedUsers ] = useState( [] );

    return(
        <div>
            <Button
                onClick={
                    () => setShowPopup(true)
                }
                className={ `chat-create-popup-button rounded-button ${ type === "short" ? "short" : "" }` }
                type="primary"
            >
                <Icons.Chat type="new" />
                { type !== "short" &&
                    <Localize wrap wrapClass="label">CHAT.Button_Text_NewChat</Localize>
                }
            </Button>
            <Modal
                title={ <Localize wrap="h3">CHAT.Modal_Title_NewChat</Localize> }
                destroyOnClose={ true }
                closeIcon={
                    <Icons.Close />
                }
                footer={
                    <>
                        <Button
                            onClick={ () => setShowPopup(false) }
                            className="rounded-button button-bg-color site-text-color"
                        >
                            <Localize>GLOBAL.Button_Text_Cancel</Localize>
                        </Button>
                        <ChatCreateButton
                            selectedUsers={ selectedUsers }
                            setShowPopup={ setShowPopup }
                            setSelectedUsers={ setSelectedUsers }
                        />
                    </>
                }
                visible={ showPopup }
                closable={ true }
                onCancel={ () => setShowPopup(false) }
                wrapClassName="modal-actions chat-create-modal"
                centered
            >
                <ChatCreateSelectedUsers
                    selectedUsers={ selectedUsers }
                    setSelectedUsers={ setSelectedUsers }
                />
                <SearchUsers
                    elementClass={ '.chat-create-modal .search-holder' }
                    scrollElementClass={ '.chat-create-modal .ant-modal-body' }
                    actionButton={ ({ objData }) => {
                        return(
                            <ChatCreateSelectUser
                                user={ objData }
                                selectedUsers={ selectedUsers }
                                setSelectedUsers={ setSelectedUsers }
                            />
                        )
                    }}
                />
            </Modal>
        </div>
    );
};

export default ChatCreatePopup