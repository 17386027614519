import { useMutation } from "@apollo/react-hooks";

import { GET_POST_COMMENTS } from "graphql/query/comment-gql";
import { ADD_COMMENT } from "graphql/mutation/comment-gql";

import { successNotification } from "../../result";


const useCommentFormMutation = (props) => {

    const { postId } = props;

    const [onAddComment, { error, loading }] = useMutation(ADD_COMMENT, {
        update : (cache, { data }) => {

            const {
                addComment : {
                    label,
                    message,
                    comments
                }
            } = data;

            const {post} = cache.readQuery({
                query: GET_POST_COMMENTS,
                variables: {
                    post_id: postId,
                }
            });

            const newCommentsNodes = comments.map( (item) => {
                return { node :  item, __typename: "CommentEdge"}
            });

            const { pageInfo } = post.comments;

            cache.writeQuery({
                query: GET_POST_COMMENTS,
                data: {
                    post: {
                        ...post,
                        comments: {
                            pageInfo: {
                                ...pageInfo,
                                total: pageInfo.total + newCommentsNodes.length,
                            },
                            edges: [...newCommentsNodes, ...post.comments.edges ],
                            __typename: "CommentConnection"
                        }
                    },
                },
            });

            successNotification({
                title: label,
                description: message,
            });

        }
    });

    return { onAddComment, loading, error };
};

export default useCommentFormMutation;