import React from 'react';
import { Icon } from 'antd';

const CompetitionSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M23.2,9.2L15.4,6l0.2-0.5c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.2-0.3-0.4-0.4l-5.1-2.1C10,2.3,10,2.3,9.9,2.2
	c-0.4-0.2-0.8,0-1,0.4L0.9,21c-0.2,0.4,0,0.8,0.4,1c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.7-0.5l4.7-10.7l4,1.6L10.7,13
	c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.2,0.2,0.3,0.4,0.4l8.4,3.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.5-0.2c0.2-0.2,0.3-0.5,0.3-0.7
	l-0.8-4.1l3.5-2c0.3-0.1,0.4-0.4,0.4-0.7S23.4,9.3,23.2,9.2z M10.1,4l3.8,1.5l-2.5,5.5L7.6,9.6L10.1,4z M18.5,11.5
	c-0.3,0.2-0.4,0.5-0.4,0.8l0.7,3.3l-6.4-2.6l2.5-5.5l6.3,2.6L18.5,11.5z"/>
    </svg>
);

const CompetitionIcon = props => <Icon component={CompetitionSvg} {...props} />;

export default CompetitionIcon;