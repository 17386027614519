import React from "react";

import { useChatMessageDeleteMutation } from "../hooks";
import { copyText } from "utils";
import { errorNotification } from "components/result";


const ChatMessageActions = ({  selectedMessage, ...props  }) => {

    const { setMessage, hideModal, myMessage } = props;
    const { onDeleteMessage, loading } = useChatMessageDeleteMutation();

    return(
        <ul className="modal-actions-list company-link-hover">
            <li>
                <button onClick={ () => setMessage("reply") }>
                    Reply
                </button>
            </li>
            { myMessage &&
                <li>
                    <button onClick={ () => setMessage("edit") }>
                        Edit Message
                    </button>
                </li>
            }
            <li>
                <button onClick={ () => {
                    copyText(selectedMessage.content);
                    hideModal();
                }}>
                    Copy text
                </button>
            </li>
            { myMessage &&
                <li className="delete-action">
                    <button onClick={ () => {
                        if(!loading){

                            onDeleteMessage({
                                variables: {
                                    id: selectedMessage.id,
                                }
                            }).catch((error) => {
                                errorNotification(error);
                            });
                            hideModal();
                        }

                    }}>
                        Delete message
                    </button>
                </li>
            }
        </ul>
    )
};

export default ChatMessageActions;