import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import client from "./graphql/client";

import 'antd/dist/antd.css';

import App from './components/app';
import Authentication from "./components/authentication";
import { RealWindowHeight, ScrollToTop } from './components/service';


ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <Authentication>
                <ScrollToTop />
                <RealWindowHeight />
                <App />
            </Authentication>
        </Router>
    </ApolloProvider>,
    document.getElementById('root')
);

