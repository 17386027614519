import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { SEARCH_USERS } from 'graphql/query/search-gql';
import { GET_ME } from "graphql/query/user-gql";

import AvatarBlock from "components/avatar";
import { FollowButton } from "components/follow";
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";


const UserList = ({ users, me }) => {

    return users.map( ({ node }) => {

        const {
            id,
            avatar,
            nickname,
            name,
            initials,
            status,
        } = node;

        return (
            <div className="user-row border-color-light" key={id}>
                <Link to={`/${nickname}`} className="card-data">
                    <AvatarBlock
                        size={ 44 }
                        photoUrl={ avatar }
                        initials={ initials }
                        label={`@${nickname}`}
                        blocked={ status === "block"  ? "normal" : "" }
                        text={ status === "block"  ? "Inactive user" : name }
                    />
                </Link>
                <div className="card-follow">
                    { id !== me.id ?
                        <FollowButton author={ node }/> :
                        <b className="site-label-text-color">It's you</b>
                    }
                </div>
            </div>
        );
    } );
};

const SearchUsers = (props) => {

    const { query, onlyQuery } = props;

    const {loading, data, fetchMore, client} = useQuery(SEARCH_USERS, {
        variables: {
            text : query
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const { me } = client.readQuery({query: GET_ME});

    const {
        searchUsers : {
            pageInfo,
            edges : users
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        if( !loading && pageInfo.hasNextPage){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const searchUsers = fetchMoreResult.searchUsers;

                    const newSearchResults = {
                        searchUsers: {
                            edges : [
                                ...users,
                                ...searchUsers.edges
                            ],
                            __typename: searchUsers.__typename,
                            pageInfo : searchUsers.pageInfo
                        }
                    };

                    return searchUsers.edges.length ? newSearchResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    if(onlyQuery){
        return {loading, data, fetchMoreAction};
    }

    return(
        <>
            { data !== undefined && pageInfo.total > 0 &&
            <>
                <div className="users-list">
                    <UserList
                        users={ users }
                        me={me}
                    />
                </div>
                { pageInfo.hasNextPage &&
                    <LoadMoreOnScroll
                        fetchAction={() => fetchMoreAction()}
                        loading={ false }
                    />
                }
            </>
            }
            { !loading && data !== undefined && pageInfo.total === 0  &&
                <div className="search-result-not-found">
                    <Localize br={ <br /> } wrap="h3">NO_DATA.Text_Search_Users</Localize>
                </div>
            }
            { loading && <Loader /> }
        </>
    );
};

export default SearchUsers;