import React from "react";
import { Drawer } from "antd";

import Comments from "../comments";
import Icons from "../../icons";
import { Localize } from "components/service";

import './comments-drawer.css';



const CommentsDrawer = (props) => {

    const { setShowDrawer, showDrawer, postId } = props;


    return (
        <Drawer
            className="comments-drawer"
            title={
                <>
                    <Localize wrap="h4" >
                        POST.Drawer_Title_Comments
                    </Localize>
                    <button
                        className="close-drawer"
                        onClick={ () => setShowDrawer( false )}
                    >
                        <Icons.Close />
                    </button>
                </>
            }
            placement="right"
            closable={false}
            onClose={ () => setShowDrawer( false )}
            visible={ showDrawer }
            destroyOnClose={true}
        >
            <Comments
                postId={postId}
                withActions
                elementClass={ '.comments-drawer .comments-list-wrap' }
                scrollElementClass={ '.comments-drawer .ant-drawer-body' }
            />
        </Drawer>
    );

};

export default CommentsDrawer;