import { useMutation } from "@apollo/react-hooks";

import { CREATE_CHAT } from "graphql/mutation/chat-gql";


const useChatCreateMutation = (userIds, action) => {

    const [onChatCreate, { error, loading }] = useMutation(CREATE_CHAT, {
        variables: {
            users:  userIds
        },
        update: (cache, { data : { chatCreate } }) => {

            const {
                /*label,
                message,*/
                chat
            } = chatCreate;

            if(chat !== undefined) {
                action(chat);
            }

        }
    });

    return { onChatCreate, error, loading };

};

export default useChatCreateMutation;