import { useMutation } from "@apollo/react-hooks";

import { UPDATE_CHAT_MESSAGE } from "graphql/mutation/chat-gql";


const useChatMessageUpdateMutation = () => {

    const [onUpdateMessage, { error, loading }] = useMutation( UPDATE_CHAT_MESSAGE );

    return { onUpdateMessage, loading, error };
};

export default useChatMessageUpdateMutation;