import React from "react";


const ChatMessageItemReply = ({
        message,
        action
    }) => {

    return(
        <div className={`chat-message-reply ${ action ? action.type : "" }`}>
            { action &&
                <div className="action">
                    { action.button }
                </div>
            }
            <div className="message">
                <h5>
                    { action && action.type === "edit" ?
                        "Edit Message" :
                        message.author.name
                    }
                </h5>
                <div className="message-text">
                    { message.content }
                </div>
            </div>
        </div>
    );
}

export default ChatMessageItemReply;