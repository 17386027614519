import React from "react";
import {Link, useHistory} from "react-router-dom";
import {useMutation} from "@apollo/react-hooks";
import {Button, Form, Input} from "antd";

import { USER_REGISTRATION } from "graphql/mutation/user-gql";

import {errorNotification, successNotification} from "components/result";
import { Localize } from "components/service";

import './registration-form.css';



const formSubmit = ({ event, form, registrationRequest }) => {
    event.preventDefault();

    form.validateFields((err, values) => {
        if (!err) {
            registrationRequest({
                variables : {
                    input: {
                        name: values.fullName,
                        nickname: values.nickname,
                        email: values.email,
                        password: values.password
                    }
                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    });

};

const NormalRegistrationForm = ({ form, logo : Logo }) => {

    const { getFieldDecorator } = form;

    let history = useHistory();

    const [registrationRequest, {loading}] = useMutation(USER_REGISTRATION, {
            update(cache, { data }) {

                const {
                    registerUser : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message,
                    duration: 10
                });

                history.push(`/`);
            }
        }
    );

    const formItemLayout ={
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <div className="form-holder">
            <Logo />
            <h2>
                <Localize>FORMS.Title_Registration</Localize>
            </h2>
            <Form
                onSubmit={(event) => {
                    formSubmit({ event, form, registrationRequest });
                }}
                className="registration-form"
            >
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_Email</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                message: <Localize>FORM_RULES.Email</Localize>,
                            },
                            {
                                required: true,
                                message: <Localize>FORM_RULES.Required_Email</Localize>
                            }
                        ],
                    })(
                        <Input name="email" />,
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_FullName</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('fullName', {
                        rules: [
                            { min: 3, message: <Localize minLength={ 3 }>FORM_RULES.Min_FullName</Localize> },
                            { required: true, message: <Localize>FORM_RULES.Required_FullName</Localize> }
                        ],
                    })(
                        <Input name="fullname" />,
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_Username</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('nickname', {
                        rules: [
                            { min: 3, message: <Localize minLength={ 3 }>FORM_RULES.Min_Username</Localize> },
                            {
                                type: "string",
                                pattern: /^[a-z_.-]+$/,
                                message: <Localize>FORM_RULES.RegExp_Username</Localize>
                            },
                            { required: true, message: <Localize>FORM_RULES.Required_Username</Localize>}
                        ],
                    })(
                        <Input name="username" />,
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_Password</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('password', {
                        rules: [
                            { min: 8, message: <Localize minLength={ 8 }>FORM_RULES.Min_Password</Localize> },
                            { required: true, message: <Localize>FORM_RULES.Required_Password</Localize> }
                        ],
                    })(
                        <Input.Password type="password" name="password"/>,
                    )}
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="rounded-button"
                            loading={loading}
                        >
                            <Localize wrap>FORMS.Button_Text_Register</Localize>
                        </Button>
                    </div>
                </Form.Item>
            </Form>
            <div className="sign-link-wrap">
                <span className="site-label-text-color">
                    <Localize>FORMS.Text_Login_Question</Localize>
                </span>
                <Link to="/" className="company-color">
                    <Localize>FORMS.Link_Text_Login</Localize>
                </Link>
            </div>
        </div>
    );
};


const RegistrationForm = Form.create({ name: 'registration' })(NormalRegistrationForm);

export default RegistrationForm;