import React from 'react';
import {Col} from "antd";

import CardPost from "../../card-post";


const CardListDashboard  = (props) => {

    const {
        post,
        relationship_info
    } = props;

    return (
        <Col span={24} className="card-dashboard-holder" >
            <CardPost
                post={post}
                relationship_info={relationship_info}
            />
        </Col>
    );
};

export default CardListDashboard;