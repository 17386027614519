import React from 'react';
import { useParams } from 'react-router-dom';

import {ContainerWrap} from "../../wraps";
import { CollectionUpdate } from "../../collections";

const CollectionUpdatePage = () => {

    const { collectionSlug } = useParams();


    return (
        <ContainerWrap className="container-margin">
            <CollectionUpdate
                collectionSlug={ collectionSlug  }
            />
        </ContainerWrap>
    );
};

export default CollectionUpdatePage;