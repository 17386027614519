import { gql } from "apollo-boost";

import { userDealerFragment } from "./dealer-frgm";


export const userFieldsShort = gql`
    fragment userFieldsShort on User {
        id
        nickname
        name
        initials
        salutation
        avatar
        status
    }`;

//seller_number
//dealer_id
//date_entrance_at


export const userFields = gql`
    fragment userFields on User {
        ...userFieldsShort
        ...userDealer
        email
        locale
        role
        gender
        phone
        job_type
        date_birth_at
        about
        last_active_date
    }
    ${ userFieldsShort }
    ${ userDealerFragment }
`;

export const userMetaFields = gql`
    fragment userMetaFields on UserMeta {
        id
        meta_key
        meta_type
        meta_value
    }`;

export const userMetaFragment = gql`
    fragment userMeta on User {
        meta_fields{
            ...userMetaFields
        }
    }
    ${ userMetaFields }
`;



