import React from 'react';
import { Icon } from 'antd';

const ShareSvg = () => (
    <svg fill="currentColor"  width="26px" height="26px" viewBox="0 0 26 26">
        <path d="M1.3,23.4c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.6-0.4-0.6-0.7c0-10.1,10.7-15.1,14.4-15.9V3.3c0-0.3,0.2-0.6,0.4-0.7
                 c0.3-0.1,0.6-0.1,0.8,0.1l8.9,7.5c0.2,0.1,0.3,0.4,0.3,0.6s-0.1,0.4-0.3,0.6L16.2,19c-0.2,0.2-0.5,0.2-0.8,0.1
                 c-0.3-0.1-0.4-0.4-0.4-0.7v-4C6.7,15.1,2,23,2,23.1C1.9,23.3,1.6,23.4,1.3,23.4z M16.4,4.9v2.5c0,0.4-0.3,0.7-0.7,0.7
                 c-2.1,0.2-11.5,4-13.3,11.7c2.3-2.7,6.8-6.9,13.3-7.1c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5v3.2l6.9-5.9l-3.8-3.2l0,0
                 L16.4,4.9z"
        />
    </svg>
);


const ShareIcon = props => <Icon component={ ShareSvg } {...props} />;

export default ShareIcon;