import { gql } from "apollo-boost";

import { pageInfoFieldsFull } from "../fragment/pagination-frgm";


export const NOTIFICATIONS_COUNT = gql`
    query NotificationsCount(
        $user_id : ID!,
        $view: Boolean
    ){
        userNotifications(
            user_id: $user_id,
            first: 1, view: $view
        ){
            pageInfo{
                total
            }
        }
    }`;

export const GET_NOTIFICATION = gql`
    query MyNotification($id : ID!) {
        myNotification(id: $id){
            id
            status
            type
            notifi_data{
                message
                obj_left{
                    id
                    text
                    image
                    slug
                }
                obj_right{
                    id
                    text
                    image
                    slug
                }
            }
        }
    }`;

export const NOTIFICATIONS_LIST = gql`
    query UserNotifications(
        $user_id : ID!,
        $status: Boolean,
        $endCursor : String
    ){
        userNotifications(
            user_id: $user_id,
            first: 15,
            status: $status,
            after: $endCursor
        ){
            pageInfo{
                ...pageInfoFieldsFull
            }
            edges{
                node{
                    id
                    status
                    view
                    type
                    notifi_data{
                        message
                        obj_left{
                            id
                            text
                            image
                            slug
                        }
                        obj_right{
                            id
                            text
                            image
                            slug
                        }
                    }
                }
            }
        }
    }
    ${ pageInfoFieldsFull }
`;