import React, { useState } from "react";
import { Button, Modal } from "antd";

import CollectionCreateButton from "../collection-create-button";
import CollectionToAddPost from "../collection-to-add-post";
import Icons from "components/icons";
import { Localize } from "components/service";


const CollectionAddPostPopup = (props) => {

    const { post } = props;

    const [ showPopup, setShowPopup ] = useState(false);
    const [ refetchCollections, setRefetchCollections ] = useState(false);

    return (
        <>
            <button
              className={ `favorite-but ${ post.inCollection ? 'active' : ''}` }
              onClick={ () => setShowPopup(true) }
            >
                <Icons.Collection type="Add" active={ post.inCollection } />
            </button>
            <Modal
                title={<Localize wrap="h3">COLLECTION.Modal_Title_AddPost</Localize>}
                destroyOnClose={ true }
                footer={
                    <>
                        <CollectionCreateButton
                          hideOnComplete
                          onCreatedCollection= {
                              () => {
                                  setRefetchCollections(true);
                              }
                          }
                        />
                        <Button
                          onClick={ () => setShowPopup(false) }
                          className="rounded-button"
                          type="primary"
                        >
                            <Localize>GLOBAL.Button_Text_Done</Localize>
                        </Button>
                    </>
                }
                closeIcon={
                  <Icons.Close />
                }
                visible={ showPopup }
                closable={ true }
                onCancel={ () => setShowPopup(false) }
                wrapClassName="modal-actions add-to-collection-modal"
                centered
            >
                <CollectionToAddPost
                    postId={post.id}
                    refetchCollections={ refetchCollections }
                    setRefetchCollections={ setRefetchCollections }
                />
            </Modal>
        </>
    );
};

export default CollectionAddPostPopup;