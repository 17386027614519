import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import { DUPLICATE_POST } from "graphql/mutation/post-gql";

import { Localize } from "components/service";
import { errorNotification, successNotification } from "components/result";


const PostCardMenuDuplicate = ({ post_id } ) => {

    const history = useHistory();

    const [ onDuplicateClick, { loading } ] = useMutation(DUPLICATE_POST, {
        update : (cache, { data : { postDuplicate } }) => {

            const { label, message, post } = postDuplicate;

            successNotification({
                title: label,
                description: message,
            });

            history.push(`/post/edit/${post.slug}`)

        }
    });

    return(
        <Button
            loading = { loading }
            onClick={ () => {

                onDuplicateClick({
                    variables: {
                        post_id,
                    }
                }).catch((error) => {
                    errorNotification(error);
                });

            } }
        >
            <Localize wrap>MENUS.Button_Text_Repost</Localize>
        </Button>
    )
}

export default PostCardMenuDuplicate;