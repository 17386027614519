import React from 'react';
import { useQuery } from "@apollo/react-hooks";

import { GET_USER } from "graphql/query/user-gql";

import { ContainerWrap, FollowWrap } from "../../wraps";
import { FollowersByType, FollowHeader } from "../../follow";
import { Loader } from "../../result";
import { Localize } from "../../service";


const UserFollowersPage = ({ match }) => {

    const { userLogin } = match.params;

    const {loading, data } = useQuery(GET_USER, {
        variables: { nickname : userLogin },
        fetchPolicy:"cache-and-network"
    });

    return (
        <ContainerWrap className="followers-page container-margin">
            <FollowHeader
                backLink={`/${userLogin}`}
                backLinkMobile={ `/${userLogin}` }
                title={ <Localize slug={ `@${userLogin}` }>GLOBAL.Title_Followers</Localize> }
            />
            <FollowWrap >
                { !loading ?
                    <FollowersByType
                        id={ data.user.id }
                        type={"User"}
                    /> :
                    <Loader />
                }
            </FollowWrap>
        </ContainerWrap>
    );
};

export default UserFollowersPage;