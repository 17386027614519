import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Button, Form, Input, Radio } from "antd";

import { UPDATE_POST_DATA } from "graphql/mutation/post-gql";
import { GET_ME } from "graphql/query/user-gql";

import PostRemoveButton from "../../post-remove-button";
import Mention from "components/mentions";
import {ElementVisibility, Localize} from "components/service";
import { errorNotification, successNotification } from "components/result";

import './post-update-data-form.css';



const handleUpdate = (props) => {

    const {
        onUpdatePost,
        changeLoadingState,
        redirect
    } = props;

    onUpdatePost({
        variables : {
            post_id: props.id,
            input: {
                title: props.title,
                description: props.description,
                media_layout: props.media_layout,
                status: props.status,
                type: props.type
            }
        },
        update : (cache, { data }) => {

            const {
                updatePost : {
                    label,
                    message,
                    post
                }
            } = data;

            successNotification({
                title: label,
                description: message,
            });

            changeLoadingState();
            redirect(post);
        }
    }).catch((error) => {
        changeLoadingState();
        errorNotification(error);
    });
};

const formButton = (props) => {

    const {
        onUpdatePost,
        changeLoadingState,
        redirect
    } = props;

    return (
        <Button
            loading={ props.loading }
            className="rounded-button"
            disabled={ props.disabled }
            onClick={
                () => {

                    changeLoadingState( true );

                    handleUpdate({
                        id: props.id,
                        title: props.form.getFieldValue('post-title'),
                        description: props.form.getFieldValue('post-description'),
                        type: props.form.getFieldValue('post-type') ?? "post",
                        media_layout: "square",
                        status: props.status,
                        onUpdatePost,
                        changeLoadingState: () => {
                            changeLoadingState(false)
                        },
                        redirect
                    });
                }
            }
        >
            { props.buttonText }
        </Button>
    );

};

const NormalPostDataForm = (props) => {

    const {
        form,
        post,
        history,
    } = props;

    const { getFieldDecorator  } = form;

    const [updatePost, { loading : updateLoading}] = useMutation(UPDATE_POST_DATA);

    const client = useApolloClient();
    const { me } = client.readQuery({query: GET_ME});

    const [ loadingState, setLoadingState ] = useState(
    { save : false, publish : false }
    );

    const SaveDraftButton = () => formButton({
        form,
        buttonText : <Localize wrap>GLOBAL.Button_Text_SaveDraft</Localize>,
        loading : updateLoading && loadingState.save,
        disabled : !post.media.length,
        status : 'draft',
        onUpdatePost: updatePost,
        id: post.id,
        changeLoadingState : (state) => {
            setLoadingState( { ...loadingState, save: state } )
        },
        redirect : () => history.push(`/${post.author.nickname}/drafts`)
    });

    const PublishButton = () => formButton({
        form,
        buttonText : post.status === 'public' ?
            <Localize wrap>GLOBAL.Button_Text_Save</Localize> :
            <Localize wrap>GLOBAL.Button_Text_Publish</Localize>,
        loading : updateLoading && loadingState.publish,
        disabled : !post.media.length,
        status : 'public',
        onUpdatePost: updatePost,
        id: post.id,
        changeLoadingState : (state) => {
            setLoadingState( { ...loadingState, publish: state } )
        },
        redirect : ({order_slug}) => {
            if(post.status === 'public'){
                history.push(`/post/${order_slug}`)
            } else {
                history.push(`/${post.author.nickname}`)
            }
        }
    });

    const formItemLayout ={
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <div className="post-update-details">
            <Localize wrap="h4">POST.Title_PostDetails</Localize>
            <Form
                className="post-create-form">
                {(me.role === "admin" || me.role === "moderator") &&
                    <Form.Item>
                        {getFieldDecorator('post-type', {
                            initialValue: post.type || "post",
                        })(
                            <Radio.Group>
                                <Radio value="post">
                                    <Localize>POST.Type_Post</Localize>
                                </Radio>
                                <Radio value="news">
                                    <Localize>POST.Type_News</Localize>
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                }
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_Title</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('post-title', {
                        initialValue: post.title,
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_Description</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('post-description', {
                        initialValue: post.description || "",
                    })(
                        <Mention rows="7"/>
                    )}
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <ElementVisibility maxWidth={ 768 } type="hide" comparison="<">
                            <div>
                                <PostRemoveButton
                                    postId={post.id}
                                    myNickname={ post.author.nickname }
                                />
                            </div>
                        </ElementVisibility>
                        <div>
                            { post.status !== 'public' &&
                                <SaveDraftButton />
                            }
                            { <PublishButton /> }
                        </div>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

const PostUpdateDataForm = Form.create({ name: 'post_update_data' })(NormalPostDataForm);

export default PostUpdateDataForm;