import React from 'react';
import { Icon } from 'antd';

const RankingSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M18.3,22.2c-0.1,0-0.2,0-0.3-0.1L12,19l-6,3.1c-0.3,0.1-0.6,0.1-0.8-0.1c-0.2-0.2-0.3-0.5-0.3-0.7L6,14.7L1.2,10
	C1,9.8,1,9.5,1,9.2C1.1,9,1.4,8.8,1.6,8.7l6.7-1l3-6c0.3-0.5,1.1-0.5,1.3,0l3,6l6.7,1c0.3,0,0.5,0.2,0.6,0.5c0.1,0.3,0,0.6-0.2,0.8
	l-4.8,4.7l1.1,6.6c0,0.3-0.1,0.6-0.3,0.7C18.6,22.2,18.4,22.2,18.3,22.2z M12,17.4c0.1,0,0.2,0,0.3,0.1l5,2.6l-0.9-5.5
	c0-0.2,0-0.5,0.2-0.7l4-3.9L15,9.2c-0.2,0-0.5-0.2-0.6-0.4l-2.5-5l-2.5,5C9.4,9,9.2,9.2,8.9,9.2L3.4,10l4,3.9
	c0.2,0.2,0.3,0.4,0.2,0.7l-0.9,5.5l5-2.6C11.7,17.4,11.8,17.4,12,17.4z"/>
    </svg>
);

const RankingIcon = props => <Icon component={RankingSvg} {...props} />;

export default RankingIcon;