const initials = (str, divider = ",") => {

    let resultStr = "";

    if(str.indexOf(divider, 0) !== -1) {

        resultStr += str.split(divider).map( (item) => {
            return item.trim()[0];
        });

    } else if(str.indexOf(" ", 0) !== -1) {
        resultStr = initials( str, " " );
    } else {
        resultStr = str;
    }

    return resultStr.replace(",", "").slice(0, 2);
};

export default initials;