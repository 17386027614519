import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "antd";

import { FOLLOW_HASHTAG } from "graphql/mutation/follow-gql"

import { errorNotification, successNotification } from "../../result";


const followHashtag = (props) => {

    const {
        hashtag_id,
        onFollowClick,
        onUpdateAction
    } = props;

    onFollowClick({
        variables: {
            hashtag_id,
        },
        update : (cache, { data }) => {

            const {
                followHashtag: {
                    label,
                    message,
                    hashtag: {
                        me_following
                    }
                }
            } = data;

            onUpdateAction(me_following);

            successNotification({
                title: label,
                description: message,
            });

        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const HashtagFollowButton = (props) => {

    const {
        id,
        meFollowing,
        buttonClass = "",
  //      buttonType,
        onUpdateAction = () => {},
        children
    } = props;

    const [ onFollowClick, { loading : followLoading } ] = useMutation(FOLLOW_HASHTAG);

    //const allButtonClass = `${buttonClass} ${!meFollowing ? 'follow' : '' }`;

    return(
        <Button
            type={ !meFollowing ? "primary" : null }
            loading={ followLoading }
            className={ buttonClass }
            onClick={ () => {
                followHashtag({
                    hashtag_id: id,
                    onFollowClick,
                    onUpdateAction
                })
            }}
        >
            { children }
        </Button>
    )
};

export default HashtagFollowButton;