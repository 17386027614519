import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { NOTIFICATIONS_COUNT } from "graphql/query/notification-gql";

import NotificationDrawer from "../notification-drawer";
import Icons from "components/icons";
import { Localize } from "components/service";

import './notification-button.css';


const NotificationButton = (props) => {

    const { cssClass, userId } = props;

    const [showDrawer, setShowDrawer] = useState(false);

    const { data, refetch } = useQuery(NOTIFICATIONS_COUNT, {
        variables: {
            user_id: userId,
            view: false
        },
        fetchPolicy:"network-only",
        pollInterval: 30000
    });

    const {
        userNotifications : {
            pageInfo: {
                total
            } = {}
        } = {}
    } = data !== undefined ? data : {};

    return (
        <>
            <button
                title={ Localize({children: "MENUS.Label_Notifications" }).props.children }
                className={ `notification-button ${cssClass} ${showDrawer ? 'active' : null }`}
                onClick={ () => setShowDrawer(true) }
            >
                { data !== undefined && total > 0 &&
                    <span className="notification-badge company-bg-color" />
                }
                <Icons.Notification />
            </button>
            <NotificationDrawer
                showDrawer={showDrawer}
                setShowDrawer={ setShowDrawer }
                refetchNotificationsView={ () => refetch() }
                userId={userId}
            />
        </>
    );
};

export default NotificationButton;