import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Radio } from "antd";

import { SEARCH_COLLECTIONS } from 'graphql/query/search-gql';

import { CollectionList } from 'components/collections';
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";


const SearchCollections = (props) => {

    const { query, onlyQuery, sortBy = 'CREATED_AT' } = props;
    const [ sortField, setSortField ] = useState( sortBy );

    const {loading, data, fetchMore} = useQuery(SEARCH_COLLECTIONS, {
        variables: {
            text : query,
            orderBy: [{
                column: sortField,
                order: "DESC"
            }]
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        searchCollections : {
            pageInfo,
            edges : collections
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        if( !loading && pageInfo.hasNextPage){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const searchCollections = fetchMoreResult.searchCollections;

                    const newSearchResults = {
                        searchCollections: {
                            edges : [
                                ...collections,
                                ...searchCollections.edges
                            ],
                            __typename: searchCollections.__typename,
                            pageInfo : searchCollections.pageInfo
                        }
                    };

                    return searchCollections.edges.length ? newSearchResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    if(onlyQuery){
        return {loading, data, fetchMoreAction};
    }

    return(
        <>
            { data !== undefined && pageInfo.total > 0 &&
            <>
                <div className="search-sort-and-view border-color-light">
                    <div className="search-sort">
                        <Radio.Group
                            onChange={
                                (e) => { setSortField(e.target.value) }
                            }
                            defaultValue={ sortField }
                        >
                            <Radio.Button value="CREATED_AT">
                                <Localize>SORT.Label_Newest</Localize>
                            </Radio.Button>
                            <Radio.Button value="COUNT_FOLLOWERS">
                                <Localize>SORT.Label_Popular</Localize>
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
                <CollectionList
                    collections={ collections }
                    view="row"
                    withAuthor
                    showCounters
                    showFollow
                />
                { pageInfo.hasNextPage &&
                    <LoadMoreOnScroll
                        fetchAction={() => fetchMoreAction()}
                        loading={ false }
                    />
                }
            </>
            }
            { !loading && data !== undefined && pageInfo.total === 0  &&
                <div className="search-result-not-found">
                    <Localize br={ <br /> } wrap="h3">NO_DATA.Text_Search_Collections</Localize>
                </div>
            }
            {  loading && <Loader /> }
        </>
    );
};

export default SearchCollections;