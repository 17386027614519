import React from "react";
import { Col, Row } from "antd";

import { ChangePasswordForm } from "../../forms";
import { UserTopBar } from "components/user";
import {ElementVisibility, Localize} from "components/service";


const ProfileTabChangePassword = ({ me }) => {

    return (
        <div>
            <ElementVisibility
                maxWidth={ 768 }
                type="show"
                comparison="<"
            >
                <UserTopBar
                    itsMe
                    title={ <Localize wrap>GLOBAL.Title_ChangePassword</Localize> }
                    backLink={ `/${me.nickname}` }
                />
            </ElementVisibility>
            <Row type="flex" className="profile-row">
                <Col span={24}>
                    <ChangePasswordForm />
                </Col>
            </Row>
        </div>
    );
};

export default ProfileTabChangePassword;