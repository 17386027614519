import { gql } from "apollo-boost";

import { postThumbnailFragment, postFieldsData } from "./post-frgm";
import { userFieldsShort } from "./user-frgm";

export const collectionAuthorFragment = gql`
    fragment collectionAuthorFragment on Collection {
        author{
            ...userFieldsShort
            me_following
            itsMe
        }
    }
    ${ userFieldsShort }
`;

export const collectionPostActionFields = gql`
    fragment collectionPostActionFields on Collection {
        id
        postInCollection(post_id : $post_id)
        posts(first : 1){
            pageInfo{
                total
            }
            edges{
                node{
                    ...postFieldsData
                    inCollection
                    ...postThumbnailFragment
                }
            }    
        }
    }
    ${ postFieldsData }
    ${ postThumbnailFragment }
`;

export const collectionFieldsData = gql`
    fragment collectionFieldsData on Collection {
        id
        title
        slug
        description
        cover_url   
    }
`;

export const collectionFields = gql`
    fragment collectionFields on Collection {
        ...collectionFieldsData
        status
        me_following
        count_posts
        count_followers
        created_at
        updated_at        
    }
    ${ collectionFieldsData }
`;

export const collectionPostsFields = gql`
    fragment collectionPostsFields on CollectionPost {
        id
        post_id
        collection_id
        created_at
        updated_at       
    }
`;