import { useEffect } from "react";


const ChatMessagesResizeScrollHelper = ({ element, scrollElement, messages }) => {

    useEffect(() => {

        const emptySpace = scrollElement.offsetHeight - element.offsetHeight;
        const scrollOriginal = scrollElement.offsetHeight + scrollElement.scrollTop;

        if(element.style.marginTop !== "0px"){
            if(emptySpace <= 0){
                element.style.marginTop = "0px";

                scrollElement.style.overflow = "auto";
                //scrollElement.style.display = "block";
            }
        }

        /*
        if(element.style.marginTop !== "0px"){
            if(emptySpace >= 0){
                element.style.marginTop = `${emptySpace}px`;
            } else {

                element.style.marginTop = "0px";

                scrollElement.style.overflow = "auto";
                scrollElement.scrollTo(0, scrollElement.scrollHeight);
            }
        }*/

        if(element.offsetHeight - scrollOriginal < 300){
            scrollElement.scroll( {
                top: scrollElement.scrollHeight,
                behavior: 'smooth'
            });
        }

    }, [element, scrollElement, messages]);


    return null;
};

export default ChatMessagesResizeScrollHelper;