import { gql } from "apollo-boost";


export const mediaThumbnailFields = gql`
    fragment mediaThumbnailFields on Media {
        id
        thumbnail
    }
`;

export const mediaFields = gql`
    fragment mediaFields on Media {
        id
        name
        title
        thumbnail
        path
        extension
        alt
        description
        order
    }
`;

export const mediaFieldsImageSizes = gql`
    fragment mediaFieldsImageSizes on Media {
        image_sizes{
            thumbnail
            original
            square
            crop_landscape
            crop_square
            crop_portrait
        }
    }
`;

export const mediaFragment = gql`
    fragment mediaFragment on Post {
        media{
            ...mediaFields
        }
    }
    ${ mediaFields }
`;

export const mediaFragmentFull = gql`
    fragment mediaFragmentFull on Post {
        media{
            ...mediaFields
            ...mediaFieldsImageSizes
        }
    }
    ${ mediaFields }
    ${ mediaFieldsImageSizes }
`;