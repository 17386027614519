import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_USER_LIKED_POST } from 'graphql/query/follow-gql';

import FollowList from "./follow-list";
import { LoadMoreOnScroll } from "../../service";
import { errorNotification, Loader } from "../../result";
import { getHolderElement } from "utils";


const FollowListLikeContainer = (props) => {

    const {
        postId,
        myId,
        elementClass,
        scrollElementClass
    } = props;

    const { loading, data, fetchMore } = useQuery(GET_USER_LIKED_POST, {
        variables: {
            post_id : postId
        },
        fetchPolicy: "cache-and-network"
    });

    const {
        listUsersLikedPost : {
            edges : likes,
            __typename : typename,
            pageInfo
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {
        if( !loading ){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newEdges = fetchMoreResult.listUsersLikedPost.edges;
                    const pageInfo = fetchMoreResult.listUsersLikedPost.pageInfo;

                    const newLikes = {
                        listUsersLikedPost: {
                            edges : [
                                ...likes,
                                ...newEdges
                            ],
                            __typename: typename,
                            pageInfo
                        }
                    };

                    return newEdges.length ? newLikes : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    const resultHolder = getHolderElement(elementClass, scrollElementClass);

    return (
        <div className="follow-list-wrap">
        { data !== undefined &&
            <>
                <FollowList
                    items={ likes }
                    myId={ myId }
                />
                { pageInfo.hasNextPage && resultHolder.element !== null &&
                    <LoadMoreOnScroll
                        fetchAction={() => fetchMoreAction()}
                        { ...resultHolder }
                    />
                }
            </>
        }
        { loading && <Loader /> }
        </div>
    );
};

export default FollowListLikeContainer;