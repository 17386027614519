import React from 'react';
import { Icon } from 'antd';


const ChatSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M25.5,13.5L3.4,4.2C3.2,4.1,2.9,4.1,2.7,4.3C2.5,4.5,2.4,4.8,2.4,5L5.9,24c0,0.2,0.2,0.5,0.5,0.6c0.1,0,0.2,0.1,0.3,0.1
	            c0.1,0,0.3,0,0.4-0.1l4.8-3.3l3,3.4c0.1,0.2,0.4,0.3,0.6,0.3c0.1,0,0.2,0,0.2,0c0.3-0.1,0.5-0.4,0.5-0.7l0.4-6.1l8.8-3.1
	            c0.3-0.1,0.5-0.4,0.5-0.7C26,13.9,25.8,13.6,25.5,13.5z M4.3,7.1l10.5,10.3l-7.6,5.2L4.3,7.1z M14.9,22.2l-1.7-1.9l1.9-1.3
	            L14.9,22.2z M16.1,16.6L6.5,7.1l16.7,7L16.1,16.6z"
        />
    </svg>
);

const ChatMenuSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <g>
            <path d="M19.7,22.2c-0.2,0-0.3-0.1-0.5-0.2L15.3,19h-3.3c-0.8,0-1.6-0.2-2.4-0.6c-0.4-0.2-0.5-0.7-0.3-1c0.2-0.4,0.7-0.5,1-0.3
			c0.5,0.3,1,0.4,1.6,0.4h3.6c0.2,0,0.3,0.1,0.5,0.2L19,20l0.1-2c0-0.3,0.2-0.6,0.5-0.7c1.3-0.4,2.2-1.7,2.2-3.1v-2.9
			c0-1.8-1.5-3.2-3.2-3.2c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8c2.6,0,4.7,2.1,4.7,4.7v2.9c0,1.9-1.1,3.5-2.8,4.3l-0.2,3
			c0,0.3-0.2,0.5-0.4,0.6C19.9,22.2,19.8,22.2,19.7,22.2z"/>
        </g>
        <g>
            <g>
                <path d="M4.6,19.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.4-0.4-0.4-0.6l-0.2-3C2,14.6,0.9,13,0.9,11.1V8.2c0-2.6,2.1-4.7,4.7-4.7h6.6
				c2.6,0,4.7,2.1,4.7,4.7v2.9c0,2.6-2.1,4.7-4.7,4.7H9l-3.9,3.1C5,19,4.8,19.1,4.6,19.1z M5.7,4.9c-1.8,0-3.2,1.5-3.2,3.2v2.9
				c0,1.4,0.9,2.6,2.2,3.1c0.3,0.1,0.5,0.4,0.5,0.7l0.1,2l2.9-2.3c0.1-0.1,0.3-0.2,0.5-0.2h3.6c1.8,0,3.2-1.5,3.2-3.2V8.2
				c0-1.8-1.5-3.2-3.2-3.2H5.7z"/>
            </g>
        </g>
    </svg>
);

const ChatNewSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M23.6,4.8L20,2.1c-0.2-0.1-0.4-0.2-0.6-0.1c-0.2,0-0.4,0.1-0.5,0.3L9.1,15.1c-0.1,0.1-0.1,0.2-0.1,0.4l-0.6,4.4
		        c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1l4.2-1.7c0.1-0.1,0.2-0.1,0.3-0.2l9.9-12.9
		        C24,5.5,23.9,5,23.6,4.8z M19.7,3.7l2.4,1.8l-0.8,1l-2.4-1.8L19.7,3.7z M13.4,16.8L11,15L18,6l2.4,1.8L13.4,16.8z M10.3,16.4l2,1.5
		        L10,18.8L10.3,16.4z"
        />
        <path d="M6,19.1H0.8c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8H6c0.4,0,0.8-0.3,0.8-0.8S6.4,19.1,6,19.1z"/>
        <path d="M7,13.5H0.8c-0.4,0-0.8,0.3-0.8,0.8S0.3,15,0.8,15H7c0.4,0,0.8-0.3,0.8-0.8S7.5,13.5,7,13.5z"/>
        <path d="M0.8,9.4h9.2c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H0.8C0.3,7.9,0,8.2,0,8.6S0.3,9.4,0.8,9.4z"/>
    </svg>
);

const ChatIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    if(props.type === "new"){
        return <Icon component={ ChatNewSvg }/>;
    }

    if(props.type === "menu"){
        return <Icon component={ ChatMenuSvg }/>;
    }

    return <Icon component={ ChatSvg }/>;
};


export default ChatIcon;