import React from 'react';
import { useQuery } from "@apollo/react-hooks";

import { GET_HASHTAG } from "graphql/query/hashtag-gql";

import { ContainerWrap, FollowWrap } from "../../wraps";
import { FollowersByType, FollowHeader } from "../../follow";
import { Loader } from "../../result";
import { Localize } from "../../service";


const HashtagFollowersPage = ({ match }) => {

    const { tagSlug } = match.params;

    const {loading, data } = useQuery(GET_HASHTAG, {
        variables: { slug : tagSlug },
        fetchPolicy:"cache-and-network"
    });

    return (
        <ContainerWrap className="followers-page container-margin">
            <FollowHeader
                backLink={`/tag/${tagSlug}`}
                title={ <Localize slug={ `#${tagSlug} ` }>GLOBAL.Title_Followers</Localize> }
            />
            <FollowWrap>
                { !loading ?
                    <FollowersByType
                        id={ data.hashtag.id }
                        type={"Hashtag"}
                    /> :
                    <Loader />
                }
            </FollowWrap>
        </ContainerWrap>
    );
};

export default HashtagFollowersPage;