import { useSubscription } from "@apollo/react-hooks";

import { CHAT_CREATED_SUBSCRIPTION } from "graphql/subscription/chat-gql";
import { SEARCH_CHATS } from "graphql/query/chat-gql";


const useChatCreatedSubscription = () => {

    useSubscription(CHAT_CREATED_SUBSCRIPTION, {
        onSubscriptionData: ( { client, subscriptionData : { data } }  ) => {

            if( !data.chatCreated ){

                return null;
            }

            const { chatCreated : chat } = data;

            let updateChat  = false;

            const { chatSearch : {
                    pageInfo,
                    edges : chats
                }
            } = client.readQuery( {
                query: SEARCH_CHATS,
                variables: {
                    filter: ""
                }
            });

            const updatedChats = chats.map( ( { node } ) => {

                if(node.id === chat.id){
                    updateChat = true;

                    return { "node" : chat, __typename: "ChatEdge" };
                }

                return { "node" : node, __typename: "ChatEdge" };

            });

            client.writeQuery({
                query : SEARCH_CHATS,
                variables: {
                    filter: ""
                },
                data:{
                    chatSearch : {
                        pageInfo: {
                            total : pageInfo.total + 1,
                            ...pageInfo
                        },
                        edges : updateChat ? updatedChats : [
                            {
                                node : chat,
                                __typename: "ChatEdge"
                            },
                            ...chats
                        ],
                        __typename: "ChatConnection"
                    },
                }
            });

        }
    });

};

export default useChatCreatedSubscription;
