import React, {useState} from "react";
import {Button, message, Modal} from "antd";

import { FollowListLikeContainer } from "../../follow/follow-list";
import Icons from "../../icons";
import { Localize } from "components/service";


const CardLikeAmount = (props) => {

    const { likeCount } = props;
    const [ showPopup, setShowPopup ] = useState(false);

    let warningMessage = Localize({children: "NO_DATA.Text_Likes" });

    return(
        <span className="like-amount">
            <Button
                onClick={
                    () => {

                        likeCount < 1 ?
                            message.warning( warningMessage ) :
                            setShowPopup(true)
                    }
                }
            >
                <Localize likeCount={ likeCount } >
                    { likeCount > 1 ?
                        "POST.Button_Text_Likes":
                        "POST.Button_Text_Like"
                    }
                </Localize>
            </Button>
            <Modal
                title={
                    <Localize wrap="h3">POST.Modal_Title_Likes</Localize>
                }
                closeIcon={
                    <Icons.Close />
                }
                destroyOnClose={ true }
                footer={null}
                visible={ showPopup }
                closable={ true }
                onCancel={ () => setShowPopup(false) }
                wrapClassName="modal-actions likes-modal"
                centered
            >
                <FollowListLikeContainer
                    { ...props }
                    elementClass={ '.likes-modal .follow-list-wrap' }
                    scrollElementClass={ '.likes-modal .ant-modal-body' }
                />
            </Modal>
        </span>
    );
};

export default CardLikeAmount;