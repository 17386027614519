import React, {useState} from 'react';

import SearchForm from "./search-form";
import SearchByType from "./search-by-type";

import './search.css';


const SearchBlock = () => {

    const [searchVariables, setSearchVariables] = useState({ type: 'post', input: '' });

    return (
        <div className="search-holder invert-search-color">
            <SearchForm
                setSearchVariables={ setSearchVariables }
                searchVariables={ searchVariables }
            />
            <SearchByType
                type={ searchVariables.type }
                query={ searchVariables.input }
            />
        </div>
    );
};

export default SearchBlock;