import React from 'react';
import { Icon } from 'antd';

const AddToCollectionSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 26 26">
        <path d="M24.2,6.2H12.6l-2.3-3.1c-0.1-0.2-0.4-0.3-0.6-0.3H1.9c-0.4,0-0.8,0.4-0.8,0.8v18.1c0,0.4,0.4,0.8,0.8,0.8
		         h22.3c0.4,0,0.8-0.4,0.8-0.8V7C25,6.6,24.6,6.2,24.2,6.2z M23.4,20.9H2.7V4.4h6.6l2.3,3.1c0.1,0.2,0.4,0.3,0.6,0.3h11.2V20.9z"/>
        <path d="M9.4,13.3c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h2.9v2.9c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-2.9
		         h2.9c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-2.9v-2.9c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8v2.9H9.4z"/>
    </svg>
);

const AddToCollectionActiveSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 26 26">
        <path d="M24.2,6.2H12.6l-2.3-3.1c-0.1-0.2-0.4-0.3-0.6-0.3H1.9c-0.4,0-0.8,0.4-0.8,0.8v18.1c0,0.4,0.4,0.8,0.8,0.8h22.3
                 c0.4,0,0.8-0.4,0.8-0.8V7C25,6.6,24.6,6.2,24.2,6.2z M17.4,12.3l-4.5,4.5c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2l-2.5-2.5
                 c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l2,2l4-4c0.3-0.3,0.8-0.3,1.1,0S17.7,12,17.4,12.3z"
        />
    </svg>
);

const CollectionSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path  d="M25.2,7.2H13.6l-2.3-3.1c-0.1-0.2-0.4-0.3-0.6-0.3H2.9c-0.4,0-0.8,0.4-0.8,0.8v18.1c0,0.4,0.4,0.8,0.8,0.8
		          h22.3c0.4,0,0.8-0.4,0.8-0.8V8C26,7.6,25.6,7.2,25.2,7.2z M24.4,21.9H3.7V5.4h6.6l2.3,3.1c0.1,0.2,0.4,0.3,0.6,0.3h11.2V21.9z"/>
    </svg>
);

const CollectionSmlSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 10 10">
        <polygon  points="4.7,3 3.8,1.7 1,1.7 1,3 1,3.6 1,8.3 9,8.3 9,3 "/>
    </svg>
);


const CollectionIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    if( props.type === "small" ){
        return <Icon component={ CollectionSmlSvg } />;
    }

    if(props.type === "Add"){
        return <Icon component={ props.active ? AddToCollectionActiveSvg : AddToCollectionSvg} />;
    }

    return <Icon component={ CollectionSvg } />;
};


export default CollectionIcon;