import React from "react";

import TopAppBar from "../../menu-bar/top-app-bar";
import UserHeaderPopup from "../user-header-popup";

import "./user-top-bar.css"


const UserTopBar = (props) => {

    const {
        loading,
        itsMe,
        title,
        backLink,
        leftSide,
    } = props;

    if(loading){
        return null;
    }

    return (
        <TopAppBar
            className="user-top-bar"
            backLink={ backLink }
            leftSide={ leftSide }
            title={ <h3>{title}</h3> }
            rightSide={
                <>
                    { itsMe && <UserHeaderPopup/> }
                </>
            }
        />
    );
};

export default UserTopBar;