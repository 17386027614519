import React from 'react';
import { Icon } from 'antd';

const SearchSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 28 28">
        <path d="M23.2,22.7L17.7,17c0,0,0,0-0.1,0c2.7-3,2.6-7.8-0.2-10.7C16,4.8,14.1,4,12.1,4c-2,0-3.9,0.8-5.4,2.3
	             c-2.9,3-2.9,8,0,11c1.4,1.5,3.3,2.3,5.4,2.3c1.6,0,3.2-0.5,4.5-1.5c0,0,0,0.1,0.1,0.1l5.5,5.7c0.1,0.2,0.3,0.2,0.5,0.2
	             c0.2,0,0.4-0.1,0.5-0.2C23.5,23.5,23.5,23,23.2,22.7z M12.1,18c-1.6,0-3.1-0.6-4.3-1.8c-2.4-2.5-2.4-6.4,0-8.9
	             c1.1-1.2,2.7-1.8,4.3-1.8c1.6,0,3.1,0.6,4.3,1.8c2.4,2.5,2.4,6.4,0,8.9C15.2,17.4,13.7,18,12.1,18z"
        />
    </svg>
);
const SearchIcon = props => <Icon component={SearchSvg} {...props} />;

export default SearchIcon;