import React from "react";

import AvatarPhoto from "../avatar-photo";

const AvatarCustom = (props) => {

    const {
        holderClass = "",
        dataClass = "",
        avatarClass = "",
        children
    } = props;

    return (
        <div className={ `avatar ${holderClass}` }>
            <div className={`avatar-photo ${avatarClass}`}>
                <AvatarPhoto {...props} />
            </div>
            <div className={`avatar-data ${dataClass}`}>
                { children }
            </div>
        </div>
    );
};

export default AvatarCustom;
