import React from 'react';
import { Icon } from 'antd';

const ContactSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M23.2,4.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
	c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0c0,0,0,0,0,0H1.7c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1
	c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1C1,3.8,1,3.9,1,3.9c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1v16.1c0,0.4,0.3,0.8,0.8,0.8
	h20.8c0.4,0,0.8-0.3,0.8-0.8L23.2,4.1C23.2,4.2,23.2,4.1,23.2,4.1z M20.4,4.9l-8.3,6.9L3.7,4.9H20.4z M2.4,19.5V5.8l9.2,7.6
	c0.1,0.1,0.3,0.2,0.5,0.2s0.3-0.1,0.5-0.2l9.2-7.6v13.7H2.4z"/>
    </svg>
);

const ContactIcon = props => <Icon component={ContactSvg} {...props} />;

export default ContactIcon;