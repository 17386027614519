import CardListDashboard from "./card-list-dashboard";
import CardListDraftGrid from "./card-list-draft-grid";
import CardListActionGrid from "./card-list-action-grid";
import CardListHashtagPreview from './card-list-hashtag-preview';
import CardListCollectionPreview from './card-list-collection-preview';
import CardListPreview from "./card-list-preview";
import CardListTimeline from "./card-list-timeline";

const ListType = {
    Preview : CardListPreview,
    Timeline : CardListTimeline,
    Dashboard : CardListDashboard,
    DraftGrid : CardListDraftGrid,
    ActionGrid  : CardListActionGrid,
    HashtagPreview : CardListHashtagPreview,
    CollectionPreview : CardListCollectionPreview,
};

export default ListType;