import { chatHooks } from "components/chat";

const useSubscriptions = () => {

    const { Subscription } = chatHooks;

    Subscription.useChatCreated();
    Subscription.useMessageCreated();
    Subscription.useMessageUpdated();

}

export default useSubscriptions;