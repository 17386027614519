

const getHolderElement = (elementClass, scrollElementClass) => {

    if(elementClass && scrollElementClass){
        return {
            element: document.querySelector(elementClass),
            scrollElement: document.querySelector(scrollElementClass)
        }
    }

    if(elementClass){
        return {
            element: document.querySelector(elementClass),
        }
    }

    if(scrollElementClass){
        return {
            scrollElement: document.querySelector(scrollElementClass)
        }
    }

    return {};

};

export default getHolderElement;