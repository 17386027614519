import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import CollectionFollowButton from "../collection-follow-button";
import { copyLink } from "utils";
import { Localize } from "components/service";
import PostCardMenuDuplicate from "../../posts/post-card-menu/post-card-menu-duplicate";


const CollectionCardMenu = (props) => {

    const {
        relation : {
            slug,
            relationship_id
        },
        me,
        post,
        itsMe = me.id === post.author.id,
        hidePopup,
    } = props;

    const [ meFollowing, setMeFollowing ] = useState( !!props.relation );

    return (
        <ul className="modal-actions-list company-link-hover">
            <li>
                <CollectionFollowButton
                    buttonClass={ meFollowing ? 'bold-text' : ''}
                    id={ relationship_id }
                    meFollowing={ meFollowing }
                    onUpdateAction={ setMeFollowing }
                >
                    <Localize wrap>
                        { meFollowing ?
                            "FOLLOW.Button_Text_UnfollowCollection":
                            "FOLLOW.Button_Text_FollowCollection"
                        }
                    </Localize>
                </CollectionFollowButton>
            </li>
            <li>
                <Button onClick={ () => copyLink(`/post/${post.order_slug.replace(/[=]/g, '')}`) }>
                    <Localize wrap>MENUS.Button_Text_CopyLink</Localize>
                </Button>
            </li>
            <li>
                <Link to={`/collection/${ slug }`}>
                    <Localize wrap>MENUS.Link_Text_GoCollection</Localize>
                </Link>
            </li>
            { !itsMe && me.role !== "user" &&
                <li>
                    <PostCardMenuDuplicate
                        post_id={ post.id }
                    />
                </li>
            }
            <li>
                <button onClick={ hidePopup }>
                    <Localize wrap>MENUS.Button_Text_Cancel</Localize>
                </button>
            </li>
        </ul>
    );
};

export default CollectionCardMenu;