import React from 'react';
import { Icon } from 'antd';


const AttachFileSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M9.3,22.3c-1.2,0-2.5-0.4-3.6-1.1c-1.4-1-2.3-2.4-2.6-4c-0.3-1.7,0.1-3.3,1-4.7l5.5-8c0.2-0.3,0.7-0.4,1-0.2s0.4,0.7,0.2,1
	            l-5.5,8c-0.7,1.1-1,2.3-0.8,3.6c0.2,1.3,0.9,2.3,2,3.1c2.2,1.5,5.2,0.9,6.7-1.2l6.3-9.2c0.5-0.7,0.7-1.6,0.5-2.5s-0.7-1.7-1.4-2.2
	            c-0.7-0.5-1.6-0.7-2.5-0.5C15.3,4.5,14.6,5,14,5.7l-5.8,8.4c-0.6,0.8-0.4,2,0.5,2.5c0.8,0.6,2,0.4,2.5-0.5l5-7.2
	            c0.2-0.3,0.7-0.4,1-0.2s0.4,0.7,0.2,1l-5,7.2c-1,1.5-3.1,1.9-4.6,0.9c-0.7-0.5-1.2-1.3-1.4-2.1s0-1.8,0.5-2.5l5.8-8.4
	            c0.7-1.1,1.9-1.8,3.1-2c1.3-0.2,2.6,0,3.7,0.8c1.1,0.7,1.8,1.9,2,3.1c0.2,1.3,0,2.6-0.8,3.7l-6.3,9.2C13.3,21.4,11.3,22.3,9.3,22.3z"
        />
    </svg>
);

const AttachFileIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ AttachFileSvg }/>;
};


export default AttachFileIcon;