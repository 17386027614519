import React from "react";
import {useApolloClient} from "@apollo/react-hooks";

import { GET_ME } from "graphql/query/user-gql";

import AvatarBlock, {AvatarThreeRow} from "../../avatar";
import CollectionFollowButton from "../collection-follow-button";
import Icons from "../../icons";

import './collection-card-row.css';
import {Localize} from "../../service";


const CollectionCardRow = (props) => {

    const {
        cardActions = null,
        byDefault,
        showStatus,
        showCounters,
        showFollow,
        withAuthor,
        collection : {
            id,
            title,
            slug,
            cover_url,
            status,
            posts,
            author,
            description,
            me_following,
            count_followers,
            count_posts,
        }
    } = props;

    const client = useApolloClient();
    const { me } = client.readQuery({query: GET_ME});

    let cover = null;

    if( cover_url || posts.edges.length > 0 ){
        cover = cover_url ? cover_url : posts.edges[0].node.media[0].thumbnail
    }

    return(
        <div className="collection-card-row border-color-light">
            { cardActions }
            <div className="card-data">
                { withAuthor ?
                    <AvatarThreeRow
                        shape='square'
                        size={ 44 }
                        photoUrl={ cover }
                        initials={ <Icons.NoImage /> }
                        avatarClass={"border-color-light"}
                        link={`/collection/${slug}`}
                        nickname={ author.nickname }
                        badgeIcon={ <Icons.Collection type="small" /> }
                        title={ byDefault ? <Localize>COLLECTION.Title_Default</Localize> : title }
                        text={ description }
                    /> :
                    <AvatarBlock
                        shape='square'
                        size={ 44 }
                        photoUrl={ cover }
                        initials={ <Icons.NoImage /> }
                        link={`/collection/${slug}`}
                        label={`^${slug}`}
                        text={ byDefault ? <Localize>COLLECTION.Title_Default</Localize> : title  }
                    />
                }
            </div>
            { showStatus &&
                <>
                    { status === 'private' &&
                        <div className="private site-icon"><Icons.Lock /></div>
                    }
                    { status === 'public' &&
                        <div className="public site-icon"><Icons.Public /></div>
                    }
                </>
            }
            { (showCounters || showFollow) &&
                <div className="card-info">
                    {showCounters &&
                    <div className="card-counters">
                        <div className="card-post-count site-sub-text-light-color">
                            <Icons.Post filled  />
                            <span>{ count_posts }</span>
                        </div>
                        <div className="card-follower-count site-sub-text-light-color">
                            <Icons.Followers />
                            <span>{ count_followers }</span>
                        </div>
                    </div>
                    }
                    {showFollow &&
                        <div className="card-follow">
                            { author.id !== me.id ?
                                <CollectionFollowButton
                                    id={id}
                                    meFollowing={me_following}
                                    buttonType={!me_following}
                                    buttonClass="rounded-button"
                                >
                                    <Localize wrap>
                                        { me_following ?
                                            "FOLLOW.Button_Text_Following":
                                            "FOLLOW.Button_Text_Follow"
                                        }
                                    </Localize>
                                </CollectionFollowButton> :
                                <b className="site-sub-text-light-color">
                                    <Localize>COLLECTION.Text_YourCollection</Localize>
                                </b>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default CollectionCardRow;