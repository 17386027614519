const hexEncode = (num) => {
    return num.toString(16);
};

const hexDecode = (str) => {
    return parseInt(str, 16);
};

const base64Encode = (str) => {

    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        (match, p1) => {
            return String.fromCharCode('0x' + p1);
        })
    );

};

const base64Decode = (str) => {
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
};

const encodeToSlug = (numArr) => {

    const hexNumArr = numArr.map( num => hexEncode(parseInt(num)) ),
          slugStr = hexNumArr.join('_');

    return base64Encode(slugStr);

};

const decodeFromSlug = (str) => {

    const slugStr = base64Decode(str),
          hexNumArr = slugStr.split('_');

    return hexNumArr.map( num => hexDecode(num) );
};

export const encode = {
    hex : hexEncode,
    base64 : base64Encode,
    toSlug : encodeToSlug
};

export const decode = {
    hex : hexDecode,
    base64 : base64Decode,
    fromSlug : decodeFromSlug
};

