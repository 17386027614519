import React from "react";
import moment from "moment";
import { DatePicker, Form, Input } from "antd";

import { Localize } from "components/service";


const NormalDealerInformationForm = (props) => {

    const {
        form,
        dealer = {},
        sellerNumber,
        dateEntrance
    } = props;

    const { getFieldDecorator } = form;

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    return (
        <Form
            className="profile-form"
            layout="horizontal"
        >
            <div className="grid-two-col">
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_SellerNumber</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('seller_number', {
                        initialValue: sellerNumber,

                    })(
                        <Input name="seller_number" disabled />
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_DateEntrance</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator(
                        'date_entrance_at',
                        {
                            initialValue: dateEntrance && moment(dateEntrance, "YYYY-MM-DD")
                        }
                    )(<DatePicker format="DD.MM.YYYY" disabled />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_CompanyName</Localize>
                        </span>
                }
                    {...formItemLayout}
                >
                    {getFieldDecorator('company_name', {
                            rules: [{ required: true, message: <Localize>FORM_RULES.Required_CompanyName</Localize> }],
                            initialValue: dealer.company_name,

                        })(
                        <Input name="company_name" disabled />
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_DealerNumber</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('dealer_number', {
                        rules: [{ required: true, message: <Localize>FORM_RULES.Required_DealerNumber</Localize> }],
                        initialValue: dealer.dealer_number
                    })(
                        <Input name="dealer_number" disabled />
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_City</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('city', {
                        initialValue: dealer.city
                    })(
                        <Input name="city" disabled />
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_ZipCode</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('zip_code', {
                        initialValue: dealer.zip_code
                    })(
                        <Input name="zip_code" disabled />
                    )}
                </Form.Item>
            </div>

            <Form.Item
                label={
                    <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_Street</Localize>
                        </span>
                }
                {...formItemLayout}
            >
                {getFieldDecorator('street', {
                    initialValue: dealer.street
                })(
                    <Input name="street" disabled />
                )}
            </Form.Item>

            <div className="grid-two-col">
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_Region</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('region', {
                        initialValue: dealer.region
                    })(
                        <Input name="region" disabled />
                    )}
                </Form.Item>
                <Form.Item
                    label={
                        <span className="site-label-text-color">
                            <Localize>FORMS.Input_Label_District</Localize>
                        </span>
                    }
                    {...formItemLayout}
                >
                    {getFieldDecorator('district', {
                        initialValue: dealer.district
                    })(
                        <Input name="district" disabled />
                    )}
                </Form.Item>
            </div>
        </Form>
    );
};

const DealerInformationContainer = (props) => {

    const { me } = props;

    return (
        <NormalDealerInformationForm
            {...props}
            nickname = { me.nickname }
            sellerNumber = { me.seller_number }
            dateEntrance = { me.date_entrance_at }
            dealer   = { me.dealer }
        />
    );
};

const DealerInformationForm = Form.create({ name: 'dealer_info' })(DealerInformationContainer);

export default DealerInformationForm;