import React from "react";
import {Button} from "antd";

import { copyLink } from "utils";
import { Localize } from "components/service";

const PostCardMenuCopyLink = ({ link }) => {
    return (
        <Button onClick={ () => copyLink(link) }>
            <Localize wrap>MENUS.Button_Text_CopyLink</Localize>
        </Button>
    );
};

export default PostCardMenuCopyLink;