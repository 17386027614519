import React from 'react';
import {useApolloClient} from "@apollo/react-hooks";
import {Link} from "react-router-dom";
import {Avatar} from "antd";

import { GET_ME } from 'graphql/query/user-gql';

import { LinkedMention } from "../../service";
import CommentDeleteButton from "../comment-delete-button";
import CommentLikeButton  from "../comment-like-button";

import './comments-list.css';


const CommentsList = (props) => {

    const {
        comments = {},
        amount,
        postId,
        withTime = true,
        withAvatar = false,
        withActions = false,
        withSimpleActions = false
    } = props;

    const client = useApolloClient();
    const { me } = client.readQuery({query: GET_ME});

    if(!comments.length){
        return null;
    }

    const commentsToShow = (amount) ? comments.slice(0, amount) : comments;

    const RenderItems = commentsToShow.map(({ node }) => {

        const {id, author, content, liked, like_count, created_at} = node;

        return (
            <li key={id} className={ `comment ${withAvatar ? 'with-avatar' : ""}` }>
                {withAvatar &&
                    <Link to={`/${ author.nickname }`}>
                        <Avatar
                            src={author.avatar}
                            size={ 40 }
                        >
                            { author.initials }
                        </Avatar>
                    </Link>
                }
                <div className="comment-description">
                    <p>
                        <Link to={`/${ author.nickname }`} className="nick inner-link">{author.nickname}</Link>
                        <LinkedMention searchSigns={ ['@', '^', '#'] }>
                            {content}
                        </LinkedMention>
                    </p>
                    { withTime && <p className="comment-date">{ created_at }</p> }
                </div>
                { (withActions || withSimpleActions) &&
                    <div className="comment-actions">
                        { me.id === author.id ?
                            <>
                                { !withSimpleActions &&
                                    <CommentDeleteButton
                                        id = { id }
                                        postId = { postId }
                                    />
                                }
                            </> :
                            <div className="comment-like-wrap">
                                <CommentLikeButton
                                    commentId = { id }
                                    liked = { liked }
                                />
                                { !withSimpleActions &&
                                    <p className="count">{ like_count }</p>
                                }
                            </div>
                        }
                    </div>
                }
            </li>
        );
    });

    return (
        <ul className="comments-list">
            { RenderItems }
        </ul>
    );
};

export default CommentsList;