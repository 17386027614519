import React from "react";

import AvatarBlock from "components/avatar";


const UserAvatar = ({ user, labelPrefix = "" }) => {

    return(
        <AvatarBlock
            photoUrl={ user.avatar }
            blocked={ user.status === "block" ? "normal" : null }
            initials={ user.initials }
            link={`/${ user.nickname }`}
            label={`${ labelPrefix }${ user.nickname }`}
            text={  user.status === "block"  ? "Inactive user" : user.name  }
            size={50}
        />
    );
};

export default UserAvatar;