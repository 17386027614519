import React from 'react';
import { useParams } from "react-router-dom";

import { ChatsBlock, ChatMessagesBlock } from "components/chat";
import { ContainerWrap, ChatWrap, WrapColumn } from "components/wraps";
import {
    useMessageCreatedSubscription,
    useChatCreatedSubscription,
    useMessageUpdatedSubscription
} from "components/chat/hooks";
import Icons from "components/icons";
import { Localize } from "components/service";


const ChatPage = () => {

    useMessageCreatedSubscription();
    useMessageUpdatedSubscription();
    useChatCreatedSubscription();


    const { chatSlug } = useParams();

    return (
        <ContainerWrap className="chat container-margin">
            <ChatWrap className={ `chat-wrap ${chatSlug ? "chat-open" : ""}` }>
                <WrapColumn position="left">
                    <ChatsBlock />
                </WrapColumn>
                <WrapColumn position="right">
                    <div className="chat-messages-block-wrap">
                    { chatSlug !== undefined ?
                        <ChatMessagesBlock /> :
                        <div className="empty-chat-wrap">
                            <Icons.NoContent type="chatSelected"/>
                            <Localize wrap="p" br={ <br /> }>NO_DATA.Select_Chat</Localize>
                        </div>
                    }
                    </div>
                </WrapColumn>
            </ChatWrap>
        </ContainerWrap>
    );
};

export default ChatPage;