import React from 'react';
import { Col, Row } from "antd";

import { useColumnsChildren } from "components/use-hooks";

import "./chat-wrap.css";


const ChatWrap = ({children, className = null}) => {

    const { leftColumn, rightColumn } = useColumnsChildren(children);

    const columns = {
        left: {
            xs: 24, sm: 24, md: 9, lg: 10, xl: 9, xxl:8, span: 8
        },
        right: {
            xs: 24, sm: 24, md: 15, lg: 14, xl: 15, xxl:16, span: 16
        }
    }

    return (
        <Row className={className} type="flex" gutter={ 0 } justify="center">
            <Col { ...columns.left } >
                { leftColumn }
            </Col>
            <Col { ...columns.right } >
                { rightColumn }
            </Col>
        </Row>
    );
};

export default ChatWrap;