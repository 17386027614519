import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "antd";

import { FOLLOW_COLLECTION } from "graphql/mutation/follow-gql";

import { errorNotification, successNotification } from "../../result";


const followCollection = (props) => {

    const {
        collection_id,
        onFollowClick,
        onUpdateAction
    } = props;

    onFollowClick({
        variables: {
            collection_id,
        },
        update : (cache, { data }) => {

            const {
                followCollection: {
                    label,
                    message,
                    collection: {
                        me_following
                    }
                }
            } = data;

            onUpdateAction(me_following);

            successNotification({
                title: label,
                description: message,
            });

        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const CollectionFollowButton = (props) => {

    const {
        id,
        meFollowing,
        buttonClass = "",
        //buttonType,
        onUpdateAction = () => {},
        children
    } = props;

    const [ onFollowClick, { loading : followLoading } ] = useMutation(FOLLOW_COLLECTION);

    //const allButtonClass = `${buttonClass} ${!meFollowing ? 'follow' : '' }`;

    return(
        <Button
            type={ !meFollowing ? "primary" : null }
            loading={ followLoading }
            className={ buttonClass }
            onClick={ () => {
                followCollection({
                    collection_id: id,
                    onFollowClick,
                    onUpdateAction
                })
            }}
        >
            { children }
        </Button>
    )
};

export default CollectionFollowButton;