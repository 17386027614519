import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_POST_COMMENTS } from "graphql/query/comment-gql";

import CommentForm from "./comment-form";
import CommentsList from "./comments-list";
import { LoadMoreOnScroll } from "../service";
import { errorNotification, Loader } from "../result";
import { getHolderElement } from "utils";


const Comments = ( { postId, elementClass, scrollElementClass, withActions } ) => {

    const {loading, data, fetchMore} = useQuery(GET_POST_COMMENTS, {
        variables: {post_id: postId},
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
    });

    const {
        post: {
            comments
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        if (!loading && comments.pageInfo.hasNextPage) {

            fetchMore({
                variables: {
                    endCursor: comments.pageInfo.endCursor
                },
                updateQuery: (previousResult, {fetchMoreResult}) => {

                    const newComments = fetchMoreResult.post.comments;

                    const edges = [
                        ...comments.edges,
                        ...newComments.edges
                    ];

                    const uniqueEdges = edges.filter(
                        (ele, ind) => ind === edges.findIndex(elem => elem.node.id === ele.node.id));

                    const commentsResults = {
                        post: {
                            ...data.post,
                            comments: {
                                ...newComments,
                                edges: uniqueEdges
                            }
                        }
                    };

                    return newComments.edges.length ? commentsResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    const resultHolder = getHolderElement(elementClass, scrollElementClass);

    return(
        <>
            <CommentForm
              postId={ postId }
              lastCommentId={
                  data !== undefined && comments.pageInfo.total > 0 ?
                      comments.edges[0].node.id : ""
              }
              type="modal"
              mentionPosition="after"
            />
            <div className="comments-list-wrap">
              {data !== undefined && comments.pageInfo.total > 0 &&
                  <>
                      <CommentsList
                          comments={ comments.edges }
                          withActions={withActions}
                          withAvatar={ true }
                          postId={postId}
                      />
                      { comments.pageInfo.hasNextPage && !loading &&
                          <LoadMoreOnScroll
                              fetchAction={() => fetchMoreAction()}
                              loading={ false }
                              {...resultHolder}
                          />
                      }
                  </>
              }
              { loading && <Loader /> }
            </div>
        </>
    );
};

export default Comments;