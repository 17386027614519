import React from 'react';
import {Col} from "antd";
import { encode } from "../../../../utils";

import { CardPreview, CardPreviewRow} from "../../../card";


const CardListHashtagPreview  = (props) => {

    const {
        node : {
            id,
            hashtag_id,
            post_id,
            post
        },
        prefixPath,
        showAuthor,
        view
    } = props;

    const slug = encode.toSlug([id, hashtag_id, post_id]).replace(/[=]/g, '');

    if( view === 'row'){
        return (
            <Col span={ 24 } className="card-preview-row-holder">
                <CardPreviewRow
                    post={ post }
                    showAuthor={ showAuthor }
                    fullPath={ `${prefixPath}/${slug}` }
                />
            </Col>
        );
    }

    return (
        <Col span={8} className="card-preview-holder">
            <CardPreview
                post={ post }
                fullPath={ `${prefixPath}/${slug}` }
                counters
            />
        </Col>
    );
};


export default CardListHashtagPreview;