const russian = {
    'COLLECTION.Button_Create' : 'Новая коллекция',
    'COLLECTION.Modal_Title_Create' : 'Создание коллекции',
    'COLLECTION.Title_EditCollection' : 'Редактировать коллекцию',
    'COLLECTION.Type_Private' : 'приватная',
    'COLLECTION.Type_Public' : 'публичная',
    'COLLECTION.Text_YourDefault' : 'Ваша приватная коллекция по умолчанию',
    'COLLECTION.Link_Text_Edit' : 'Редактировать коллекцию',
    'COLLECTION.Modal_Title_Remove' : 'Удаление коллекции',
    'COLLECTION.Button_Delete' : 'Удалить коллекцию',
    'COLLECTION.Text_ModalDelete_Question' : 'Вы уверены что вы хотите удалить данную коллекцию?',
    'COLLECTION.Title_Default' : 'Коллекция по умолчанию',
    'COLLECTION.Text_PostCount' : '{ postCount } пост',
    'COLLECTION.Text_YourCollection' : 'Ваша коллекция',
    'COLLECTION.Modal_Title_AddPost' : 'Добавить в коллекцию',
    'FOLLOW.Button_Text_Follow' : 'Подписаться',
    'FOLLOW.Button_Text_Unfollow' : 'Отписаться',
    'FOLLOW.Button_Text_Following' : 'Подписан',
    'FOLLOW.Button_Text_FollowCollection' : 'Подписаться на коллекцию',
    'FOLLOW.Button_Text_UnfollowCollection' : 'Отписаться от коллекции',
    'FOLLOW.Button_Text_FollowHashtag' : 'Подписаться на хештег',
    'FOLLOW.Button_Text_UnfollowHashtag' : 'Отписаться от хештега',
    'FOLLOW.Type_Label_Collections' : '{ icon } Коллекции',
    'FOLLOW.Type_Label_Users' : '{ icon } Пользователи',
    'FOLLOW.Type_Label_Hashtags' : '{ icon } Хештеги',
    'FORM_RULES.Required_Username' : 'Укажите имя пользователя!',
    'FORM_RULES.Required_Password' : 'Пожалуйста укажите пароль!',
    'FORM_RULES.Email' : 'Введите действительный адрес электронной почты.',
    'FORM_RULES.Required_Email' : 'Укажите ваш адрес электронной почты',
    'FORM_RULES.Min_FullName' : 'ФИО должно содержать минимум { minLength } символов',
    'FORM_RULES.Required_FullName' : 'Пожалуйста укажите ваше ФИО',
    'FORM_RULES.Min_Username' : 'Минимальная длинна имени пользователя { minLength } символов',
    'FORM_RULES.RegExp_Username' : 'Может содержать только маленькие буквы, "-", "_", "."',
    'FORM_RULES.Min_Password' : 'Минимальная длинна пароля { minLength } символов',
    'FORM_RULES.Required_NewPassword' : 'Пожалуйста укажите новый пароль',
    'FORM_RULES.Required_Gender' : 'Пожалуйста выберите ваш пол',
    'FORM_RULES.Required_Language' : 'Пожалуйста выберите язык',
    'FORM_RULES.ContactPhone' : 'Может содержать только цифры, "+", "-", " "',
    'FORM_RULES.Required_CurrentPassword' : 'Укажите ваш текущий пароль',
    'FORM_RULES.Required_ConfirmPassword' : 'Повторите пароль еще раз',
    'FORM_RULES.Required_PasswordInconsistent' : 'Пароли которые вы ввели не совпадают',
    'FORM_RULES.Text_UploadImage' : 'Загрузите фото высокого качеста до { size } МБ.{ br }Больше фото вы можете добавить на этапе редактирования поста.',
    'FORM_RULES.Attachment_Size' : 'Загрузите любые файлы менее { size } МБ каждый',
    'FORM_RULES.Required_CollectionSlug' : 'Пожалуйста укажите слаг коллекции',
    'FORM_RULES.Min_CollectionSlug' : 'Слаг должен быть минимум  { minLength } символа',
    'FORM_RULES.RegExp_CollectionSlug' : 'Может содержать только маленькие буквы, цифры, "-", "_"',
    'FORMS.Title_Login' : 'Авторизация',
    'FORMS.Input_Label_Username' : 'Имя пользовател',
    'FORMS.Input_Label_Password' : 'Пароль',
    'FORMS.Button_Text_Login' : 'Войти',
    'FORMS.Text_Registration_Question' : 'Новый пользователь?',
    'FORMS.Link_Text_Registration' : 'Зарегистрироваться',
    'FORMS.Link_Text_ForgotPassword' : 'Забыли пароль?',
    'FORMS.Link_Text_ForgotPassword2' : 'Отправить',
    'FORMS.Link_Text_Login' : 'Войти',
    'FORMS.Title_Registration' : 'Регистрация',
    'FORMS.Input_Label_Email' : 'Адрес электронной почты',
    'FORMS.Input_Label_FullName' : 'ФИО',
    'FORMS.Button_Text_Register' : 'Зарегистрироваться',
    'FORMS.Text_Login_Question' : 'Вы уже зарегистрированы?',
    'FORMS.Button_Text_ForgotPassword' : 'Отправить ссылку',
    'FORMS.Title_ForgotPassword' : 'Восстановление пароля',
    'FORMS.Success_Text_ForgotPassword' : 'Мы отправили вам ссылку для восстановления пароля!',
    'FORMS.Input_Label_NewPassword' : 'Новый пароль',
    'FORMS.Title_ChangePassword' : 'Смена пароля',
    'FORMS.Text_ChangePassword_Question' : 'Уже вспомнили ваш пароль?',
    'FORMS.Text_ForgotPassword_Question' : 'Отправить новый запрос?',
    'FORMS.Loading_Text_ChangePassword' : 'Пожалуйста подождите мы проверяем ваш токен',
    'FORMS.Select_OptionLabel_Male' : 'Мужской',
    'FORMS.Select_OptionLabel_Female' : 'Женский',
    'FORMS.Select_Label_Gender' : 'Пол',
    'FORMS.Select_Label_Language' : 'Язык',
    'FORMS.Textarea_Label_About' : 'Коротко о вас',
    'FORMS.Input_Placeholder_FullName' : 'Введите ваше ФИО',
    'FORMS.Input_Placeholder_Username' : 'Укажите ваше имя пользователя',
    'FORMS.Input_Placeholder_Email' : 'Укажите ваш Емейл',
    'FORMS.Input_Placeholder_Gender' : 'Выберите ваш пол',
    'FORMS.Input_Placeholder_Language' : 'Выберите язык интерфейса',
    'FORMS.Input_Placeholder_About' : 'Расскажите что нибудь о себе',
    'FORMS.Input_Placeholder_ContactEmail' : 'Введите ваш контактный адрес электронной почты',
    'FORMS.Input_Placeholder_ContactPhone' : 'Введите ваш контактный телефон',
    'FORMS.Input_Label_ContactPhone' : 'Контактный телефон',
    'FORMS.Input_Label_ContactEmail' : 'Контактный адрес электронной почты',
    'FORMS.Button_Text_ChangePassword' : 'Изменить пароль',
    'FORMS.Input_Placeholder_CurrentPassword' : 'Введите ваш текущий пароль',
    'FORMS.Input_Placeholder_NewPassword' : 'Введите ваш новый пароль',
    'FORMS.Input_Placeholder_ConfirmPassword' : 'Повторите новый пароль',
    'FORMS.Input_Label_CurrentPassword' : 'Текущий пароль',
    'FORMS.Input_Label_ConfirmPassword' : 'Повторите еще раз',
    'FORMS.Input_Label_Title' : 'Заголовок',
    'FORMS.Input_Label_Description' : 'Описание',
    'FORMS.Input_Label_CollectionName' : 'Название Коллекции',
    'FORMS.Upload_Label_Cover' : 'Обложка',
    'FORMS.Input_Label_CollectionUniqueName' : 'Уникальное название коллекции',
    'FORMS.Checkbox_Label_CollectionPublic' : 'Сделать коллекцию публичной',
    'FORMS.Input_Placeholder_Slug' : 'Уникальное навание для УРЛ',
    'GLOBAL.Button_Text_Confirm' : 'Подтвердить',
    'GLOBAL.Button_Text_Yes' : 'Да',
    'GLOBAL.Button_Text_No' : 'Нет',
    'GLOBAL.Text_InactiveUser' : 'Заблокированный пользователь',
    'GLOBAL.Button_Text_Cancel' : 'Отменить',
    'GLOBAL.Text_ItsYou' : 'Это Вы',
    'GLOBAL.Link_Text_EditProfile' : 'Редактировать профиль',
    'GLOBAL.Title_EditProfile' : 'Редактирование провиля',
    'GLOBAL.Button_Text_ChangePhoto' : 'Изменить фото',
    'GLOBAL.Button_Text_Save' : 'Сохранить',
    'GLOBAL.Title_AdditionalInfo' : 'Дополнительная информация',
    'GLOBAL.Title_ChangePassword' : 'Изменение пароля',
    'GLOBAL.Button_Text_Next' : 'Дальше',
    'GLOBAL.Button_Text_Publish' : 'Опубликовать',
    'GLOBAL.Button_Text_SaveDraft' : 'Сохранить черновик',
    'GLOBAL.Button_Text_Done' : 'Закончить',
    'GLOBAL.Button_Text_Create' : 'Создать',
    'GLOBAL.Button_Text_ChangeCover' : 'Изменить обложку',
    'GLOBAL.Link_Text_OnError' : 'Перейти в ленту новостей',
    'GLOBAL.Title_Followers' : '{ slug } подписчики',
    'GLOBAL.Title_Following' : '{ slug } подписки',
    'GLOBAL.Link_Text_OnDraftError' : 'Перейти в черновики',
    'GLOBAL.Text_SystemError' : 'Извините, что-то пошло не так. Пожалуйста, попробуйте перезагрузить страницу или свяжитесь с администратором.',
    'GLOBAL.Link_Text_SystemError' : 'Вернутся на домашнюю страницу',
    'GLOBAL.Text_Loading' : 'Загрузка...',
    'GLOBAL.Button_Text_More' : 'еще',
    'MENUS.Label_News' : 'Новости',
    'MENUS.Label_MyTimeline' : 'Мой таймлайн',
    'MENUS.Label_Search' : 'Поиск',
    'MENUS.Label_Profile' : 'Профиль',
    'MENUS.Label_NewPost' : 'Создать пост',
    'MENUS.Button_Text_CopyLink' : 'Копировать ссылку',
    'MENUS.Button_Text_Cancel' : 'Закрыть',
    'MENUS.Label_EditPost' : 'Редактировать пост',
    'MENUS.Label_DeletePost' : 'Удалить пост',
    'MENUS.Link_Text_GoCollection' : 'Перейти в коллекцию',
    'MENUS.Link_Text_GoHashtag' : 'Перейти в хештег',
    'MENUS.Label_Posts' : 'Посты',
    'MENUS.Label_DraftPosts' : 'Черновики',
    'MENUS.Label_Collections' : 'Коллекции',
    'MENUS.Label_Documents' : 'Документы',
    'MENUS.Label_Users' : 'Пользователи',
    'MENUS.Label_Hashtags' : 'Хештеги',
    'MENUS.Label_Following' : 'Подписки',
    'MENUS.Label_Followers' : 'Подписчики',
    'MENUS.Label_EditProfile' : 'Редактировать профиль',
    'MENUS.Label_AdditionalInfo' : 'Дополнительная информация',
    'MENUS.Label_ChangePassword' : 'Изменить пароль',
    'MENUS.Button_Text_Logout' : 'Выход',
    'MENUS.Label_Settings' : 'Настройки',
    'MENUS.Label_EditCollection' : 'Редактировать коллекцию',
    'MENUS.Label_Notifications' : 'Уведомления',
    'NO_DATA.Text_Timeline' : 'Нет постов в вашей ленте новостей',
    'NO_DATA.Link_Text_TimelineCreatePost' : 'Создать первый пост?',
    'NO_DATA.Link_Text_TimelineSearch' : 'Найти что нибудь для подписки?',
    'NO_DATA.Text_Likes' : 'Никому не нравится этот Пост.',
    'NO_DATA.Text_Search_Posts' : 'Мы не смогли найти Постов{ br }соответствующих вашему запросу.',
    'NO_DATA.Text_Search_Users' : 'Мы не смогли найти Пользователей{ br }соответствующих вашему запросу.',
    'NO_DATA.Text_Search_Hashtags' : 'Мы не смогли найти Хештегов{ br }соответствующих вашему запросу.',
    'NO_DATA.Text_Search_Collections' : 'Мы не смогли найти Коллекций{ br }соответствующих вашему запросу.',
    'NO_DATA.Text_Search_Documents' : 'Мы не смогли найти Документов{ br }соответствующих вашему запросу.',
    'NO_DATA.Text_Notifications_All' : 'У вас нет уведомлений',
    'NO_DATA.Text_Notifications_Unread' : 'У вас нет непрочитанных уведомлений',
    'NO_DATA.Link_Text_UserCreatePost' : 'Создать новый пост?',
    'NO_DATA.Text_UserPosts' : 'У { who } еще нет постов',
    'NO_DATA.Text_UserDraftPosts' : 'У вас нет черновиков',
    'NO_DATA.Text_UserCollections' : 'У { who } еще нет коллекций',
    'NO_DATA.Text_UserDocuments' : 'У { who } еще нет документов',
    'NO_DATA.Text_NewsTimeline' : 'Еще нет никаких новостей',
    'NO_DATA.Text_Who_User' : 'Пользователя',
    'NO_DATA.Text_Who_You' : 'Вас',
    'NO_DATA.Text_UserFollowers' : 'У { who } еще нет подписчиков',
    'NO_DATA.Text_HashtagFollowers' : 'На этот хештег еще никто не подписался',
    'NO_DATA.Text_CollectionFollowers' : 'На это коллекцию еще никто не подписался',
    'NO_DATA.Text_HashtagFollow' : 'У { who } еще нет подписки на Хештеги',
    'NO_DATA.Text_CollectionFollow' : 'У { who } еще нет подписки на Коллекции',
    'NO_DATA.Text_UserFollow' : 'У { who } еще нет подписки на Пользователей',
    'NO_DATA.Text_HashtagPosts' : 'В этом хештеге пока что нет постов',
    'NO_DATA.Text_CollectionPosts' : 'В этой коллекции пока что нет постов',
    'NO_DATA.Text_Search' : 'Мы не смогли найти что нибудь{ br }соответствующее вашему запросу.',
    'NOTIFICATIONS.Drawer_Title' : 'Уведомления',
    'NOTIFICATIONS.Filter_Label_All' : 'Все',
    'NOTIFICATIONS.Filter_Label_Unread' : 'Не прочитанные',
    'POST.Text_ModalDelete_Question' : 'Вы уверены что вы хотите удалить данный пост?',
    'POST.Button_Text_DeletePost' : 'Удалить пост',
    'POST.Modal_Title_Delete' : 'Удаление поста',
    'POST.Modal_Title_Share' : 'Поделиться постом',
    'POST.Button_Text_Share' : 'Поделиться',
    'POST.Button_Text_Shared' : 'Поделились',
    'POST.Button_Text_Like' : '{ likeCount } отметка "Нравится"',
    'POST.Button_Text_Likes' : '{ likeCount } отметок "Нравится"',
    'POST.Modal_Title_Likes' : 'Нравится пользователям',
    'POST.Button_Text_ViewComments' : 'Посмотреть все { commentCount } комментариев',
    'POST.Drawer_Title_Comments' : 'Комментарии',
    'POST.Modal_Title_Attachments' : 'Вложенные файлы',
    'POST.Button_Text_Attachment' : '{ attachmentCount } вложение',
    'POST.Button_Text_Attachments' : '{ attachmentCount } вложений',
    'POST.Input_Placeholder_Comments' : 'Добавить комментарий...',
    'POST.Link_Text_ViewPost' : 'Посмотреть пост',
    'POST.Link_Text_DownloadAttachment' : 'Скачать',
    'POST.Title_ChooseImage' : 'Выбрать изображение',
    'POST.Text_AddImage' : 'Добавить изображение',
    'POST.Type_Post' : 'Пост',
    'POST.Type_News' : 'Новости',
    'POST.Title_PostDetails' : 'Текст поста',
    'POST.Title_CreatePost' : 'Создание поста',
    'POST.Title_EditPost' : 'Редактирование поста',
    'POST.Drawer_Title_Attachments': 'Вложения',
    'POST.Drawer_Title_ManageImages' : 'Управление изображениями',
    'POST.Title_AttachDocuments': 'Добавить файлы',
    'POST.Title_ManageImages' : 'Управление изображениями',
    'POST.Dragger_Text_Attach' : 'Добавить файлы',
    'POST.Attachment_Count' : 'Вложения ({ attachmentCount })',
    'POST.Images_Count' : 'Изображения ({ imagesCount })',
    'POST.Avatar_NewsTitle' : 'НОВОСТЬ',
    'SEARCH.Input_Placeholder_User' : 'Введите ФИО или имя пользователя',
    'SEARCH.Input_Placeholder' : 'Начать искать...',
    'SEARCH.Type_Label_News' : 'Новости',
    'SEARCH.Type_Label_Posts' : 'Посты',
    'SEARCH.Type_Label_Users' : 'Пользователи',
    'SEARCH.Type_Label_Hashtags' : 'Хештеги',
    'SEARCH.Type_Label_Collections' : 'Коллекции',
    'SEARCH.Type_Label_Documents' : 'Документы',
    'SORT.Label_Newest' : 'Новейшие',
    'SORT.Label_Popular' : 'Популярные',
    'SORT.Label_MostUsed' : 'Самые используемые',
    'SORT.Label_AtoZ' : 'От А до Я',
    'SUMMARY_COUNTERS.Label_KNYS' : 'ГДЗН',
    'SUMMARY_COUNTERS.Label_Users' : 'Пользователи',
    'SUMMARY_COUNTERS.Label_Posts' : 'Посты',
    'SUMMARY_COUNTERS.Label_Images' : 'Изображения',
    'SUMMARY_COUNTERS.Label_Videos' : 'Видео',
    'SUMMARY_COUNTERS.Label_Documents' : 'Документы',
    'SUMMARY_COUNTERS.Label_Comments' : 'Комментарии',
    'SUMMARY_COUNTERS.Label_Collections' : 'Коллекции',
    'SUMMARY_COUNTERS.Label_Teams' : 'Команды',
    'SUMMARY_COUNTERS.Label_Hashtags' : 'Хештеги',
    'MENUS.Label_MyProfile' : 'Мой профиль',
    'FORMS.Input_Label_JobDescription' : 'Должность',
    'FORMS.Input_Placeholder_JobDescription' : 'Введите вашу должность',
    'GLOBAL.Modal_Title_Menu' : 'Меню',

};

export default russian;