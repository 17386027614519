import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_USER_FOLLOWING } from "graphql/query/follow-gql";

import { FollowWrap } from "components/wraps";
import FollowList from "../../follow-list";
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";
import Icons from "components/icons";


const FollowingUsersTab = ({ me, user, itsMe, currentTab }) => {

    const { loading, data, fetchMore } = useQuery(GET_USER_FOLLOWING, {
        variables: {
            user_id : user.id
        },
        fetchPolicy: "cache-and-network"
    });

    if(!currentTab) return null;

    const {
        userFollowing : {
            pageInfo,
            edges = [],
            __typename : typename,
        } = {}
    } = data !== undefined ? data  : {} ;

    const fetchMoreAction = () => {
        if(!loading && data !== undefined){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newEdges = fetchMoreResult.userFollowing.edges;
                    const pageInfo = fetchMoreResult.userFollowing.pageInfo;

                    const newFollowing = {
                        userFollowing: {
                            edges : [
                                ...edges,
                                ...newEdges
                            ],
                            __typename: typename,
                            pageInfo
                        }
                    };

                    return newEdges.length ? newFollowing : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    const nodes = edges.map(
        ({ node }) => {
            return {
                node : {
                    id: node.id,
                    author: node
                }
            }
        }
    );

    return (
        <div className="following-tab" >
            <FollowWrap>
                { data !== undefined && nodes.length > 0 &&
                    <>
                        <FollowList
                            items={nodes}
                            myId={me.id}
                        />
                        { pageInfo.hasNextPage &&
                            <LoadMoreOnScroll
                                fetchAction={() => fetchMoreAction()}
                                loading={false}
                            />
                        }
                    </>
                }
                { data !== undefined && !nodes.length &&
                    <div className="following-tabs-message">
                        <div className="no-content-icon site-icon-color-second">
                            <Icons.NoContent type="userFollowing" />
                        </div>
                        <Localize
                            wrap="p"
                            who={ itsMe ?
                                <Localize>NO_DATA.Text_Who_You</Localize> :
                                <Localize>NO_DATA.Text_Who_User</Localize>
                            }
                        >
                            NO_DATA.Text_UserFollow
                        </Localize>
                    </div>
                }
                { loading && <Loader /> }
            </FollowWrap>
        </div>
    );
};

export default FollowingUsersTab;