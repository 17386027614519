import { errorNotification } from "components/result";

const chatMessagesFetchMoreHelper = ({
        direction,
        loading,
        lastMessageId,
        firstMessageId,
        hasMoreMessages,
        setHasMoreMessages,
        messagesPerPage,
        fetchMore,
        chatMessages
    }) => {

    if(!loading && hasMoreMessages[direction]){

        let lastItem,
            currentOffset,
            scrollWrap = document.querySelector(".chat-message-list-wrap");

        if(direction === 'prev'){
            lastItem = document.querySelector(`#message-in-chat-${ lastMessageId }`);
            currentOffset = lastItem?.offsetTop - scrollWrap.offsetTop;
        }

        fetchMore({
            variables: {
                id : direction === 'next' ? firstMessageId : lastMessageId,
                paginate:  direction
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {

                const newMessagesList = fetchMoreResult.chatMessages;

                const newMessages = direction === 'next' ? [
                    ...newMessagesList,
                    ...chatMessages,
                ] : [
                    ...chatMessages,
                    ...newMessagesList,
                ];

                if(newMessagesList.length < messagesPerPage){
                    setHasMoreMessages({ ...hasMoreMessages, [direction] : false } );
                }

                return newMessages.length ? { chatMessages : [...newMessages]} : previousResult;

            }
        }).then( () => {
            if(direction === 'prev'){
                scrollWrap.scrollTo(0, lastItem.offsetTop - (currentOffset + 100));
            }
        }).catch((error) => {
            errorNotification(error);
        });
    }
};

export default chatMessagesFetchMoreHelper;