import React, {useState} from "react";
import { useQuery } from "@apollo/react-hooks";
import { Radio } from "antd";

import { GET_COLLECTION_POSTS } from "graphql/query/collection-gql";

import { CardList } from "../../card";
import {LoadMoreOnScroll, Localize} from "../../service";
import { errorNotification, Loader } from "../../result";
import Icons from "../../icons";


const normalizedArr = (postsArr) => {

    if(postsArr.length === 0){
        return {
            posts : [],
            lastPostId : false
        };
    }

    const posts = postsArr.map(item => {
        return {node: {...item}}
    });

    const lastPostId = posts[posts.length - 1].node.id;

    return {
        posts,
        lastPostId
    };

};

const CollectionPosts = (props) => {

    const {
        collectionId,
        collectionSlug,
        postsPerPage = 12
    } = props;

    const { loading, data, fetchMore } = useQuery(GET_COLLECTION_POSTS, {
        variables: {
            collection_id : collectionId,
            defaultCount: postsPerPage
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const [ hasMorePosts, setHasMorePosts ] = useState( true );
    const [ postsView, setPostsView ] = useState("grid");

    const { posts, lastPostId } = normalizedArr(
        data !== undefined ?
                data.collectionPosts : []
    );

    const fetchMoreAction = (direction) => {
        if(!loading && hasMorePosts && posts.length >= postsPerPage){

            fetchMore({
                variables: {
                    id : lastPostId,
                    paginate:  direction
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newPostList = fetchMoreResult.collectionPosts;

                    const newPosts = [
                        ...data.collectionPosts,
                        ...newPostList,
                    ];

                    if(newPostList.length < postsPerPage){
                        setHasMorePosts(false);
                    }

                    return newPosts.length ? { collectionPosts : [...newPosts]} : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <div className="collection-posts-list">
            { data !== undefined &&
            <>
                { posts.length > 0 ?
                    <>
                        <div className="collection-posts-actions">
                            <div className="posts-view-type">
                                <Radio.Group
                                    onChange={ (e) => { setPostsView(e.target.value) }}
                                    defaultValue={ postsView }
                                    className="input-bg-color"
                                >
                                    <Radio.Button value="grid"><Icons.View type="grid" /></Radio.Button>
                                    <Radio.Button value="row"><Icons.View type="list" /></Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                        <CardList
                            type="collectionPreview"
                            cardItems={ posts }
                            view={postsView}
                            path={ `/collection/${collectionSlug}` }
                        />
                        {posts.length >= postsPerPage &&
                            <LoadMoreOnScroll
                                fetchAction={() => fetchMoreAction('next')}
                                loading={loading}
                            />
                        }
                    </>:
                    <div className="empty-collection">
                        <div className="no-content-icon site-icon-color-second">
                            <Icons.NoContent type="posts" />
                        </div>
                        <Localize wrap="p">NO_DATA.Text_CollectionPosts</Localize>
                    </div>
                }
            </>
            }
            { loading && data === undefined &&
                <Loader />
            }
        </div>
    );
};

export default CollectionPosts;