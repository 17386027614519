import React, {useState} from "react";

import AvatarBlock from "../../avatar";
import { HashtagFollowButton } from "../../hashtag";
import Icons from "../../icons";

import './follow-hashtag-list.css';



const FollowHashtagItem = ({ item }) => {

    const [ meFollowing, setMeFollowing ] = useState( item.me_following );

    return (
        <li className="follow-hashtag-item">
            <AvatarBlock
                photoUrl={item.cover_url}
                initials={ <Icons.NoImage /> }
                link={`/tag/${item.slug}`}
                label={`#${item.slug}`}
                size={ 44 }
                shape="square"
                badgeIcon={ <Icons.Hashtag type="small" /> }
            />
            <HashtagFollowButton
                id={ item.id }
                meFollowing={ meFollowing }
                buttonType={ !meFollowing }
                buttonClass="rounded-button"
                onUpdateAction={ setMeFollowing }
            >
                { meFollowing ? 'Following' : 'Follow' }
            </HashtagFollowButton>
        </li>
    );
};

const FollowHashtagList = ({ items }) => {

    if(!items.length){
        return null;
    }

    const FollowHashtagItems = items.map(({ node }) => {
        return <FollowHashtagItem
            key={node.id}
            item={ node }
        />
    });

    return (
        <ul className="follow-hashtag-list">
            { FollowHashtagItems }
        </ul>
    );
};

export default FollowHashtagList;