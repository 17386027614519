import React from 'react';
import { ContainerWrap } from "../../wraps";
import SearchBlock from "../../search";

const SearchPage = () => {

    return (
        <ContainerWrap className="container-margin container-margin-short-top">
            <SearchBlock />
        </ContainerWrap>
    );
};

export default SearchPage;