import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { NOTIFICATIONS_LIST } from "graphql/query/notification-gql";

import NotificationItems from "../notification-item";
import { LoadMoreOnScroll } from "../../service";
import { errorNotification, Loader } from "../../result";
import Icons from "../../icons";
import { getHolderElement } from "utils";
import { Localize } from "components/service";

import './notification-list.css';


const NotificationList = (props) => {

    const {
        userId,
        filterStatus,
        onClickItem,
        elementClass,
        scrollElementClass
    } = props;

    const { loading, data, fetchMore } = useQuery(NOTIFICATIONS_LIST, {
        variables: {
            user_id: userId,
            status: filterStatus === "all" ? null : false
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
    });

    const {
        userNotifications : {
            pageInfo: {
                hasNextPage,
                endCursor,
                total
            } = {},
            edges : notifications = [],
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        if(hasNextPage && !loading){

            fetchMore({
                variables: {
                    endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newResults = fetchMoreResult.userNotifications.edges;
                    const pageInfo = fetchMoreResult.userNotifications.pageInfo;

                    const newNotificationsResults = {
                        userNotifications: {
                            edges : [
                                ...notifications,
                                ...newResults
                            ],
                            __typename: pageInfo.__typename,
                            pageInfo
                        }
                    };

                    return newResults.length ? newNotificationsResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    const resultHolder = getHolderElement(elementClass, scrollElementClass);

    return(
        <ul className="notification-list">
            { data !== undefined  &&
                <>
                    <NotificationItems
                        notifications={notifications}
                        onClickItem={ onClickItem }
                    />
                    { hasNextPage &&
                        <LoadMoreOnScroll
                            fetchAction={() => fetchMoreAction()}
                            loading={ false }
                            {...resultHolder}
                        />
                    }
                </>
            }
            { !loading && total === 0 &&
                <li className="notifications-empty">
                    <div className="no-content-icon site-icon-color-second">
                        <Icons.NoContent type="notification" />
                    </div>
                    <h3 className="site-text-color">
                        <Localize>
                            { filterStatus === "all" ?
                                "NO_DATA.Text_Notifications_All" :
                                "NO_DATA.Text_Notifications_Unread"
                            }
                        </Localize>

                    </h3>
                </li>
            }
            { loading && <li><Loader /></li> }
        </ul>
    );

};

export default NotificationList;