import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_USER_HASHTAG_FOLLOWING } from "graphql/query/follow-gql";

import { FollowWrap } from "components/wraps";
import FollowHashtagList from "../../follow-hashtag-list";
import { Loader } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";


const FollowingTagsTab = ({ itsMe, user, currentTab }) => {

    const { loading, data } = useQuery(GET_USER_HASHTAG_FOLLOWING, {
        variables: {
            user_id : user.id
        },
        fetchPolicy: "cache-and-network"
    });

    if(!currentTab) return null;

    const {
        hashtagFollowing : {
            edges = []
        } = {}
    } = data !== undefined ? data  : {} ;

    return (
        <div className="following-tab" >
            <FollowWrap>
                { !loading && edges.length > 0 &&
                    <FollowHashtagList
                        items={edges}
                    />
                }
                { !loading && !edges.length &&
                    <div className="following-tabs-message">
                        <div className="no-content-icon site-icon-color-second">
                            <Icons.NoContent type="hashtagFollowing" />
                        </div>
                        <Localize
                            wrap="p"
                            who={ itsMe ?
                                <Localize>NO_DATA.Text_Who_You</Localize> :
                                <Localize>NO_DATA.Text_Who_User</Localize>
                            }
                        >
                            NO_DATA.Text_HashtagFollow
                        </Localize>
                    </div>
                }
                { loading && <Loader /> }
            </FollowWrap>
        </div>
    );
};

export default FollowingTagsTab;