import React, {useState, useEffect} from "react";
import {Carousel, Modal} from "antd";
import MagicSliderDots from 'react-magic-slider-dots';

import {Loader} from "../../result";
import Icons from "../../icons";

import './card-media-slider.css';
import 'react-magic-slider-dots/dist/magic-dots.css';


const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <Icons.ArrowRight circle />
        </button>
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <Icons.ArrowLeft circle />
        </button>
    );
};

const ViewOriginalImage = ({ originalSrc }) => {

    const [ showPopup, setShowPopup ] = useState(false);
    const [ imageLoading, setImageLoading] = useState(true);

    useEffect( () => {
        setImageLoading(true);

    }, [ originalSrc, showPopup ] );

    return(
       <div className="view-original-image company-icon-hover">
           <button
               className="site-icon"
               onClick={
                   () => setShowPopup(true)
               }
           >
               <Icons.Scale />
           </button>
           <Modal
               closeIcon={
                   <Icons.Close />
               }
               destroyOnClose={ true }
               footer={null}
               visible={ showPopup }
               closable={ true }
               onCancel={ () => {setShowPopup(false) }}
               wrapClassName="original-image-modal"
               centered
           >
               <div className={`original-image-wrap company-icon-hover ${imageLoading ? "loading" : ""}`}>
                   <img src={ originalSrc}
                        alt=""
                        onLoad={ () => setImageLoading(false) }
                   />
                   { imageLoading ?
                       <Loader /> :
                       <a href={  originalSrc }
                          download
                          className="original-image-download site-icon"
                          target="_blank"
                          rel="noopener noreferrer"
                       >
                           <Icons.Download/>
                       </a>
                   }
               </div>
           </Modal>
       </div>
    );
};

const CardMediaSlider = ({ media, postId, badge }) => {

    const [ currentImg, setCurrentImg ] = useState(null);

    const settings = {
        dots: true,
        infinite: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: () => {
            setCurrentImg(
                document.querySelector(`#card-post-${postId} .slick-current img`)
            )
        },
        appendDots: dots => {
            return(
                <MagicSliderDots
                    dots={dots}
                    numDotsToShow={5}
                    dotWidth={ 13 }
                    dotContainerClassName="magic-dots slick-dots slick-dots-bottom"
                />
            )
        }
    };

    if (!media.length) {
        return false;
    }

    const Slides = media.map(({id, path, image_sizes, name, description}) => {

        const { original } = image_sizes[0];

        return (
            <div className="card-image" key={id}>
                <img
                    src={ path }
                    alt={ name }
                    data-original-src={ original }
                    onLoad={
                        () => {
                            setCurrentImg(
                                document.querySelector(`#card-post-${postId} .slick-current img`)
                            )
                        }
                    }
                />
                { !!description && (
                    <div className="card-description-holder">
                        <span className="card-description"> { description } </span>
                    </div>
                )}
            </div>
        );
    });

    return (
        <div className="card-media-slider-wrap">
            { badge }
            <div className="card-media-slider">
                <Carousel { ...settings }>
                    { Slides }
                </Carousel>
            </div>
            <ViewOriginalImage
                originalSrc={ currentImg !== null &&
                    currentImg.dataset.originalSrc
                }
            />
        </div>
    );
};

export default CardMediaSlider;