import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/react-hooks";

import { GET_USER } from "graphql/query/user-gql";

import { WrapColumn, ContainerWrap, TimelineWrap } from "../../wraps";
import { UserPost, UserAvatar } from '../../user'
import { TopAppBar } from "../../menu-bar";
import { ElementVisibility } from "../../service";
import { Loader } from "../../result";
import { decode } from "utils";


const UserPostPage = () => {

    const [pageLoaded, setPageLoaded ] =  useState(false);

    const { scrollIds } = useParams();
    const [ postId, authorId, orderDate ] = decode.fromSlug(scrollIds);

    const {loading, data} = useQuery(GET_USER, {
        variables: { user_id : authorId },
        fetchPolicy:"cache-and-network"
    });

    useEffect( () =>
        window.scrollTo(0, 30),
    [pageLoaded]
    );

    return (
        <ContainerWrap className="container-margin">
            {data !== undefined &&
                <ElementVisibility maxWidth={768} type="show" comparison="<">
                    <TopAppBar
                        title={<h3>{`@${data.user.nickname}`}</h3>}
                    />
                </ElementVisibility>
            }
            <TimelineWrap>
                <WrapColumn position="left">
                    <UserPost
                        setPageLoaded={ setPageLoaded }
                        postId={ postId }
                        authorId={ authorId }
                        orderDate={ orderDate }
                    />
                </WrapColumn>
                <WrapColumn position="right">
                    <>
                        { !loading ?
                            <UserAvatar
                                user={ { ...data.user } }
                                labelPrefix={ "@" }
                            /> : <Loader />
                        }
                    </>
                </WrapColumn>
            </TimelineWrap>
        </ContainerWrap>
    );
};

export default UserPostPage;