import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

import { USER_LOGOUT } from "graphql/mutation/user-gql";
import { GET_AUTH_TOKEN } from "graphql/query/user-gql";
import INITIAL_DATA from "graphql/initial-data";

import { errorNotification } from "components/result";


const useLogout = () => {

    const history = useHistory(),
          location = useLocation(),
          [userLogout, {client}] = useMutation( USER_LOGOUT );


    const clearStore = ({ route = false }) => {

        localStorage.clear();
        //client.resetStore().catch((error) => { //console.log(error)});

        client.cache.reset().then(
            () => {
                client.writeData({ data : INITIAL_DATA });
                client.writeQuery({
                    query: GET_AUTH_TOKEN,
                    data: {
                        authToken: false
                    },
                });

                if(route !== undefined && route){
                   history.push(route ?? "/", { path: location.pathname });
                }
            }
        );

    };

    const logout = ({ redirectRoute = "/"}) => {
        userLogout({
            update(cache) {

                cache.writeQuery({
                    query: GET_AUTH_TOKEN,
                    data: {
                        authToken: false
                    },
                });

                setTimeout( () => {
                    clearStore({ route: redirectRoute })
                }, 100 );
            }
        }).catch((error) => {
            errorNotification(error);
        });
    };

    return {
        logout, clearStore
    };

};

export default useLogout;