import React from "react";

import CardActionLike from "./card-action-like";
import CardActionFavorite from './card-action-favorite';
import CardActionSharePost from "./card-action-share-post";
import Icons from "../../icons";

import './card-actions.css';


const CardActions = (props) => {

    const {
        postSlug,
        postId,
        liked,
        setShowCommentsDrawer
    } = props;

    return (
        <div className="card-actions company-icon-hover site-icon">
            <div className="card-actions-left-side">
                <CardActionLike
                    postId={postId}
                    liked={liked}
                />
                <button
                    className="message-but"
                    onClick={ () => setShowCommentsDrawer(true) }
                >
                    <Icons.Comment />
                </button>
                <CardActionSharePost
                    postId={postId}
                />
            </div>
            <div className="card-actions-right-side">
                <CardActionFavorite
                    postId={postId}
                    postSlug={postSlug}
                />
            </div>
        </div>
    );
};

export default CardActions;