import FollowersByTypeUser from './followers-by-type-user';
import FollowersByTypeCollection from './followers-by-type-collection';
import FollowersByTypeHashtag from './followers-by-type-hashtag';


const FollowersBy = {
    User : FollowersByTypeUser,
    Collection : FollowersByTypeCollection,
    Hashtag : FollowersByTypeHashtag
};

export default FollowersBy;