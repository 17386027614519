import { useEffect } from "react";


const useAutosize = ({ elementId }) => {

    useEffect(() => {

        if(elementId){
            const element = document.getElementById(elementId);

            const handleResize = () => {
                element.style.height = 'auto';
                element.style.height = element.scrollHeight +'px';
            };

            element.addEventListener('input', handleResize);

            return () => {
                element.removeEventListener('input', handleResize);
            }

        }
    });

    return null;

};

export default useAutosize;