import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Icon, Upload } from "antd";

import { POST_ADD_MEDIA, POST_DELETE_MEDIA } from "graphql/mutation/post-gql";
import { GET_POST_SHORT } from "graphql/query/post-gql";

import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";

import './post-upload-media.css';


const PostUploadMedia = ({ defaultMedia, postId, postSlug }) => {

    const client = useApolloClient();

    const [addMedia, { loading }] = useMutation(POST_ADD_MEDIA);
    const [deleteMedia] = useMutation(POST_DELETE_MEDIA);

    const [ filesLoading, setFilesLoading ] = useState(0);

    const fileList = defaultMedia.map(({ id, title, thumbnail, path }) => {
        return (
            {
                uid : id,
                name : title,
                url : path,
                thumbUrl : thumbnail,
                status : 'done'
            }
        )
    });

    const formUploadProps = {
        accept: 'image/*',
        beforeUpload :  (file) => {

            setFilesLoading( (prevState) => prevState + 1 );

            addMedia({
                variables : {
                    post_id : postId,
                    files: [file]
                },
                update(cache, { data }) {

                    const {addMedia} = data;
                    const {label, message} = addMedia;

                    setFilesLoading( (prevState) => prevState - 1 );

                    successNotification({
                        title: label,
                        description: message
                    });
                }
            }).catch((error) => {
                setFilesLoading( (prevState) => prevState - 1 );
                errorNotification(error);
            });

            return false;
        },
        onRemove: (file) => {

            if(file.status === 'done' && defaultMedia.length > 1){

                deleteMedia({
                    variables : {
                        media_id : file.uid
                    },
                    update: (cache, { data }) => {

                        const {
                            deleteMedia : {
                                label,
                                message
                            }
                        } = data;

                        const {post} = client.readQuery({
                            query: GET_POST_SHORT,
                            variables: {
                                slug: postSlug,
                            }
                        });

                        const newMedia = post.media.filter(item => item.id !== file.uid);

                        client.writeQuery({
                            query: GET_POST_SHORT,
                            data: {
                                post: { ...post, media:[ ...newMedia ] }
                            },
                        });

                        successNotification({
                            title: label,
                            description: message
                        });

                    }
                }).catch((error) => {
                    errorNotification(error);
                });

            }
        },
        fileList: fileList,
        listType: "picture-card",
        showUploadList: {
            showPreviewIcon: false,
            showDownloadIcon: false
        },
        multiple : true
    };

    return(
        <div className={ `post-update-media ${ fileList.length < 3 ? "center-upload" : ""}`}>
            <Localize wrap="h4" imagesCount={ defaultMedia.length }>
                POST.Images_Count
            </Localize>
            <Upload {...formUploadProps}>
                <div className="upload-label">
                    { loading || filesLoading > 0 ?
                        <Icon type="loading" /> :
                        <>
                            <div className="site-badge"><Icons.Plus /></div>
                            <div className="site-icon">
                                <Localize>POST.Text_AddImage</Localize>
                            </div>
                        </>
                    }
                </div>
            </Upload>
        </div>
    );
};

export default PostUploadMedia;