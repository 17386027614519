import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/react-hooks";
import { Button, Modal } from "antd";

import { REMOVE_COLLECTION } from "graphql/mutation/collection-gql";

import { errorNotification, successNotification } from "../../result";
import Icons from "../../icons";

import "./collection-delete-button.css";
import {Localize} from "../../service";


const removeCollectionAction = (props) => {

    const {
        removeCollection,
        collection_id,
        history,
        setPopupVisible
    } = props;

    removeCollection({
        variables: {
            collection_id,
        },
        update: (cache, { data }) => {

            const {
                removeCollection:{
                    label,
                    message,
                    collection: {
                        author: {
                            nickname
                        }
                    }
                }
            } = data;

            successNotification({
                title: label,
                description: message,
            });

            setPopupVisible(false);

            history.push(`/${nickname}/collections`);
        }
    }).catch((error) => {
        errorNotification(error);
    });
};

const DeleteButton = ({ type, clickAction }) => {

    if(type === "smallAntButton"){
        return(
            <Button
                className="small-rounded-button site-icon"
                shape="circle"
                onClick={ clickAction }
            >
                <Icons.Trash />
            </Button>
        );
    }

    if (type === 'antButton') {
        return(
            <Button
                className="rounded-button"
                onClick={ clickAction }
            >
                <Localize wrap>COLLECTION.Button_Delete</Localize>
            </Button>
        );
    }

    return (
        <button onClick={ clickAction }>
            <Localize wrap>COLLECTION.Button_Delete</Localize>
        </button>
    );

};

const CollectionDeleteButton = (props) => {

    const {
        collection_id,
        buttonType,
    } = props;

    const  [popupVisible, setPopupVisible] = useState(false);
    const history = useHistory();

    const [removeCollection, { loading : removeLoading }] = useMutation(REMOVE_COLLECTION);

    return (
        <>
            <DeleteButton
                type={ buttonType }
                clickAction={ () => setPopupVisible(true) }
            />
            <Modal
                title={ <Localize>COLLECTION.Modal_Title_Remove</Localize> }
                className="delete-collection-modal"
                visible={ popupVisible }
                onOk={
                    () => removeCollectionAction(
                        {
                            removeCollection,
                            collection_id,
                            history,
                            setPopupVisible
                        }
                    )
                }
                onCancel={() => setPopupVisible(false)}
                okText={ <Localize wrap>GLOBAL.Button_Text_Yes</Localize> }
                okButtonProps={
                    {
                        loading : removeLoading,
                        className : "rounded-button"
                    }
                }
                cancelText={ <Localize>GLOBAL.Button_Text_No</Localize> }
                cancelButtonProps={
                    { className : "rounded-button" }
                }
            >
                <Localize wrap="p">GLOBAL.Text_ModalDelete_Question</Localize>
            </Modal>
        </>
    );

};

export default CollectionDeleteButton;