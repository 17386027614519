import React from 'react';
import { Icon } from 'antd';

const CommentSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 26 26">
        <path d="M18,1.5H8c-3.8,0-6.8,3.1-6.8,6.8v4.4c0,3.8,3.1,6.8,6.8,6.8h5.1l6,4.7c0.1,0.1,0.3,0.2,0.5,0.2
		c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.4-0.4,0.4-0.6l0.3-4.7c2.5-1.1,4.2-3.5,4.2-6.3V8.3C24.8,4.6,21.8,1.5,18,1.5z M23.3,12.8
		c0,2.3-1.5,4.3-3.7,5c-0.3,0.1-0.5,0.4-0.5,0.7l-0.2,3.8l-5-4c-0.1-0.1-0.3-0.2-0.5-0.2H8c-2.9,0-5.3-2.4-5.3-5.3V8.3
		C2.7,5.4,5.1,3,8,3h10c2.9,0,5.3,2.4,5.3,5.3V12.8z"/>
        <path d="M9.1,9.3C8.5,9.3,8,9.8,8,10.4s0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1S9.7,9.3,9.1,9.3z"/>
        <path d="M12.9,9.3c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1S13.5,9.3,12.9,9.3z"/>
        <path d="M16.6,9.3c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1S17.1,9.3,16.6,9.3z"/>
    </svg>
);

const CommentFillSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 18 18">
        <path d="M12.2,2.7H5.4c-2.3,0-4.1,1.9-4.1,4.1v3c0,2.3,1.9,4.1,4.1,4.1h3.7l4.2,3.3l0.2-3.6c1.7-0.5,2.9-2.1,2.9-3.9v-3
	C16.4,4.5,14.5,2.7,12.2,2.7z M6.1,8.9c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7
	C6.9,8.6,6.5,8.9,6.1,8.9z M8.7,8.9C8.3,8.9,8,8.6,8,8.2c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C9.5,8.6,9.1,8.9,8.7,8.9z
	 M11.2,8.9c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C12,8.6,11.6,8.9,11.2,8.9z"/>
    </svg>
);


const CommentIcon = props => <Icon component={ props.filled ? CommentFillSvg : CommentSvg} />;

export default CommentIcon;