import React from 'react';
import { Icon } from 'antd';

const SettingsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 24 24">
        <path d="M12,6.9c-2.8,0-5.1,2.3-5.1,5.1s2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1S14.8,6.9,12,6.9z M12,15.7
		c-2,0-3.7-1.7-3.7-3.7S10,8.3,12,8.3s3.7,1.7,3.7,3.7S14,15.7,12,15.7z"/>
        <path d="M22.4,9.5L21,9.2c-0.2-0.6-0.4-1.1-0.7-1.7l0.8-1.2c0.6-0.6,0.6-1.6,0-2.2l-1.2-1.2c-0.6-0.6-1.6-0.6-2.2,0
		l-1.2,0.8c-0.5-0.3-1.1-0.5-1.7-0.7l-0.3-1.4c0-0.9-0.8-1.6-1.6-1.6h-1.8c-0.9,0-1.6,0.7-1.6,1.6L9.2,3C8.7,3.2,8.1,3.4,7.6,3.7
		L6.4,2.8c-0.6-0.6-1.6-0.6-2.2,0L2.9,4.1c-0.6,0.6-0.6,1.6,0,2.2l0.8,1.2C3.4,8.1,3.2,8.7,3,9.2L1.6,9.5C0.7,9.5,0,10.2,0,11.1v1.8
		c0,0.9,0.7,1.6,1.6,1.6L3,14.8c0.2,0.6,0.4,1.1,0.7,1.7l-0.8,1.2c-0.6,0.6-0.6,1.6,0,2.2l1.2,1.2c0.6,0.6,1.6,0.6,2.2,0l1.2-0.8
		c0.5,0.3,1.1,0.5,1.7,0.7l0.3,1.4c0,0.9,0.8,1.6,1.6,1.6h1.8c0.9,0,1.6-0.7,1.6-1.6l0.3-1.4c0.6-0.2,1.1-0.4,1.7-0.7l1.2,0.8
		c0.6,0.6,1.6,0.6,2.2,0l1.2-1.2c0.6-0.6,0.6-1.6,0-2.2l-0.8-1.2c0.3-0.5,0.5-1.1,0.7-1.7l1.4-0.3c0.9,0,1.6-0.8,1.6-1.6v-1.8
		C24,10.2,23.3,9.5,22.4,9.5z M22.6,12.9c0,0.2-0.2,0.2-0.3,0.2l-1.9,0.3c-0.3,0-0.5,0.3-0.6,0.5c-0.2,0.7-0.5,1.5-0.9,2.1
		c-0.1,0.2-0.1,0.5,0,0.8l1.1,1.6c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0,0.3l-1.2,1.2c-0.1,0.1-0.2,0.1-0.3,0c0,0-0.1-0.1-0.1-0.1
		l-1.6-1.1c-0.2-0.2-0.5-0.2-0.8,0c-0.7,0.4-1.4,0.7-2.1,0.9c-0.3,0.1-0.5,0.3-0.5,0.6l-0.3,1.9c0,0,0,0.1,0,0.1
		c0,0.1-0.1,0.2-0.2,0.2h-1.8c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0-0.1l-0.3-1.9c0-0.3-0.2-0.5-0.5-0.6c-0.7-0.2-1.5-0.5-2.1-0.9
		c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0.1L5.5,20c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0l-1.2-1.2
		c-0.1-0.1-0.1-0.2,0-0.3c0,0,0.1-0.1,0.1-0.1l1.1-1.6c0.2-0.2,0.2-0.5,0-0.8c-0.4-0.7-0.7-1.4-0.9-2.1c-0.1-0.3-0.3-0.5-0.6-0.5
		l-1.9-0.3c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2v-1.8c0-0.2,0.2-0.2,0.3-0.2l1.9-0.3c0.3,0,0.5-0.3,0.6-0.5
		c0.2-0.7,0.5-1.5,0.9-2.1c0.1-0.2,0.1-0.5,0-0.8L4,5.5c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2,0-0.3l1.2-1.2c0.1-0.1,0.2-0.1,0.3,0
		c0,0,0.1,0.1,0.1,0.1l1.6,1.1c0.2,0.2,0.5,0.2,0.8,0C8.6,4.7,9.3,4.4,10,4.2c0.3-0.1,0.5-0.3,0.5-0.6l0.3-1.9c0,0,0-0.1,0-0.1
		c0-0.1,0.1-0.2,0.2-0.2h1.8c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1,0,0.1l0.3,1.9c0,0.3,0.3,0.5,0.5,0.6c0.7,0.2,1.5,0.5,2.1,0.9
		c0.2,0.1,0.5,0.1,0.8,0L18.5,4c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3,0l1.2,1.2c0.1,0.1,0.1,0.2,0,0.3c0,0-0.1,0.1-0.1,0.1
		l-1.1,1.6c-0.2,0.2-0.2,0.5,0,0.8c0.4,0.7,0.7,1.4,0.9,2.1c0.1,0.3,0.3,0.5,0.6,0.5l1.9,0.3c0.2,0,0.3,0.1,0.3,0.2V12.9z"/>
    </svg>
);


const SettingsIcon = props => {

    return <Icon component={ SettingsSvg }/>;
};


export default SettingsIcon;