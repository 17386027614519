import { gql } from "apollo-boost";

export const attachmentsFieldsFull = gql`
    fragment attachmentsFieldsFull on Attachment {
        id
        name
        title
        display_url
        extension
        order
        description
    }
`;

export const attachmentsFragmentFull = gql`
    fragment attachmentsFragmentFull on Post {
        attachments{
            ...attachmentsFieldsFull
        }
    }
    ${ attachmentsFieldsFull }
`;