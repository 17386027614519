import React from "react";

import Icons from "components/icons";


const AvatarBlocked = ({ className = ""}) => {

    return(
        <div className={`avatar-blocked ${className}`}>
            <p className="avatar"><Icons.User filled /></p>
            <p className="cross"><Icons.Close /></p>
        </div>
    );
};

export default AvatarBlocked;