import React, { useState } from "react";
import { useApolloClient, useMutation } from "@apollo/react-hooks";
import { Button, Drawer, Icon, Upload } from "antd";

import { POST_ADD_ATTACHMENT, POST_DELETE_ATTACHMENT } from "graphql/mutation/post-gql";
import { GET_POST } from "graphql/query/post-gql";

import { errorNotification, successNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";

import './post-upload-attachments.css';


const UploadAttachments = (props) => {

    const {
        defaultAttachments,
        postId,
        postSlug,
        countSendedFiles,
        setCountSendedFiles,
        hideDrawer
    } =  props;

    const client = useApolloClient();

    const [addAttachment, { loading }] = useMutation(POST_ADD_ATTACHMENT);
    const [deleteAttachment] = useMutation(POST_DELETE_ATTACHMENT);

    const { Dragger } = Upload;

    const fileList = defaultAttachments.map(({ id, title, extension, display_url }) => {
        return (
            {
                uid : id,
                name : `${title}.${extension}`,
                url : display_url,
                status : 'done'
            }
        )
    });

    const formAttachmentsProps = {
        beforeUpload :  (file) => {

            setCountSendedFiles(countSendedFiles + 1 );

            addAttachment({
                variables : {
                    post_id : postId,
                    files: [file]
                },
                update(cache, { data }) {

                    const {addAttached} = data;
                    const {label, message} = addAttached;

                    const {post} = client.readQuery({
                        query: GET_POST,
                        variables: {
                            slug: postSlug,
                        }
                    });

                    client.writeQuery({
                        query: GET_POST,
                        data: {
                            post: {...post, ...addAttached.post}
                        },
                    });

                    setCountSendedFiles(countSendedFiles - 1 );

                    successNotification({
                        title: label,
                        description: message
                    });

                }
            }).catch((error) => {
                setCountSendedFiles(countSendedFiles - 1 );
                errorNotification(error);
            });

            return false;
        },
        onRemove: (file) => {

            if(file.status === 'done') {

                deleteAttachment({
                    variables: {
                        attached_id: file.uid
                    },
                    update: (cache, {data}) => {

                        const {
                            deleteAttached : {
                                label,
                                message
                            }
                        } = data;

                        const {post} = client.readQuery({
                            query: GET_POST,
                            variables: {
                                slug: postSlug,
                            }
                        });

                        const newMedia = post.attachments.filter(item => item.id !== file.uid);

                        client.writeQuery({
                            query: GET_POST,
                            data: {
                                post: {...post, attachments: [...newMedia]}
                            },
                        });

                        successNotification({
                            title: label,
                            description: message
                        });

                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }
        },
        fileList: fileList,
        showUploadList: {
            showPreviewIcon: false,
        },
        multiple : true
    };

    return(
        <div className="post-update-attachments">
            <Dragger {...formAttachmentsProps}>
                <div>
                    { loading ? <Icon type="loading" /> : <div className="site-badge"><Icons.Plus /></div> }
                    <Localize wrap>POST.Dragger_Text_Attach</Localize>
                </div>
                <div className="upload-rules site-label-text-color">
                    <Localize size={ 100 } >FORM_RULES.Attachment_Size</Localize>
                </div>
            </Dragger>

            <div className="hide-drawer">
                <Button
                    type="primary"
                    className="rounded-button"
                    onClick={ hideDrawer }
                >
                    <Localize wrap>GLOBAL.Button_Text_Done</Localize>
                </Button>
            </div>
        </div>
    );
};

const PostUploadAttachments = (props) => {

    const { defaultAttachments } = props;

    const [countSendedFiles, setCountSendedFiles] = useState(0);
    const [showDrawer, setShowDrawer] = useState(false);


    return (
        <div className="post-update-attachments-wrap">
            <Localize wrap="h4" attachmentCount={ defaultAttachments.length }>
                POST.Attachment_Count
            </Localize>
            <Button
                className="rounded-button"
                onClick={ () => setShowDrawer( true )}
            >
                <Localize>POST.Title_AttachDocuments</Localize>
            </Button>
            <Drawer
                className="post-update-drawer"
                title={
                    <>
                        <Localize wrap="h4">POST.Drawer_Title_Attachments</Localize>
                        <button
                            className="close-drawer"
                            onClick={ () => setShowDrawer( false )}
                        >
                            <Icons.Close />
                        </button>
                    </>
                }
                placement="right"
                closable={ false }
                onClose={ () => setShowDrawer( false )}
                visible={showDrawer}
            >
                <UploadAttachments
                    { ...props }
                    countSendedFiles={countSendedFiles}
                    setCountSendedFiles={setCountSendedFiles}
                    hideDrawer={ () => setShowDrawer( false ) }
                />
            </Drawer>
        </div>
    );

};

export default PostUploadAttachments;