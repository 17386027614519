import React from "react";
import { Link, useHistory } from "react-router-dom";

import Icons from "components/icons";

import "./top-app-bar.css";


const LeftSide = (props) => {

    const { goBack } = useHistory();

    const {backLink, leftSide} = props;

    if(leftSide !== undefined){
        return (
            <>{leftSide}</>
        );
    }

    if(backLink !== undefined){
        return(
            <Link to={ backLink }
                  className="back-button button-bg-color site-icon"
            >
                <Icons.ArrowLeft/>
            </Link>
        );

    }

    return(
        <button
              onClick={ goBack }
              className="back-button button-bg-color site-icon"
        >
            <Icons.ArrowLeft/>
        </button>
    );

};

const TopAppBar = (props) => {

    const {
        className = "",
        backLink,
        leftSide,
        title,
        rightSide
    } = props;

    return (
        <div className={ `top-app-bar bar-bg-color ${className}`}>
            <div className="top-app-bar-left">
                <LeftSide
                    leftSide={ leftSide }
                    backLink={ backLink }
                />
            </div>
            <div className="top-app-bar-title">
                { title }
            </div>
            <div className="top-app-bar-right">
                { rightSide }
            </div>
        </div>
    );
};

export default TopAppBar;