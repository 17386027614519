import React from 'react';
import { Icon } from 'antd';

const ArrowLeftSvg = () => (
    <svg fill="currentColor"  width="8px" height="14px" viewBox="0 0 8 14">
        <path d="M7,14c-0.3,0-0.5-0.1-0.7-0.3l-6-6c-0.4-0.4-0.4-1,0-1.4l6-6c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L2.4,7l5.3,5.3
	c0.4,0.4,0.4,1,0,1.4C7.5,13.9,7.3,14,7,14z"/>
    </svg>
);

const ArrowCircleLeftSvg = () => (
    <svg fill="currentColor"  width="30px" height="30px" viewBox="0 0 30 30">
        <path d="M0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15c0-8.3-6.7-15-15-15C6.7,0,0,6.7,0,15z M9.9,15.1
                 c0-0.3,0.1-0.7,0.4-0.9L16.7,8c0.5-0.5,1.3-0.5,1.8,0c0.5,0.5,0.5,1.3,0,1.8L13,15.1l5.4,5.2c0.5,0.5,0.5,1.3,0,1.8
                 c-0.3,0.3-0.6,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L10.3,16C10.1,15.8,9.9,15.4,9.9,15.1z"
        />
    </svg>
);

const ArrowLeftFullSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 12 12">
        <path d="M0.2,6.7l4.9,5C5.3,11.9,5.5,12,5.8,12s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L3.3,7H11c0.6,0,1-0.4,1-1
	             s-0.4-1-1-1H3.4l3.2-3.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-4.9,5C-0.1,5.7-0.1,6.3,0.2,6.7z"
        />
    </svg>
);

const ArrowRightSvg = () => (
    <svg fill="currentColor"  width="8px" height="14px" viewBox="0 0 8 14">
        <path d="M0.3,13.7c-0.4-0.4-0.4-1,0-1.4L5.6,7L0.3,1.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6c0.4,0.4,0.4,1,0,1.4l-6,6
	             C1.5,13.9,1.3,14,1,14S0.5,13.9,0.3,13.7z"
        />
    </svg>
);

const ArrowCircleRightSvg = () => (
    <svg fill="currentColor"  width="30px" height="30px" viewBox="0 0 30 30">
        <path d="M15,0C6.7,0,0,6.7,0,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C30,6.7,23.3,0,15,0z M19.7,16l-6.4,6.1
	             c-0.2,0.2-0.6,0.4-0.9,0.4c-0.3,0-0.7-0.1-0.9-0.4c-0.5-0.5-0.5-1.3,0-1.8l5.4-5.2l-5.4-5.2c-0.5-0.5-0.5-1.3,0-1.8
	             c0.5-0.5,1.3-0.5,1.8,0l6.4,6.1c0.3,0.2,0.4,0.6,0.4,0.9S19.9,15.8,19.7,16z"
        />
    </svg>
);

const ArrowRightFullSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 12 12">
        <path d="M11.7,5.3l-4.9-5c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L8.6,5H1C0.4,5,0,5.4,0,6s0.4,1,1,1h7.7l-3.2,3.3
	             c-0.4,0.4-0.4,1,0,1.4C5.7,11.9,5.9,12,6.2,12s0.5-0.1,0.7-0.3l4.9-5C12.1,6.3,12.1,5.7,11.7,5.3z"
        />
    </svg>
);

const ArrowDownSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 14 14">
        <path d="M14,4c0,0.3-0.1,0.5-0.3,0.7l-6,6c-0.4,0.4-1,0.4-1.4,0l-6-6c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L7,8.6l5.3-5.3
	             c0.4-0.4,1-0.4,1.4,0C13.9,3.5,14,3.7,14,4z"
        />
    </svg>
);

const ArrowUpSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 14 14">
        <path d="M14,10c0-0.3-0.1-0.5-0.3-0.7l-6-6c-0.4-0.4-1-0.4-1.4,0l-6,6c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0L7,5.4l5.3,5.3
	             c0.4,0.4,1,0.4,1.4,0C13.9,10.5,14,10.3,14,10z"
        />
    </svg>
);


const ArrowLeftIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    if(props.circle){
        return <Icon component={ ArrowCircleLeftSvg }  />;
    }

    return <Icon component={ props.full ? ArrowLeftFullSvg : ArrowLeftSvg }  />;
};

const ArrowRightIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    if(props.circle){
        return <Icon component={ ArrowCircleRightSvg }  />;
    }

    return <Icon component={ props.full ? ArrowRightFullSvg : ArrowRightSvg}  />;

};

const ArrowDownIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ ArrowDownSvg } {...props} />;

};

const ArrowUpIcon = props => {

    if(props.loading){
        return <Icon type="loading" />;
    }

    return <Icon component={ ArrowUpSvg } {...props} />;

};

export {
    ArrowRightIcon,
    ArrowLeftIcon,
    ArrowDownIcon,
    ArrowUpIcon
};