import React from "react";
import { Link } from "react-router-dom";

import AdditionalMenuPopover from "./additional-menu-popover";
import AdditionalMenuModal from "./additional-menu-modal";
import { UserRequestModal } from "components/user";
import { AvatarCustom } from "components/avatar";
import { useLogout } from "components/use-hooks";
import { Localize } from "../../service";
import Icons from "components/icons";

import "./additional-menu.css";


//import PDF from "../../../assets/MMDA_DiamondStarClub_AGB.pdf";



const AdditionalMenu = ({ type, user }) => {

    const { logout } = useLogout();
    const Wrap = ( props ) => {
         return type === "popover"? <AdditionalMenuPopover {...props }/> : <AdditionalMenuModal {...props }/>
    };


    return(
        <Wrap>
            <AvatarCustom
                photoUrl={ user.avatar }
                initials={ user.initials }
                size={ 35 }
            >
                <Link to={`/${user.nickname}`}>
                    <Localize>MENUS.Label_MyProfile</Localize>
                </Link>
            </AvatarCustom>
            <nav className={ `additional-menu` }>
                <ul>
                    <li>
                        <Link
                            to="/chat"
                            className="with-icon"
                        >
                            <Icons.Chat type="menu" />
                            <Localize wrap>MENUS.Label_Chat</Localize>
                        </Link>
                    </li>
                    <li>
                        <UserRequestModal />
                    </li>
                    <li>
                        <button onClick={ logout } className="with-icon">
                            <Icons.SignOut />
                            <Localize wrap>MENUS.Button_Text_Logout</Localize>
                        </button>
                    </li>

                </ul>
            </nav>
        </Wrap>
    );
}

export default AdditionalMenu;