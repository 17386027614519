import React from "react";

const WrapColumn = ({ children, position }) => {
    return (
        <div data-position={position}>
            { children }
        </div>
    );
};

export default WrapColumn;