import React from "react";
import {Link} from "react-router-dom";

import AvatarCustom from "../avatar-custom";


const AvatarBlock = (props) => {

    const {
        label,
        link,
        text,
        labelLink = !!link,
        avatarAction
    } = props;

    const labelItem = labelLink ? <Link to={ link } className="avatar-link site-title-color">{ label }</Link> : label;

    return (
        <AvatarCustom { ...props }>
            <p className="avatar-label site-title-color">
                { labelItem }
            </p>
            { text !== undefined &&
                <div className="avatar-text site-sub-text-color">
                    { text }
                </div>
            }
            { avatarAction !== undefined &&
                <div className="avatar-action company-link-hover">
                    { avatarAction }
                </div>
            }
        </AvatarCustom>
    );
};

export default AvatarBlock;
