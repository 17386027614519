import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { SEARCH_POSTS_NEWS } from "graphql/query/search-gql";

import { CardList } from "components/card";
import {LoadMoreOnScroll, Localize} from "components/service";
import { errorNotification, Loader } from "components/result";
import Icons from "components/icons";



const NewsTimelinePosts = () => {

    const { loading, data, fetchMore } = useQuery(SEARCH_POSTS_NEWS, {
        variables: {
            top:  undefined
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        searchPosts : {
            pageInfo,
            edges : posts
        } = {}
    } = data !== undefined ? data : {};

    const fetchMoreAction = () => {

        if( !loading && pageInfo.hasNextPage){

            fetchMore({
                variables: {
                    endCursor : pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const searchPosts = fetchMoreResult.searchPosts;

                    const newSearchResults = {
                        searchPosts: {
                            edges : [
                                ...posts,
                                ...searchPosts.edges
                            ],
                            __typename: searchPosts.__typename,
                            pageInfo : searchPosts.pageInfo
                        }
                    };

                    return searchPosts.edges.length ? newSearchResults : previousResult;

                }
            }).catch((error) => {
                errorNotification(error);
            });
        }
    };

    return (
        <div className="timeline-posts">
            { !loading && pageInfo.total < 1 &&
            <div className="timeline-message">
                <div className="no-content-icon site-icon-color-second">
                    <Icons.NoContent type="posts" />
                </div>
                <Localize wrap="p">NO_DATA.Text_Timeline</Localize>
                <p>
                    <Link to="/search">
                        <Localize>NO_DATA.Link_Text_TimelineSearch</Localize>
                    </Link>
                </p>
            </div>
            }
            { posts !== undefined && posts.length > 0 &&
            <>
                <CardList cardItems={posts} type="timeline" />
                {
                    pageInfo.currentPage < pageInfo.lastPage &&
                    <LoadMoreOnScroll
                        fetchAction={() => fetchMoreAction()}
                        loading={loading}
                    />
                }
            </>
            }
            { loading && posts === undefined && <Loader /> }
        </div>
    );

};

export default NewsTimelinePosts;