import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { NavLink } from "react-router-dom";

import { GET_ME } from "graphql/query/user-gql";

import { NotificationButton } from 'components/notifications';
import Icons from "components/icons";
import AdditionalMenu from "../../additional-menu";
import { Localize } from "components/service";

import './header-actions.css';


const HeaderActions = () => {

    const client = useApolloClient();
    const { me } = client.readQuery({query: GET_ME});

    return(
        <ul className="header-actions">
            <li>
                <NavLink
                    to="/"
                    title={ Localize({children: "MENUS.Label_News" }).props.children }
                    className="header-action-news"
                    exact
                >
                    <Icons.News />
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/timeline"
                    title={ Localize({children: "MENUS.Label_MyTimeline" }).props.children }
                    className="header-action-timeline"
                    exact
                >
                    <Icons.Home type="timeline" />
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/chat"
                    title={ Localize({children: "MENUS.Label_Chat" }).props.children }
                    className="header-action-chat"
                    exact
                >
                    <Icons.Chat type="menu" />
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/search"
                    title={ Localize({children: "MENUS.Label_Search" }).props.children }
                    className="header-action-search"
                >
                    <Icons.Search />
                </NavLink>
            </li>
            <li>
                <NotificationButton
                    cssClass={ 'header-action-notification' }
                    userId={ me.id }
                />
            </li>
            <li>
                <NavLink
                    to="/post/create"
                    title={ Localize({children: "MENUS.Label_NewPost" }).props.children }
                    className="header-action-create-post company-bg-color with-font-color"
                >
                    <Icons.Plus />
                    <span className="label">
                        <Localize>MENUS.Label_NewPost</Localize>
                    </span>
                </NavLink>
            </li>
            <li>
                <AdditionalMenu
                    user={ me }
                    type="popover"
                />
            </li>
        </ul>
    );
};


export default HeaderActions;