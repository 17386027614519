import { gql } from "apollo-boost";


export const GET_SUMMARY_STATISTIC = gql`
    query GetSummaryStatistic{
        summaryStatistic{
            id
            key
            value
            updated_at
        }
    }`;