import React from "react";

import FollowList from "../../follow-list";
import { LoadMoreOnScroll, Localize } from "components/service";
import { Loader } from "components/result";
import Icons from "components/icons";

const FollowersByTypeUser = (props) => {

    const {
        followers,
        pageInfo,
        fetchMoreAction,
        loading,
        me,
        userId,
    } = props;

    return (
        <div className="follow-list-wrap">
            {  followers !== undefined && followers.length > 0 &&
            <>
                <FollowList
                    items={followers}
                    myId={me.id}
                />
                { pageInfo.hasNextPage &&
                    <LoadMoreOnScroll
                        fetchAction={() => fetchMoreAction()}
                        loading={false}
                    />
                }
            </>
            }
            { followers !== undefined && !followers.length &&
                <div className="follower-message">
                    <div className="no-content-icon site-icon-color-second">
                        <Icons.NoContent type="userFollowing" />
                    </div>
                    <Localize
                        wrap="p"
                        who={ userId === me.id ?
                            <Localize>NO_DATA.Text_Who_You</Localize> :
                            <Localize>NO_DATA.Text_Who_User</Localize>
                        }
                    >
                        NO_DATA.Text_UserFollowers
                    </Localize>
                </div>
            }
            { loading && <Loader /> }
        </div>
    );
};

export default FollowersByTypeUser;