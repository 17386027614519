import { gql } from "apollo-boost";

import { userMetaFields, userFields } from "../fragment/user-frgm";


export const FOLLOW_USER = gql`
  mutation FollowUser($user_id : ID!) {
      followUser(user_id : $user_id){
        label
        message
        me_following
        counters : user_counters{
           published : public_posts
           followers
           following
        }
      }
    }`;

export const LOGIN_USER_JVT = gql`
    mutation LoginUserJVT( $tkn : String! ) {
        loginJWT( tkn: $tkn) {
            access_token
        }
    }
`;

export const UPLOAD_USER_AVATAR = gql`
    mutation UploadAvatar(
        $user_id: ID!,
        $file: Upload!
    ){
        uploadAvatar(
            user_id: $user_id,
            file: $file
        ){
            label
            message 
            avatar
        }
    }`;

export const USER_UPDATE = gql`
    mutation UserUpdate(
        $input: UserFieldsInputApp!
    ){
        userUpdate(
            input: $input
        ){
            label
            message
            user{
                ...userFields
            }
        }
    }
    ${ userFields }
`;

export const USER_LOGOUT = gql`
    mutation UserLogout{
        logout {
            message
        }
    }
`;

export const USER_UPDATE_META = gql`
    mutation UpdateUserMeta($input: [UserMetaInput]) {
        userMetaSet(input: $input){
            label
            message
            meta_fields{
                ...userMetaFields
            } 
        }
    }
    ${ userMetaFields }
`;

export const USER_REGISTRATION = gql`
  mutation RegisterUser($input: RegisterUserInput!) {
      registerUser(input: $input){
        label
        message
      }
    }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePassword!) {
      changePassword(input: $input){
          label
          message  
      }
  }`;


export const USER_QUESTION_REQUEST = gql`
    mutation UserQuestionRequest($input: UserQuestionFieldsInput!) {
        userQuestionCreate(input: $input){
            label
            message
        }
    }`;


export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
      forgotPassword(input: $input){
          message
          status  
      }
  }`;

export const CHANGE_FORGOT_PASSWORD = gql`
    mutation ChangeForgotPassword(
        $password: String!,
        $user_id: ID!,
        $key: String!
    ){
        updateForgotPassword(
            password: $password,
            user_id: $user_id,
            key: $key
        ){
            label
            message
        }
    }`;
