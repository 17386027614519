import { gql } from 'apollo-boost';

export const typeDefs = gql`
   
    extend type Query {
        test: User
    }
`;


const GET_AUTH_TOKEN = gql`
  {
    authToken @client
  }
`;


export const resolvers = {
    Query: {
        me: (_root, _args) => {

            console.log('3543');
            // cache.readQuery({
            //     query: GET_CURRENT_USER,
            // });

            //console.log('CURRENT_USER_QUERY_RESOLVER');

        },
    },
    Mutation: {
        setAuthToken: (_root, { authToken }, { cache }) => {

            cache.writeQuery({
                query: GET_AUTH_TOKEN,
                data: { authToken: authToken }
            });

            console.log('LOGIN_UPDATE_RESOLVER');

            return !authToken;
        }

    }
};