import React, { useEffect } from "react";

import {
    SearchHashtags,
    SearchUsers,
    SearchCollections
} from "../search/search-by-type/type";

const SearchUser = (props) => {

    const {
        query,
        setItems,
        setLoading,
    } = props;


    const {data, loading} = SearchUsers({query, onlyQuery: true});

    useEffect( () => {
        setLoading(loading);

        if (data !== undefined) {
            setItems(data.searchUsers.edges);
        }

    })

    return null;

};

const SearchCollection = (props) => {

    const {
        query,
        setItems,
        setLoading,
    } = props;

    const {data, loading} = SearchCollections(
        {
            query,
            onlyQuery: true,
            sortBy: "COUNT_FOLLOWERS"
        }
    );

    useEffect( () => {
        setLoading(loading);

        if (data !== undefined) {
            setItems(data.searchCollections.edges);
        }

    })



    return null;

};

const SearchHashtag = (props) => {

    const {
        query,
        setItems,
        setLoading,
    } = props;


    const {data, loading} = SearchHashtags(
        {
            query,
            onlyQuery: true,
            sortBy: "COUNT_POSTS"
        }
    );

    useEffect( () => {
        setLoading(loading);

        if (data !== undefined) {
            setItems(data.searchHashtags.edges);
        }

    })

    return null;
};

const MentionSearch = (props) => {

    const {
        type
    } = props;

    switch(type) {
        case "@":
            return <SearchUser {...props}/>;

        case "#":
            return <SearchHashtag {...props}/>;

        case "^":
            return <SearchCollection {...props}/>;

        default: return null;
    }

};

export default MentionSearch;