import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/react-hooks";

import { GET_COLLECTION_POSTS_DETAILED } from "graphql/query/collection-gql";

import { CardList } from "../../card";
import {LoadMoreOnScroll, Localize} from "../../service";
import { errorNotification, ErrorResult, Loader } from "../../result";
import { decode } from "utils";

import './collection-post.css';


const normalizedArr = (postsArr) => {

    const posts = postsArr.map(item => {
        return {node: {...item}}
    });

    const lastPostId = posts[posts.length - 1].node.id;
    const firstPostId = posts[0].node.id;

    return {
        posts,
        lastPostId,
        firstPostId
    };

};

const CollectionPost = ({ setPageLoaded }) => {

    const { scrollIds } = useParams();

    const [ id, collectionId ] = decode.fromSlug(scrollIds);

    const postsPerPage = 10;

    const [ hasMorePosts, setHasMorePosts ] = useState(
        {  next : true, prev: true}
    );

    const { loading, error, data, fetchMore } = useQuery(GET_COLLECTION_POSTS_DETAILED, {
        variables: {
            id: id,
            collection_id : collectionId,
            defaultCount: postsPerPage
        },
        fetchPolicy:"cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    useEffect( () => {
            if(data){
                setPageLoaded(true)
            }
        }
    );

    if(error) {
        return (
            <ErrorResult
                error={error}
                goToLink="/"
                goToText={ <Localize>GLOBAL.Link_Text_OnError</Localize> }
            />
        );
    }

    const { posts, lastPostId, firstPostId }  = data !== undefined ?
        normalizedArr( data.collectionPosts ) :
        {
            posts : [],
            firstPostId : false,
            lastPostId : false
        };

    const fetchMoreAction = (direction) => {

        if(!loading &&  hasMorePosts[direction]){

            let firstItem,
                currentOffset;

            if(direction === 'prev'){
                firstItem = document.querySelector(`#post-in-timeline-${firstPostId}`);
                currentOffset = firstItem.offsetTop - window.scrollY;
            }

            fetchMore({
                variables: {
                    id : direction === 'next' ? lastPostId : firstPostId,
                    paginate:  direction
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {

                    const newPostList = fetchMoreResult.collectionPosts;

                    const newPosts = direction === 'next' ? [
                        ...data.collectionPosts,
                        ...newPostList,
                    ] : [
                        ...newPostList.reverse(),
                        ...data.collectionPosts,
                    ];

                    if(newPostList.length < postsPerPage){
                        setHasMorePosts({ ...hasMorePosts, [direction] : false } );
                    }

                    return newPosts.length ? { collectionPosts : [...newPosts]} : previousResult;

                }
            }).then( () => {
                if(direction === 'prev'){
                    window.scrollTo(0, firstItem.offsetTop - currentOffset);
                }
            })
                .catch((error) => {
                    errorNotification(error);
                });
        }
    };

    return (
        <div className="collection-timeline-posts">
            { loading && data === undefined &&
                <Loader />
            }
            { data !== undefined &&
                <>
                    <LoadMoreOnScroll
                        fetchAction={() => fetchMoreAction('prev')}
                        loading={loading}
                        direction="up"
                    />
                    <CardList
                        className="collection-timeline"
                        type="timeline"
                        cardItems={ posts }
                    />
                    <LoadMoreOnScroll
                        fetchAction={() => fetchMoreAction('next')}
                        loading={loading}
                    />
                </>
            }
        </div>
    );
};

export default CollectionPost;