import { useState } from "react";
import { useSubscription } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";

import { CHAT_MESSAGE_CREATED_SUBSCRIPTION } from "graphql/subscription/chat-gql";
import { GET_ME } from "graphql/query/user-gql";

import useChatMessageUpdateMutation from "./use-message-update-mutation";

import { chatMessagesUpdateHelper } from "../helpers";
import { errorNotification } from "components/result";
import { decode } from "utils";

import sound from 'assets/message-sound.wav';



const useMessageCreatedSubscription = () => {

    const [ audio, setAudio ] = useState("");
    const { onUpdateMessage } = useChatMessageUpdateMutation();

    useState( () => {
        const audioPlayer = new Audio(sound);

        audioPlayer.preload = 'none';
        audioPlayer.mozAudioChannelType = 'content';

        setAudio(audioPlayer);
    });

    const { chatSlug } = useParams();

    useSubscription(CHAT_MESSAGE_CREATED_SUBSCRIPTION, {
        onSubscriptionData: ( { client, subscriptionData : { data } }  ) => {

            if(!data.chatMessageCreated){
                return null;
            }

            const { chatMessageCreated: chatMessage } = data,
                chat_id = parseInt(chatMessage.chat_id);

            const [chatId] = decode.fromSlug(chatSlug);

            const { me } = client.readQuery({query: GET_ME});

            if (chatId === chat_id) {

                chatMessagesUpdateHelper({
                    chatId,
                    chatMessage,
                    client,
                    callBack : () => {

                        if(me.id !== chatMessage.author.id) {
                            onUpdateMessage({
                                variables: {
                                    id: chatMessage.id,
                                }
                            }).catch((error) => {
                                errorNotification(error);
                            });
                        }
                    }
                });

            }

            if(me.id !== chatMessage.author.id){
                audio.play().catch(
                    () => { return false }
                );
            }
        }

    });

};

export default useMessageCreatedSubscription;
